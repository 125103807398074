import { TAB_NAMES } from "@/organisms/Drawers/const"

export const INITIAL_STATE = {
  tooltips: {
    [TAB_NAMES.attackScenarios]: {
      disabledTooltipKey: "triage.drawer.tooltips.attackScenariosDisabled"
    },
    [TAB_NAMES.runtimeEvidence]: {
      disabledTooltipKey: "triage.drawer.tooltips.runtimeEvidenceDisabled"
    }
  },
  disabled: {
    [TAB_NAMES.attackScenarios]: true,
    [TAB_NAMES.runtimeEvidence]: true
  },
  featureStatus: {
    [TAB_NAMES.attackScenarios]: "beta",
    [TAB_NAMES.runtimeEvidence]: "new"
  }
}
