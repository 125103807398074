export const capitalizeFirstLetter = (str: string) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } catch {
    return str
  }
}

export const capitalize = (str: string) => {
  try {
    return str.replace(/(^|[^a-zA-Z])([a-z])/g, (match) => match.toUpperCase())
  } catch {
    return str
  }
}

export const replaceLinkInString = (str: string) =>
  str.replace(
    /(\b(https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
    '<a href="$1" target="_blank">$1</a>'
  )

export const sanitizeString = (
  str: string,
  options: { allowCode?: boolean; allowTags?: string[] } = {}
) => {
  const SYMBOLS = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
  } as Record<string, string>

  const reg = /&(?!(amp|lt|gt|quot|#x27|#x2F);)|[<>"'/]/gi
  const { allowTags, allowCode } = options

  const sanitize = (text: string) => {
    if (!allowTags?.length) {
      return text.replace(reg, (match) => SYMBOLS[match]) // Changed from str to part
    }

    const escapeText = (s: string) => {
      return s.replace(/[&<>"'/]/g, (match) => SYMBOLS[match])
    }

    const tagReg = /(<[^>]+>)([^<]*)/g

    return text.replace(tagReg, (_, tag, content) => {
      // Changed from str to part
      const tagName = tag.match(/^<\/?([a-zA-Z][a-zA-Z0-9]*)/)

      if (tagName && allowTags.includes(tagName[1].toLowerCase())) {
        return tag + escapeText(content)
      }

      return escapeText(tag) + escapeText(content)
    })
  }

  if (allowCode) {
    const parts = str.split(/(<code>.*?<\/code>)/gi)

    return parts
      .map((part) => {
        if (part.match(/^<code>.*<\/code>$/gi)) {
          return part.replace(/(<script\b[^>]*>.*?<\/script>)/gi, (match) =>
            match.replace(/[&<>"'/]/g, (char) => SYMBOLS[char])
          )
        } else {
          return sanitize(part)
        }
      })
      .join("")
  }

  return sanitize(str)
}

export const sliceConcat = (str: string | undefined, length: number) => {
  if (!str) {
    return ""
  }
  if (str.length > length) {
    return str.slice(0, length) + "..."
  }
  return str
}
