import cn from "classnames"

import { getColorBySeverity } from "@/domain/vulnerability"
import {
  getDateFromSeconds,
  getDiffToNow,
  getRelativeTimeFromNow
} from "@/helpers/date.helpers"
import { useTranslation } from "@/hooks/useTranslation"
import { ClockCircleFilled } from "@/icons"
import { IssueDetails } from "@/molecules/IssueDetails"
import { Resources } from "@/molecules/Resources"
import { SeverityTag } from "@/molecules/SeverityTag"

import styles from "./IssueCard.module.scss"

import type { IssueCardComponent } from "."

export const IssueCard: IssueCardComponent = (props) => {
  const { t } = useTranslation()
  const {
    issueType,
    cwe,
    riskId,
    resources,
    kodemScore,
    discoveredAt = 0,
    severity = "unknown"
  } = props
  const date = getDateFromSeconds(discoveredAt)
  const diffToNow = getDiffToNow(date) || 0
  const relativeDate = getRelativeTimeFromNow(date)

  return (
    <div
      className={cn("issue-card-widget-dashboard", styles.container)}
      onClick={() => props.onClick(props.id)}
    >
      <div className={styles.containerIssueDetails}>
        <IssueDetails
          title={cwe?.description || riskId || ""}
          description={
            <Resources
              inline
              popover
              resources={resources}
              t={t}
              onRowClick={props.onRowClick}
            />
          }
          severity={severity}
          issueType={issueType}
        />
      </div>
      <div className={styles.severityContainer}>
        <SeverityTag severity={severity} t={t} />
      </div>
      <div className={styles.scoreTime}>
        <div>
          <span style={{ color: getColorBySeverity(severity) }}>
            {t("general.kodemScore.with", { score: kodemScore })}
          </span>
          {relativeDate && (
            <span
              style={{
                color: `var(--${diffToNow > 25 ? "dust-red-5" : "neutral-6"}-color)`
              }}
            >
              <ClockCircleFilled className={styles.clockIcon} />
              {relativeDate}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
