import { TAB_NAMES, WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { AttackSimulationTab } from "./components/AttackSimulationTab"
import { OverviewTabTriageDrawer } from "./components/OverviewTabTriageDrawer"
import { RuntimeEvidenceTabTriageDrawer } from "./components/RuntimeEvidenceTabTriageDrawer"
import { TitleTriageDrawer } from "./components/TitleTriageDrawer"
import { INITIAL_STATE } from "./const"

import styles from "./TriageDrawer.module.scss"

import useServices from "@/hooks/useServices"
import type { TriageDrawerComponent } from "."

export const TriageDrawer: TriageDrawerComponent = (props) => {
  const { auth } = useServices()
  const attackScenariosTabName = TAB_NAMES.attackScenarios
  const runtimeEvidenceTabName = TAB_NAMES.runtimeEvidence
  const attackScenariosEnabled =
    auth.isDemo() || auth.isInternalTenant() || auth.isExperimental()
  const tabs = attackScenariosEnabled
    ? ["overview", runtimeEvidenceTabName, attackScenariosTabName]
    : ["overview", runtimeEvidenceTabName]
  const components = attackScenariosEnabled
    ? [
        OverviewTabTriageDrawer,
        RuntimeEvidenceTabTriageDrawer,
        AttackSimulationTab
      ]
    : [OverviewTabTriageDrawer, RuntimeEvidenceTabTriageDrawer]
  const { widthAdditive, ...restProps } = props

  return (
    <TabsDrawerTemplate
      name="TriageDrawer"
      tabs={tabs}
      initialState={INITIAL_STATE}
      components={components}
      width={WIDTH_DEFAULT + widthAdditive}
      Title={TitleTriageDrawer}
      classes={{ drawer: styles.drawer }}
      controlledActiveKey
      {...restProps}
    />
  )
}
