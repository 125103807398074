import cn from "classnames"

import { Card, Empty, Row } from "@/atoms"
import { useTeasers } from "@/hooks/useTeasers"
import { useTranslation } from "@/hooks/useTranslation"
import { DataReadinessPlaceholder } from "@/molecules/DataReadinessPlaceholder"
import { LoaderWidget } from "@/molecules/Widget"
import { HeadingCard } from "../../molecules/HeadingCard"

import styles from "./CardDashboardTemplate.module.scss"

import type { CardDashboardTemplateProps } from "."

const CardDashboardTemplate = (props: CardDashboardTemplateProps) => {
  const {
    children,
    gutter,
    wrap,
    loading,
    noData,
    className,
    customPlaceholder,
    unavailable,
    ...headingProps
  } = props
  const teasers = useTeasers(props)
  const showPlaceholder = !customPlaceholder && teasers[0]
  const { card: cardClassName, row: rowClassName } = className || {}
  const noLoadingState = !showPlaceholder && !loading
  const showEmpty =
    !showPlaceholder && (unavailable || (noLoadingState && noData))
  const showChildren =
    !showPlaceholder && noLoadingState && !noData && !unavailable
  const showLoading = !showPlaceholder && loading && !unavailable

  const cardCn = cn(styles.card, cardClassName, {
    [styles.loading]: loading,
    [styles.showEmpty]: showEmpty
  })
  const rowCn = cn(styles.row, rowClassName)
  const { t } = useTranslation("translation")

  return (
    <Card className={cardCn} title={<HeadingCard {...headingProps} />}>
      <Row className={rowCn} gutter={gutter} wrap={wrap}>
        {showPlaceholder && <DataReadinessPlaceholder {...teasers[1]} />}
        {showLoading && <LoaderWidget />}
        {showEmpty && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t(
              !!unavailable
                ? "settings.scopes.widgetUnavailable"
                : "general.noData"
            )}
          />
        )}
        {showChildren && children}
      </Row>
    </Card>
  )
}

export default CardDashboardTemplate
