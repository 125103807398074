import { Flex } from "@/atoms"
import { getKodemScoreColorByScore } from "@/domain/score"
import { TFunction } from "@/hooks/useTranslation"
import type React from "react"

import { Heading } from "../Heading"
import styles from "./TitleScoreCard.module.scss"

export const TitleScoreCard = ({
  score,
  t,
  align
}: {
  score: number
  t: TFunction
  align?: React.CSSProperties["alignItems"]
}) => {
  return (
    <Flex
      justify="space-between"
      align={align}
      gap={16}
      className={styles.container}
    >
      <Heading level={5} title={t("triage.drawer.kodemScoreBreakdown")} />
      <Flex vertical align="center">
        <div className={styles.scoreTitle}>{t("general.score")}</div>
        <div
          className={styles.scoreValue}
          style={{ color: getKodemScoreColorByScore(score) }}
        >
          {score}
        </div>
      </Flex>
    </Flex>
  )
}
