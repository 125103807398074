import { Tree } from "@/atoms"
import { GetProps } from "antd"

export type Module = {
  name: string // display name
  path: string // full path to module
  type: "lock" | "manifest"
  packageManager?: PackageManager
}

export interface ModulesTreeProps extends DirectoryTreeProps {
  /**
   * A list of modules to display in the tree
   */
  modules: Module[]
  edited?: string[]
  /**
   * Whether to merge directories with only directories as children
   */
  merge?: boolean

  /**
   * Whether to show only directories
   */
  directoriesOnly?: boolean
  /**
   * Show repo name with SCM icon as root
   */
  repo?: {
    name: string
    scm: SourceControl.Vendor
  }
}
// Define a utility interface that combines necessary tree node properties
export interface ModuleDataNode {
  title: string | React.ReactNode
  key: string
  icon?: React.ReactNode
  children?: ModuleDataNode[]
  isLeaf?: boolean
}

export const ROOT_KEY = "ROOT"

type DirectoryTreeProps = GetProps<typeof Tree<ModuleDataNode>>
