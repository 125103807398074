import { IconProps, Image, LocalImage } from ".."
import { ImagesIconProps } from "./Images.types"

const imageIconMapping: Record<
  Image.Source,
  (props: IconProps) => JSX.Element
> = {
  local: LocalImage,
  kodem: Image
}

const sourceControlTooltipMapping: Record<Image.Source, string | undefined> = {
  local: "general.localImage",
  kodem: undefined
}

export const ImageIcon = ({
  imageSource,
  className,
  ...props
}: ImagesIconProps) => {
  const Icon = imageIconMapping[imageSource]

  if (!Icon) return null

  return (
    <Icon
      data-tooltip-content={sourceControlTooltipMapping[imageSource]}
      className={className}
      data-tooltip-translation="true"
      {...props}
    />
  )
}
