import { Button, Empty, Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { PlaceholderTemplate } from "@/templates/PlaceholderTemplate"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"

import styles from "./ScmTab.module.scss"

import { Protected } from "@/molecules/Protected"
import { TabProps } from "./ScmTab.types"

export const Tab = (props: TabProps) => {
  const { dataSource, isLoading, components, actions, parentRoute } = props
  const { t } = useTranslation()
  const { t: tPolicies } = useTranslation("translation", {
    keyPrefix: "settings.policies"
  })

  return (
    <TabTemplate
      highlightRow={false}
      columns={getColumnsTable(t, actions)}
      dataSource={dataSource}
      loading={isLoading}
      components={components}
      onRowClick={actions.onEdit}
      locale={
        isLoading
          ? undefined
          : {
              emptyText: (
                <PlaceholderTemplate
                  classes={{
                    container: styles.containerPlaceholder,
                    title: styles.titlePlaceholder
                  }}
                  title={tPolicies("noPolicies")}
                  text={
                    <Link to={`${parentRoute}/new`}>
                      <Protected
                        permission={{
                          resource: "scm.policies",
                          action: "write"
                        }}
                      >
                        <Button type="link">
                          {tPolicies("createFirstPolicy")}
                        </Button>
                      </Protected>
                    </Link>
                  }
                  icon={
                    <Empty
                      className={styles.empty}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={null}
                    />
                  }
                />
              )
            }
      }
    />
  )
}
