import { useEffect, useState } from "react"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { CHECKBOX_ISSUE_DATA_ID } from "@/const/table-constants"
import { isDataAvailable } from "@/helpers/context.helper"
import { checkboxOnClick } from "@/helpers/table.helpers"
import { predicateHelpers } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { useGetIssuesForResource } from "@/organisms/Drawers/applications/inventory/useInventory"
import { IssuesFilter } from "@/organisms/Drawers/components/IssuesFilter"
import { TabDrawerTemplate } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { TRIAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useCounts } from "@/organisms/Drawers/hooks/useCounts"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useParamsFilter } from "@/organisms/Drawers/hooks/useParamsFilter"
import { LabelModal } from "@/organisms/LabelModal"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import { IssueActions } from "@/pages/TriagePage/components/molecules/IssueActions"
import { getColumnsTable } from "./getColumnsTable"

import type { Issue } from "@/domain/issue"
import type { IssuesTabProps } from "./IssuesTab.types"

export const Tab = (props: IssuesTabProps) => {
  const tabKey = props.tabKey || "scaIssues"
  const { getInitialParamsFilter, record, headerComponent, exportQueryParams } =
    props
  const { defaultParams, ...restProps } = props
  const filtersContext = useParamsFilter({
    filterKey: tabKey,
    initialParams: getInitialParamsFilter(),
    ...restProps
  })

  const [dataContext, countsContext] = useGetIssuesForResource(record?.id, {
    ...defaultParams,
    ...filtersContext.params
  })
  const { t } = useTranslation()
  const [selectionState, dispatch] = useSelection()

  useCounts(tabKey, countsContext, props)

  useEffect(() => {
    if (dataContext.isLoading) return
    if (dataContext.isRefetching) return
    if (!dataContext.response) return

    const { data, metadata } = dataContext.response
    const issuesByProject = data.reduce(
      (acc, item: any) => {
        const { projectId } = item

        if (!acc[projectId]) acc[projectId] = []

        acc[projectId].push(item)

        return acc
      },
      {} as Record<string, any[]>
    )

    Object.keys(issuesByProject).forEach((projectId: string) => {
      dispatch(
        actions.setIdsNestedTable(issuesByProject[projectId], projectId, {
          issuesTotal: metadata?.total
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.isLoading, dataContext.isRefetching, props.isActive])

  const [issueRecord, setIssueRecord] = useState<null | Issue>(null)
  const { onRowClick } = useDrawer(TRIAGE_DRAWER_ID)
  const { logger } = useLogger()

  return (
    <>
      <TabDrawerTemplate
        dataContext={dataContext}
        filtersContext={filtersContext}
        getColumnsTable={getColumnsTable}
        expandable={null}
        onRow={(record) => ({
          onClick: (event: React.MouseEvent<HTMLElement>) =>
            checkboxOnClick(event, {
              record: record as { key: string },
              logger,
              cb: onRowClick,
              querySelector: `[${CHECKBOX_ISSUE_DATA_ID}]`,
              analyticEvent: { name: props.drawerId, placement: tabKey }
            })
        })}
        rowSelection={{
          type: "checkbox",
          getCheckboxProps: (record) =>
            ({ [CHECKBOX_ISSUE_DATA_ID]: record.id }) as any,
          hideSelectAll: true,
          selectedRowKeys: selectionState.selectedNestedIds,
          onSelect: (record, selected) => {
            dispatch(actions.onSelectRow(record.projectId, record.id, selected))
          }
        }}
        FilterComponent={
          <IssuesFilter
            t={t}
            includeApplicationFilter={props.includeApplicationFilter}
            exportQueryParams={exportQueryParams}
            isDataAvailable={isDataAvailable(dataContext)}
            defaultParams={defaultParams}
            {...filtersContext}
          />
        }
        asideFooter={
          <IssueActions
            showLoadingMessage
            params={filtersContext.params}
            getOptionsUseDismissIssues={() => (queryClient) => ({
              onSuccess: () => {
                queryClient.removeQueries({
                  predicate: predicateHelpers.includesKeysPredicate([
                    RESOURCES_NAMES.TRIAGE.PROJECTS
                  ])
                })
                return dataContext?.refetch?.()
              },
              onSettled: undefined
            })}
            componentName={`tab:${tabKey || ""}_drawer:${props.drawerId || ""}`}
          />
        }
        headerComponent={headerComponent}
        setIssueRecord={setIssueRecord}
      />
      {issueRecord && (
        <LabelModal
          placement="tooltip"
          component="triage-tab"
          record={issueRecord}
          onClose={() => setIssueRecord(null)}
        />
      )}
    </>
  )
}
