import { Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { List } from "@/molecules/List"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import MetaImage from "../MetaImage"

import type { RuntimeEvidenceTabProps } from "../../RuntimeEvidenceTab.types"

import styles from "../../RuntimeEvidenceTab.module.scss"

export const RuntimeImagesCard = (props: RuntimeEvidenceTabProps) => {
  const { t } = useTranslation()
  const { record } = props
  const runtimeData = record?.runtimeData
  const runtimeImages = runtimeData?.runtimeImages
  const { onRowClick } = useDrawer("")

  if (!runtimeData || !runtimeImages?.length) return null

  return (
    <Flex vertical gap={8} className={styles.content}>
      <Heading
        level={5}
        title={`${t("runtimeEvidence.detectedOn")} ${t(`runtimeEvidence.${runtimeImages.length > 1 ? "related_images_other" : "related_images_one"}`)}:`}
      />
      <List
        limit={3}
        items={runtimeImages}
        classes={{
          list: styles.functionsOrClassesListEntity,
          button: styles.functionsOrClassesListButton
        }}
        render={(runtimeImage) => (
          <MetaImage
            record={runtimeImage}
            onRowClick={onRowClick}
            classes={{
              text: styles.textContent
            }}
          />
        )}
      />
    </Flex>
  )
}
