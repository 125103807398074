import { Fragment } from "react"

import { Tooltip } from "@/atoms"

export const TooltipLabelTab: React.FC<{
  children: React.ReactNode
  tooltip?: string
}> = (props) => {
  if (!props.tooltip) return <Fragment>{props.children}</Fragment>

  return (
    <Tooltip title={props.tooltip}>
      <span>{props.children}</span>
    </Tooltip>
  )
}
