import type { Issue, Type } from "."

export const isCodeIssueType = (issueType: Type) =>
  issueType === "Vulnerable_Code" || issueType === "Exposed_Secret"
export const isCodeIssue = (issue: Issue) => isCodeIssueType(issue.issueType)

export const isMaliciousPackageType = (issueType: Type) =>
  issueType === "Malicious_Package"
export const isMaliciousPackageIssue = (issue: Issue) =>
  isMaliciousPackageType(issue.issueType)

export const isVulnerablePackageType = (issueType: Type) =>
  issueType === "Vulnerable_Package"

export { getColorBySeverity } from "../vulnerability"

export const isPackageIssueType = (issueType: Type) =>
  issueType === "Vulnerable_Package"

export const getLanguage = (issue: Pick<Issue, "language">) => issue.language

export const getRiskInsights = (issue: Pick<Issue, "riskInsights">) =>
  issue.riskInsights || {}

export const isRuntime = (issue: Pick<Issue, "riskInsights">) =>
  !!getRiskInsights(issue).runtime
