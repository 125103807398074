import cn from "classnames"
import { Fragment } from "react"

import { Link, Text } from "@/atoms"
import { IconResource } from "."

import styles from "./Resources.module.scss"

import type { ResourceComponent } from "."

export const Resource: ResourceComponent = (props) => {
  const {
    type,
    name,
    imageSource,
    tag,
    to,
    copyable,
    onClick,
    classes = {}
  } = props
  const isLinkView = !!to || !!onClick
  const Component = isLinkView ? Link : Fragment
  const componentProps = isLinkView
    ? ({ type: "link", to, onClick } as const)
    : {}
  const Wrapper = tag ? "div" : Fragment
  const copyableWithTag = tag && copyable
  const rootClassName = cn(
    styles.section,
    styles.resource,
    classes.container,
    tag && styles.tagResource,
    copyableWithTag && styles.copyableWrapper
  )
  const copyableValues = copyable || {}
  return (
    <div className={rootClassName}>
      <IconResource
        type={type}
        sourceControl={props.sourceControl}
        imageSource={imageSource}
      />
      <Wrapper>
        <Text
          ellipsis
          copyable={copyableValues.name}
          className={cn(styles.nameResource, classes.name)}
          onClick={onClick}
        >
          <Component {...componentProps}>{name}</Component>
        </Text>
        {tag && (
          <Text
            copyable={copyableValues.tag}
            type="secondary"
            className={styles.tag}
          >
            {tag}
          </Text>
        )}
      </Wrapper>
    </div>
  )
}
