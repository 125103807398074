import cn from "classnames"
import { lazy, Suspense } from "react"

import { Link, Skeleton } from "@/atoms"
import {
  FIXABLE_DATA_ID_ANALYTIC_FUNNEL_CARD,
  RUNTIME_ID
} from "@/const/default-values"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useFeatureFlags } from "@/hooks/useUser"
import { StatisticCard } from "@/pages/DashboardPage/components/molecules/StatisticCard"
import { addFixabilityToLink } from "@/pages/DashboardPage/hooks/helpers"

import styles from "./FunnelCard.module.scss"

import type { FunnelCardComponent } from "."

const RuntimeTeasersFunnelCard = lazy(
  () => import("./RuntimeTeasersFunnelCard")
)

export const FunnelCard: FunnelCardComponent = (props) => {
  const {
    d,
    maxViewBox,
    title,
    Icon,
    color,
    loading,
    to,
    selected,
    size,
    fixable,
    ...rest
  } = props

  const useGradient = Array.isArray(color)
  const gradientId = `${props.id}_gradient`
  const fill = useGradient ? `url(#${gradientId})` : color
  const isSmall = props.size === "sm"
  const commonClasses = cn({
    [styles.small]: isSmall,
    [styles.loading]: loading
  })
  const kortexInstalled = useFeatureFlags("kortexInstalled")
  const isBlur = !kortexInstalled && rest.id === RUNTIME_ID
  const classes = {
    container: cn(styles.container, commonClasses, isBlur && styles.blur),
    children: cn(styles.children, commonClasses)
  }
  const { abbreviateNumber } = useNumberConvertor()

  if (loading) {
    const { flex, size, span } = props

    return (
      <StatisticCard
        flex={flex}
        span={span}
        size={size}
        classes={classes}
        valueRender={() => null}
      >
        <Skeleton.Node active />
      </StatisticCard>
    )
  }

  return (
    <StatisticCard
      classes={classes}
      footer={
        isBlur && (
          <Suspense>
            <RuntimeTeasersFunnelCard isSmall={isSmall} />
          </Suspense>
        )
      }
      title={
        <span className={cn(styles.title, isBlur && styles.hidden)}>
          <Icon className={styles.icon} />
          {title}
        </span>
      }
      to={isBlur ? undefined : to}
      selected={isBlur ? false : selected}
      size="sm"
      subTitle={
        fixable !== undefined && (
          <Link to={addFixabilityToLink(to)}>
            <span
              data-analytic-event={FIXABLE_DATA_ID_ANALYTIC_FUNNEL_CARD}
              className={cn(styles.fixable, isBlur && styles.hidden)}
            >{`${abbreviateNumber(fixable)} ${props.t("funnel.fixable")}`}</span>
          </Link>
        )
      }
      {...rest}
    >
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${maxViewBox} ${maxViewBox}`}
        preserveAspectRatio="none"
      >
        {useGradient && (
          <linearGradient id={gradientId}>
            {color.map((gradientProps) => (
              <stop
                key={`${gradientId}${gradientProps.stopColor}`}
                {...gradientProps}
              />
            ))}
          </linearGradient>
        )}
        <path fill={fill} d={d} />
      </svg>
    </StatisticCard>
  )
}
