import { Skeleton } from "@/atoms"
import { DotChartOutlined } from "@/icons"

import styles from "./LoaderWidget.module.scss"

export const LoaderWidget = () => (
  <Skeleton.Node active className={styles.container}>
    <DotChartOutlined className={styles.icon} />
  </Skeleton.Node>
)
