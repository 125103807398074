import cn from "classnames"

import { Descriptions } from "@/atoms"
import type { OverviewDescriptionsComponent } from "."
import { LabelOverviewDescriptions } from "./LabelOverviewDescriptions"
import styles from "./OverviewDescriptions.module.scss"
import { TitleOverviewDescriptions } from "./TitleOverviewDescriptions"

export const OverviewDescriptions: OverviewDescriptionsComponent = ({
  flex,
  layout = "vertical",
  bordered = true,
  classes,
  ...props
}) => {
  if (flex) {
    const { items, column } = props

    if (
      !!items &&
      items.length > 0 &&
      typeof column === "number" &&
      column > 1
    ) {
      const rowItems: any[] = []
      const colItemCount = Math.ceil(items.length / column)

      // If the items length is not evenly divisible by column count,
      // pad the array with empty items
      while (items.length % column !== 0) {
        items.push({ label: "", children: "" })
      }

      items.forEach((item, index) => {
        const rowIndex = index % colItemCount
        rowItems[rowIndex] = [
          ...(rowItems[rowIndex] || []),
          <span className={cn(styles.labelFlex, classes?.flexRow?.label)}>
            {item.label}
          </span>,
          <span className={cn(styles.valueFlex, classes?.flexRow?.children)}>
            {item.children}
          </span>
        ]
      })

      return rowItems.map((rowItems, index) => (
        <div
          key={index}
          //TODO - temporary patch. need to refactor KOD-829
          className={cn(styles.itemFlex2, classes?.flexRow?.container)}
        >
          {...rowItems}
        </div>
      ))
    }

    return (items || []).map((item, index) => (
      <div
        key={index}
        className={cn(styles.itemFlex, classes?.flexRow?.container)}
      >
        <span className={cn(styles.labelFlex, classes?.flexRow?.label)}>
          {item.label}
        </span>
        <span className={cn(styles.valueFlex, classes?.flexRow?.children)}>
          {item.children}
        </span>
      </div>
    ))
  }

  if (bordered) {
    return (
      <Descriptions
        bordered={bordered}
        colon={false}
        size="small"
        layout={layout}
        {...props}
      />
    )
  }

  return (
    <div className={styles.noBorder}>
      <Descriptions
        bordered
        colon={false}
        size="small"
        layout={layout}
        {...props}
      />
    </div>
  )
}

OverviewDescriptions.Label = LabelOverviewDescriptions
OverviewDescriptions.Title = TitleOverviewDescriptions
