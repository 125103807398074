import { Badge, Empty, Tree } from "@/atoms"
import {
  CaretDownOutlined,
  FileOutlined,
  FolderOutlined,
  LockOutlined,
  PackageManagerIcon,
  SourceControlIcon
} from "@/icons"
import { useMemo } from "react"
import {
  ROOT_KEY,
  type Module,
  type ModuleDataNode,
  type ModulesTreeProps
} from "./ModulesTree.types"

function listToTree(
  modules: Module[],
  edited: Set<string>,
  merge: boolean = false,
  directoriesOnly: boolean = false
): ModuleDataNode[] {
  if (modules.length === 0) {
    return []
  }
  const root: ModuleDataNode[] = []
  const buildTree = (
    parts: string[],
    module: Module,
    parent: ModuleDataNode[] = root,
    currentPath: string = ""
  ): void => {
    if (!parts.length) return
    const [part, ...rest] = parts
    currentPath = currentPath ? `${currentPath}/${part}` : part

    let node = parent.find((item) => item.title === part)
    if (!node) {
      node = { title: part, key: currentPath, children: [] }
      parent.push(node)
    }
    node.icon = <FolderOutlined />
    if (rest.length === 0) {
      node.title = module.path.split("/").pop() || module.name
      node.isLeaf = true
      node.key = module.path
      if (module.packageManager) {
        node.icon = (
          <PackageManagerIcon packageManager={module.packageManager} />
        )
      } else {
        node.icon = module.type === "lock" ? <LockOutlined /> : <FileOutlined />
      }
      delete node.children
    } else {
      if (!node.children) {
        node.children = []
      }
      buildTree(rest, module, node.children, currentPath)
    }
  }

  const mergeDirectories = (nodes: ModuleDataNode[]): ModuleDataNode[] => {
    return nodes.map((node) => {
      if (node.children) {
        node.children = mergeDirectories(node.children)

        // Merge only if there is exactly one directory child, allowing recursive collapsing into a single path
        while (
          merge &&
          node.children?.length === 1 &&
          !node.children[0].isLeaf
        ) {
          const child = node.children[0] as ModuleDataNode
          node.title = `${node.title}/${child.title}`
          node.key = `${node.key}/${child.title}`
          node.children = child.children
        }
      }
      if (!node.children || node.children.length === 0) node.isLeaf = true
      return node
    })
  }

  modules.forEach((module) => {
    const pathParts = module.path.split("/").filter((part) => part)
    buildTree(pathParts, module)
  })

  let treeData = merge ? mergeDirectories(root) : root

  if (directoriesOnly) {
    const removeFiles = (nodes: ModuleDataNode[]): ModuleDataNode[] =>
      nodes.filter((node) => {
        if (node.isLeaf) {
          return false // Remove leaf nodes
        }
        if (node.children) {
          node.children = removeFiles(node.children)
        }
        return true
      })

    treeData = removeFiles(treeData)
  }

  // mark the edited
  const markEdited = (nodes: ModuleDataNode[]): ModuleDataNode[] =>
    nodes.map((node) => {
      if (edited.has(node.key)) {
        node.title = (
          <span>
            {node.title} <Badge color="#40A9FF" size="small" />
          </span>
        )
      }
      if (node.children) {
        node.children = markEdited(node.children)
      }
      return node
    })

  return markEdited(treeData)
}

export function ModulesTree({
  modules = [],
  merge = false,
  directoriesOnly = false,
  repo,
  edited = [],
  ...props
}: ModulesTreeProps) {
  const normalizePath = (path: string) => path.replace(/\/+/g, "/")

  const data = useMemo(
    () =>
      listToTree(
        modules,
        new Set(edited.map(normalizePath)),
        merge,
        directoriesOnly
      ),
    [modules, edited, merge, directoriesOnly]
  )

  if (modules.length === 0) {
    return <Empty />
  }

  const treeData = repo
    ? [
        {
          key: ROOT_KEY,
          icon: <SourceControlIcon sourceControl={repo.scm} />,
          title: repo.name,
          children: data
        }
      ]
    : data

  return (
    <Tree
      switcherIcon={<CaretDownOutlined />}
      treeData={treeData}
      showLine
      showIcon
      multiple
      {...props}
    />
  )
}
