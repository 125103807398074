import { Col, Text } from "@/atoms"
import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { ExploitIconsGroup, Runtime } from "@/icons"
import { StatisticDifference } from "@/molecules/StatisticDifference"
import { IconLabelWidget } from "@/pages/DashboardPage/components/molecules/IconLabelWidget"
import { addFixabilityToLink } from "@/pages/DashboardPage/hooks/helpers"
import { getCards } from "@/pages/TriagePage/components/organisms/FunnelTriagePage/helpers.ts"
import ExploitableIssuesOverTimeArea, {
  getColorClassName
} from "../../molecules/ExploitableIssuesOverTimeArea"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./ExploitableIssuesOverTimeWidget.module.scss"

import type { ExploitableIssuesOverTimeWidgetComponent } from "."

export const ExploitableIssuesOverTimeWidget: ExploitableIssuesOverTimeWidgetComponent =
  ({ runtime, response, isLoading, exploitableAndFixableFunnel }) => {
    const { data = [], weekAgoIndex = -1, score: scoreRes = 0 } = response || {}
    const score = exploitableAndFixableFunnel || scoreRes
    const { t } = useTranslation()
    const lastScore = data[0]?.value
    const lastWeekScore = data[weekAgoIndex]?.value
    const diffFromLastWeek = lastWeekScore && lastScore - lastWeekScore
    const isNaN = Number.isNaN(Number(diffFromLastWeek))
    const colorClassName = getColorClassName(score)
    const noTrend = isNaN || weekAgoIndex === -1 || diffFromLastWeek === 0
    const isPositive = diffFromLastWeek < 0
    const funnelCards = getCards(t, new URLSearchParams(), {
      runtime: !!runtime
    })
    const loading = isLoading || runtime === undefined

    return (
      <CardDashboardTemplate
        title={t("dashboard.exploitableIssuesWidget.title")}
        wrap={false}
        loading={loading}
        contentPopover={t(
          `dashboard.exploitableIssuesWidget.${runtime ? "tooltipRuntime" : "tooltip"}`
        )}
        teasers={{ dataReadiness: true }}
        noData={data.length === 0}
      >
        <Col className={styles.aside} flex="0 0 auto">
          <span>
            {runtime && (
              <Text type="secondary" className={styles.runtimeIcon}>
                In <Runtime /> Runtime
              </Text>
            )}
            <IconLabelWidget
              Icon={ExploitIconsGroup.IN_THE_WILD}
              text={score}
              to={addFixabilityToLink(funnelCards[funnelCards.length - 1].link)}
              classes={{ color: colorClassName }}
              MixpanelEvent={
                ANALYTIC_EVENTS.DASHBOARD.EXPLOITABLE_ISSUE
                  .NAVIGATION_TO_ISSUES_PAGE
              }
            />
            <StatisticDifference
              reverse
              isPositive={isPositive}
              noTrend={noTrend}
              isNeutral={noTrend}
              title={t(`dashboard.postureScoreWidget.differenceStatistic`)}
              value={isNaN ? "N/A" : Math.abs(diffFromLastWeek)}
            />
          </span>
        </Col>
        <Col flex="auto">
          <ExploitableIssuesOverTimeArea data={data} />
        </Col>
      </CardDashboardTemplate>
    )
  }
