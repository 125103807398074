import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { FunnelTriagePage } from "@/pages/TriagePage/components/organisms/FunnelTriagePage"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./FunnelDashboard.module.scss"

export const FunnelDashboard = (props: any) => {
  const { t } = useTranslation()

  return (
    <CardDashboardTemplate
      loading={props.isLoading}
      gutter={4}
      title={t("funnel.title")}
      teasers={{
        dataReadiness: true,
        scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t),
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <FunnelTriagePage
        classes={{ container: styles.container }}
        size="lg"
        getData={() => props.context}
        MixPanelEvent={ANALYTIC_EVENTS.DASHBOARD.FUNNEL.NAVIGATION}
      />
    </CardDashboardTemplate>
  )
}
