import { Button, Card, Form, Input, Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CloseOutlined } from "@/icons"
import { ButtonListForm } from "../../molecules/ButtonListForm"
import { TitleCard } from "../../molecules/TitleCard"
import { getOperatorOptions, isCustomProperty } from "./utils"

import styles from "./CardFormList.module.scss"

import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import type { CardFormListProps } from "."

export const CardFormList: React.FC<CardFormListProps> = ({
  name,
  form,
  options,
  cardsLength,
  remove: removeCard,
  isAllResources
}) => {
  const { t } = useTranslation()
  const rules = [
    {
      required: true,
      message: t("error.requiredField"),
      validateTrigger: "onSubmit"
    }
  ]

  const { user } = useUser()
  const isNotAdmin = !isUserAdmin(user)

  const values = Form.useWatch(["conditions", name], form)
  return (
    <Card
      title={
        <TitleCard
          name={name}
          showIncludeExclude={!isAllResources}
          disable={isNotAdmin}
        />
      }
      className={styles.card}
    >
      {isAllResources && (
        <div key="no-conditions" className={styles.wrapper}>
          <Form.Item
            name={[0, "resource"]}
            className={styles.formItem}
            rules={rules}
          >
            <Select
              placeholder={t("general.resource")}
              options={options.resources}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate
            name={[0, "property"]}
            className={styles.propertyFormItem}
            rules={rules}
          >
            <Select placeholder={t("general.property")} disabled={true} />
          </Form.Item>
          <Form.Item
            name={[0, "operator"]}
            className={styles.operator}
            rules={rules}
          >
            <Select placeholder={t("general.operator")} disabled={true} />
          </Form.Item>
          <Form.Item name={[0, "value"]} className={styles.value} rules={rules}>
            <Input placeholder={t("general.value")} disabled={true} />
          </Form.Item>
          <Button
            disabled={true}
            className={styles.removeButton}
            type="text"
            block
            icon={<CloseOutlined />}
          />
        </div>
      )}
      <Form.List name={[name, "filters"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              const { resource, property: propertyName } =
                values?.filters?.[index] || {}
              const property = { resourceName: resource, propertyName }
              const prefix = ["conditions", name, "filters", field.name]
              const onlyOneCardAndRecord =
                cardsLength === 1 && fields.length === 1

              return (
                <div key={field.key} className={styles.wrapper}>
                  <Form.Item
                    name={[field.name, "resource"]}
                    className={styles.formItem}
                    rules={rules}
                  >
                    <Select
                      placeholder={t("general.resource")}
                      options={options.resources}
                      disabled={isNotAdmin}
                      onChange={() => {
                        form.setFields([
                          { name: [...prefix, "property"], value: undefined },
                          { name: [...prefix, "operator"], value: undefined }
                        ])
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate
                    name={[field.name, "property"]}
                    className={styles.propertyFormItem}
                    rules={rules}
                  >
                    <Select
                      placeholder={t("general.property")}
                      options={options.fields[resource]}
                      disabled={isNotAdmin}
                      onChange={() => {
                        form.setFieldValue([...prefix, "operator"], undefined)
                      }}
                    />
                  </Form.Item>
                  {isCustomProperty(property) && (
                    <Form.Item
                      name={[field.name, "exactKey"]}
                      className={styles.value}
                      rules={rules}
                    >
                      <Input
                        placeholder={t("general.exactKey")}
                        disabled={isNotAdmin}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={[field.name, "operator"]}
                    className={styles.operator}
                    rules={rules}
                  >
                    <Select
                      placeholder={t("general.operator")}
                      disabled={isNotAdmin}
                      options={getOperatorOptions(t, property)}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "value"]}
                    className={styles.value}
                    rules={rules}
                  >
                    <Input
                      placeholder={t("general.value")}
                      disabled={isNotAdmin}
                    />
                  </Form.Item>
                  <Button
                    disabled={onlyOneCardAndRecord || isNotAdmin}
                    className={styles.removeButton}
                    type="text"
                    onClick={() => {
                      if (onlyOneCardAndRecord) return
                      if (fields.length === 1) {
                        removeCard()
                      } else {
                        remove(index)
                      }
                    }}
                    block
                    icon={<CloseOutlined />}
                  />
                </div>
              )
            })}
            {!isAllResources && (
              <ButtonListForm disabled={isNotAdmin} onClick={() => add()}>
                {t("general.or")}
              </ButtonListForm>
            )}
          </>
        )}
      </Form.List>
    </Card>
  )
}
