import { Navigate, Route, Routes } from "react-router-dom"

import { message } from "@/atoms"
import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES, PARAMS } from "@/const/routes.constants"
import { Protected } from "@/molecules/Protected"
import { ComingSoonPage } from "@/pages/ComingSoonPage"
import { AutomatedActionPage } from "./components/pages/AutomatedActionPage"
import { AutomatedActionsPage } from "./components/pages/AutomatedActionsPage"
import { CIPage } from "./components/pages/CIPage"
import { ScmPage } from "./components/pages/ScmPage"
import { ScmPoliciesPage } from "./components/pages/ScmPoliciesPage"

import type { Action } from "@/domain/user"

export const GovernancePage = () => {
  const {
    SCM,
    ROOT,
    CI,
    SCAN_HISTORY,
    THREAT_DETECTION_POLICIES,
    AUTOMATION_HISTORY,
    WORKFLOWS
  } = NAMES_ROUTES.GOVERNANCE
  const [messageApi, messageContext] = message.useMessage()

  return (
    <>
      <Routes>
        <Route
          path={`/${SCAN_HISTORY}`}
          element={<ComingSoonPage keyPage="scanHistory" />}
        />
        <Route
          path={`/${AUTOMATION_HISTORY}`}
          element={<ComingSoonPage keyPage="automationHistory" />}
        />
        <Route
          path={`/${THREAT_DETECTION_POLICIES}`}
          element={<ComingSoonPage keyPage="threatDetectionPolicies" />}
        />
        <Route path={`/${SCM}/:needle`} element={<ScmPage />} />
        <Route
          path={`/${SCM}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
          element={
            <ScmPoliciesPage
              policyType="SCM"
              settingsRoute={NAMES_ROUTES.GOVERNANCE.SCM}
              analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.SCM}
            />
          }
        />
        <Route
          path={`/${SCM}/*`}
          element={<Navigate to={`/${ROOT}/${SCM}/protect`} />}
        />

        <Route path={`/${CI}/:needle`} element={<CIPage />} />
        <Route
          path={`/${CI}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
          element={
            <ScmPoliciesPage
              policyType="CI"
              settingsRoute={NAMES_ROUTES.GOVERNANCE.CI}
              analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.CI}
            />
          }
        />
        <Route
          path={`/${CI}/*`}
          element={<Navigate to={`/${ROOT}/${CI}/protect`} />}
        />
        <Route
          path={`/${WORKFLOWS}`}
          element={<AutomatedActionsPage messageApi={messageApi} />}
        />
        <Route
          path={`/${WORKFLOWS}/:${PARAMS.ID}`}
          element={
            <Protected
              permission={{
                resource: "governance.workflows",
                action: "write" as Action
              }}
              fallback={<Navigate to={`/${ROOT}/${WORKFLOWS}`} />}
            >
              <AutomatedActionPage messageApi={messageApi} />
            </Protected>
          }
        />
        <Route path="/history" element={<Navigate to="/" />} />
        <Route path="/policies" element={<Navigate to="/" />} />
        <Route path="/*" element={<Navigate to={`/${ROOT}/${SCM}`} />} />
      </Routes>
      {messageContext}
    </>
  )
}
