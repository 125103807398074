import { Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { DetailedEvidenceCard } from "./components/DetailedEvidenceCard"
import { KeyEvidenceCard } from "./components/KeyEvidenceCard"
import { RuntimeApplicationCard } from "./components/RuntimeApplicationCard"

import type { RuntimeEvidenceTabProps } from "./RuntimeEvidenceTab.types"

import styles from "./RuntimeEvidenceTab.module.scss"

export const RuntimeEvidenceTab = (props: RuntimeEvidenceTabProps) => {
  const { t } = useTranslation()
  const { record } = props
  const runtimeData = record?.runtimeData
  const keyEvidence = runtimeData?.keyEvidence

  if (!runtimeData || !keyEvidence) return null

  return (
    <Flex vertical gap={16} className={styles.container}>
      <Flex vertical gap={8}>
        <Heading
          level={5}
          title={t("runtimeEvidence.keyEvidence")}
          popover={{
            className: styles.popover,
            content: (
              <InformationPopover.CommonTemplate
                classes={{ content: styles.contentPopover }}
              >
                {t("runtimeEvidence.keyEvidencePopoverTitle")}
                <br />
                {t("runtimeEvidence.keyEvidencePopoverDesc")}
              </InformationPopover.CommonTemplate>
            )
          }}
        />
        <Heading level={6} title={keyEvidence.displayText} />
      </Flex>
      <KeyEvidenceCard {...props} />
      <RuntimeApplicationCard {...props} />
      <DetailedEvidenceCard {...props} />
    </Flex>
  )
}
