import cn from "classnames"

import { Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckOutlined } from "@/icons"

import styles from "./DescriptiveSelect.module.scss"

import type { DescriptiveSelectProps, TypedOption } from "."

/**
 * Can use with translation key to add labels and descriptions to values or with TypedOption to add options with descriptions directly.
 * @template T - The type of the select value (string or number)
 * @param {DescriptiveSelectProps} props - The props of the component
 * @param {string} props.translationKey - The translation key to use for the labels and descriptions (optional)
 * @param {T[] | TypedOption<T>[]} props.values - The values to use for the options or the options directly without `translationKey`
 * @param {...SelectProps<T, TypedOption<T>>} props - All other props from antd Select
 */
export function DescriptiveSelect<T extends string | number>(
  props: DescriptiveSelectProps<T>
) {
  const { translationKey, values, ...rest } = props
  const { t } = useTranslation("translation", {
    keyPrefix: translationKey
  })
  const options = translationKey
    ? values.map(
        (value: T) =>
          ({
            label: t(value.toString()),
            value: value,
            description: t(`${value}_DESCRIPTION`)
          }) as TypedOption<typeof value>
      )
    : (values as TypedOption<T>[])
  const { value: selectedValue } = rest

  return (
    <Select<T, TypedOption<T>>
      options={options}
      optionRender={(option) => {
        const { label, value, description } = option.data
        const checked = value === selectedValue && !!selectedValue

        return (
          <div className={styles.optionContainer}>
            <div className={styles.optionWrapper}>
              <div className={styles.label}>{label}</div>
              {description && (
                <div className={styles.description}>{description}</div>
              )}
            </div>
            <CheckOutlined
              className={cn(styles.check, checked && styles.checked)}
            />
          </div>
        )
      }}
      {...rest}
    />
  )
}
