import cn from "classnames"

import { Popover, Text } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { Trans } from "@/hooks/useTranslation"
import { Runtime, SafetyOutlined } from "@/icons"

import { IconLabelWidget } from "@/pages/DashboardPage/components/molecules/IconLabelWidget"

import styles from "./VulnerableFunctionSlide.module.scss"

import type { VulnerableFunctionSlideComponent } from "."

export const VulnerableFunctionSlide: VulnerableFunctionSlideComponent = (
  props
) => {
  const { executed, value, suffix, to, t, MixpanelEvent } = props
  const { abbreviateNumber } = useNumberConvertor()
  const colorCN = cn(styles.textExecuted, executed && styles.executed)
  const status = t(executed ? "issueExecuted" : "issueNotExecuted")

  return (
    <div className={styles.container}>
      <IconLabelWidget
        classes={{ color: colorCN }}
        Icon={executed ? Runtime : SafetyOutlined}
        text={
          <span className={styles.value}>
            <span>{abbreviateNumber(value)}</span>
            {!!suffix && (
              <Popover
                content={
                  <div className={styles.contentPopover}>
                    <Trans>
                      {t(
                        executed
                          ? "percentageRuntimeTooltip"
                          : "percentageWithoutRuntimeTooltip"
                      )}
                    </Trans>
                  </div>
                }
              >
                <Text underline type="secondary">{`${suffix}%`}</Text>
              </Popover>
            )}
          </span>
        }
        to={to}
        MixpanelEvent={MixpanelEvent}
      />
      <span className={cn(styles.text, colorCN)}>{status}</span>
      <div className={styles.descriptionWrapper}>
        <Text className={styles.text} type="secondary">
          {t("description")}
        </Text>
        <Text className={cn(styles.text, styles.lowercase)} type="secondary">
          {`${t(
            executed
              ? "issueExecutedDescription"
              : "issueNotExecutedDescription"
          )}.`}
        </Text>
      </div>
    </div>
  )
}
