import { ActionsCell } from "@/molecules/ActionsCell"
import { ContentConfirmDelete } from "./ContentConfirmDelete"

import type { Label } from "@/domain/label"
import type { ActionsLabelFunction } from "./ActionsLabelCell.types"

export const ActionsLabelCell: ActionsLabelFunction =
  ({ actions, t, http }) =>
  (_: unknown, record: Label) => {
    const items = [
      { key: "edit", label: t("general.edit") },
      { key: "delete", label: t("general.delete") }
    ]

    return (
      <ActionsCell
        items={items}
        onClick={({ key }) => {
          if (key === items[0].key) return actions.onEdit(record)

          actions.onDelete(
            record,
            <ContentConfirmDelete record={record} http={http} />
          )
          /* if (key === items[1].key)
            return Modal.confirm({
              destroyOnClose: true,
              footer: (originalNode) => (
                <div
                  className={styles.footer}
                  data-footer-actions-label={record.id}
                >
                  {originalNode}
                </div>
              ),
              cancelText: t("general.cancel"),
              okText: t("general.delete"),
              okButtonProps: { className: "ant-btn-primary" },
              content: <ContentConfirmDelete record={record} http={http} />,
              onOk: () => actions.onDelete(record)
            }) */
        }}
      />
    )
  }
