import { Popover, Tag, Text } from "@/atoms"
import { isImageResource } from "@/domain/resource"
import { preventDefaultWrapper } from "@/helpers/utils"
import { EC2, Image, SourceControlIcon } from "@/icons"
import { SingleImageResourcePopover } from "@/molecules/Resources"
import { Resource as ResourceComponent } from "@/pages/TriagePage/components/molecules/ResourceCell/Resource"

import styles from "./ResourceCell.module.scss"

import type { Resource, TypeResource } from "@/domain/issue"

export const ResourceCell =
  (props: {
    openPopover?: boolean
    onRowClick?: (record: { key: string }, type: TypeResource) => void
  }) =>
  (resource: Resource) => {
    const { onRowClick } = props
    const { name, sourceControl, tag, baseImageDetailsExist, type, subTitle } =
      resource
    const nameWithTag = `${name}${tag ? `:${tag}` : ""}`
    const Wrapper = onRowClick ? Popover : "div"
    const Icon = type === "Vm" ? EC2 : Image
    const showSubtitle = !!subTitle
    const showTag = !!tag && !showSubtitle
    const imageResource = isImageResource(resource)
    const onClick = preventDefaultWrapper(() => {
      onRowClick?.({ key: resource.id }, type)
    })

    return (
      <div className={styles.container}>
        <span className={styles.icon}>
          {sourceControl ? (
            <SourceControlIcon sourceControl={sourceControl} />
          ) : (
            <Icon className={styles.vmIcon} />
          )}
        </span>

        <Wrapper
          destroyTooltipOnHide
          open={props.openPopover}
          trigger="hover"
          placement="topLeft"
          rootClassName="popover-resource-cell"
          className={styles.titleSection}
          content={
            !!onRowClick
              ? ((() =>
                  imageResource ? (
                    <SingleImageResourcePopover
                      onClick={onClick}
                      {...resource}
                    />
                  ) : (
                    <Text
                      underline
                      className={styles.textPopover}
                      onClick={onClick}
                    >
                      <ResourceComponent
                        type={sourceControl ? "CodeRepository" : type}
                        name={nameWithTag}
                        sourceControl={sourceControl}
                        imageSource={resource.imageSource}
                      />
                    </Text>
                  )) as any)
              : ""
          }
        >
          <span>{name}</span>
          {showTag && <span className={styles.subTitle}>{`Tag: ${tag}`}</span>}
          {showSubtitle && <span className={styles.subTitle}>{subTitle}</span>}
        </Wrapper>
        {!!baseImageDetailsExist && (
          <Tag className={styles.tag}>From Base Image</Tag>
        )}
      </div>
    )
  }
