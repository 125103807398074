import { memo, useRef } from "react"

import { Line, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import usePostureScoreGraph from "./usePostureScoreGraph"

import styles from "./PostureScoreGraph.module.scss"

const PostureScoreGraph = ({ data }: any) => {
  const props = usePostureScoreGraph({ data })
  const { t } = useTranslation("translation", {
    keyPrefix: "dashboard.postureScoreWidget"
  })
  const analystEventSent = useRef<boolean>(false)
  const { logger, EVENTS } = useLogger()

  if (!props) {
    return null
  }

  return (
    <>
      <Text className={styles.title}>{t("titleGraph")}</Text>
      <Line
        onEvent={(_, event) => {
          const type = event?.type
          const shouldSendEvent = !analystEventSent.current

          if (shouldSendEvent && type === "tooltip:show") {
            analystEventSent.current = true
            logger.info(
              EVENTS.ANALYTIC_EVENTS.DASHBOARD.POSTURE_SCORE_OVERTIME.HOVER
            )
          }

          if (!shouldSendEvent && type === "tooltip:hide")
            analystEventSent.current = false
        }}
        tooltip={{
          customContent: (_, data) => {
            const { value, fromNow, valueColor } = data[0]?.data || {}

            return (
              <Text>
                {fromNow} - <span style={{ color: valueColor }}>{value}</span>
              </Text>
            )
          }
        }}
        {...props}
      />
      <div className={styles.legend}>
        <div>- - -</div>
        <Text type="secondary">{t("industryBenchmark")}</Text>
      </div>
    </>
  )
}

export default memo(
  PostureScoreGraph,
  (prevProps, nextProps) => prevProps.data === nextProps.data
)
