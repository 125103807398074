import cn from "classnames"
import { useEffect, useRef } from "react"

import { useGetLabels } from "@/api/useLabels"
import { Button, Checkbox, Form, Input, message, Modal } from "@/atoms"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useLogger } from "@/hooks/useLogger"
import { useModal } from "@/hooks/useModal"
import { useTranslation } from "@/hooks/useTranslation"
import { TagOutlined } from "@/icons"
import { FooterModal } from "@/molecules/FooterModal"
import { LabelTag } from "@/molecules/LabelTag"
import { usePatchLabelOnEntities } from "@/organisms/LabelModal/applications/useLabelsModal"
import { CreateModal } from "./components/CreateModal"
import { EmptyState } from "./components/EmptyState"
import { ModalTemplate } from "./components/ModalTemplate"
import { MAX_LABEL_LENGTH, MAX_LABELS_ON_SCREEN } from "./const"
import useLabelState, * as actions from "./hooks/useLabelState"

import styles from "./LabelModal.module.scss"

import type { EntityType, PayloadPatch } from "@/api/useLabels.types"
import type { Label } from "@/domain/label"
import { Permission } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { Protected } from "@/molecules/Protected"
import type { LabelModalComponent } from "."

export const LabelModal: LabelModalComponent = (props) => {
  const {
    items,
    record,
    onFinish,
    onClose,
    btnType,
    btnSize = "small",
    placement = "tab",
    disabled = false
  } = props
  const { state, dispatch } = useLabelState()
  const { visible, show, hide } = useModal()
  const { refetch, response, isLoading, isError } = useGetLabels()
  const { data = [] } = response || {}
  const switchCreateLabelModal = () => dispatch(actions.switchOpenCreateModal())
  const getValue = () =>
    (!!record
      ? {
          [record.projectId]: {
            nestedItems: [record],
            selectedNestedIds: [record.id]
          }
        }
      : items) || []

  useEffect(() => {
    const value = getValue()

    if (!!data?.length && !!value && !isError && !loading)
      dispatch(actions.updateOptions(data, value, { reset: !visible }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, items, record])

  const isPlacementTooltip = placement === "tooltip"

  useEffect(() => {
    if (isPlacementTooltip && !!record) show()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, isPlacementTooltip])

  useEffect(() => {
    if (visible) refetch()
  }, [visible, refetch])

  const [labelActions, { isPending: loading }] = usePatchLabelOnEntities()
  const formId = "ApplyLabels"
  const [form] = Form.useForm()
  const { isDirty, onFieldsChange } = useCustomFunctionsForm({
    visible
  })
  const htmlType = "submit" as const
  const formDisabled = !isDirty
  const buttonPros = { loading, disabled: formDisabled, htmlType, form: formId }
  const { isNewValue, options, searchValue, openCreateModal } = state
  const onCreateLabel = (label: Label) => {
    dispatch(actions.addNewLabel(label))
    form.resetFields([searchInputName])

    if (formDisabled) onFieldsChange()
  }
  const searchInputName = "searchInput"
  const { hasPermission } = useUser()
  const permission: Permission = { resource: "labels", action: "write" }
  const isAllowed = hasPermission(permission)
  const shouldOpenSettings = useRef(false)
  const shouldShowScroll = options.length > MAX_LABELS_ON_SCREEN
  const optionsExist = state.options.length > 0
  const { t } = useTranslation()
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "settings.labels.modal.form"
  })
  const { logger } = useLogger()

  return (
    <>
      {!isPlacementTooltip && (
        <Protected permission={permission} shouldDisable>
          <Button
            icon={<TagOutlined />}
            size={btnSize}
            type={btnType}
            disabled={disabled}
            onClick={show}
          >
            {t("general.label")}
          </Button>
        </Protected>
      )}
      <Modal
        open={visible}
        rootClassName={styles.modal}
        onCancel={hide}
        okText={t("general.apply")}
        okButtonProps={buttonPros}
        footer={(originalNode) =>
          FooterModal(originalNode, {
            Aside: (
              <Protected permission={permission}>
                <Button
                  icon={<TagOutlined />}
                  onClick={() => {
                    shouldOpenSettings.current = true
                    hide()
                  }}
                >
                  {tForm("manage")}
                </Button>
              </Protected>
            )
          })
        }
        afterOpenChange={(isOpen) => {
          if (isOpen) form.getFieldInstance(searchInputName)?.focus()
          else {
            if (shouldOpenSettings.current) {
              shouldOpenSettings.current = false
              window.open("/settings/labels", "_blank")
            }

            form.resetFields()
            dispatch(actions.updateOptions(data, getValue(), { reset: true }))
            onClose?.()
          }
        }}
      >
        <ModalTemplate title={t("settings.labels.title")} isLoading={isLoading}>
          <Form
            autoComplete="off"
            form={form}
            name={formId}
            layout="vertical"
            initialValues={{
              labels: state.options
            }}
            onFinish={async () => {
              try {
                const entity_type: EntityType = "issue"
                const entities = state.issues.map((id) => ({
                  entity_id: id,
                  entity_type
                }))
                const payload = state.options.reduce(
                  (acc, { checked, initialState, id }) => {
                    if (checked === initialState) return acc

                    if (checked) acc.label_ids_to_add.push(id)
                    if (!checked) acc.label_ids_to_remove.push(id)

                    return acc
                  },
                  {
                    entities,
                    label_ids_to_add: [],
                    label_ids_to_remove: []
                  } as PayloadPatch
                )
                const { label_ids_to_add, label_ids_to_remove } = payload

                if (!!label_ids_to_add.length || !!label_ids_to_remove.length) {
                  const { component } = props

                  await labelActions.patch(payload, { placement, component })
                }

                onFinish?.()
                hide()
              } catch (err: any) {
                const isDetailsString =
                  !!err?.data?.details && typeof err.data.details === "string"

                message.error(
                  isDetailsString ? err.data.details : "Failed to assign labels"
                )
                logger.error(err, {
                  tags: {
                    placement,
                    component: props?.component,
                    custom_error: "PATCH_LABEL_MODAL"
                  }
                })
              }
            }}
          >
            <Form.Item name={searchInputName} label={tForm("search.label")}>
              <Input
                className={styles.searchInput}
                placeholder={tForm("search.placeholder")}
                value={searchValue}
                suffix={
                  <Protected permission={permission}>
                    <Button
                      className={cn(!isNewValue && styles.displayNone)}
                      size="small"
                      onClick={() => switchCreateLabelModal()}
                    >
                      {tForm("create")}
                    </Button>
                  </Protected>
                }
                onChange={(event) => {
                  const { value } = event.target

                  if (value.length > MAX_LABEL_LENGTH) return

                  dispatch(actions.onSearch(event.target.value))
                }}
              />
            </Form.Item>

            <div
              className={cn(
                styles.checkboxGroup,
                shouldShowScroll && styles.scrollCheckboxGroup
              )}
            >
              {!optionsExist && (
                <EmptyState hasWritePermissions={isAllowed} isEmpty />
              )}
              {optionsExist && state.noSearchResults && (
                <EmptyState hasWritePermissions={isAllowed} />
              )}
              {options.map(({ hide, ...option }) => {
                if (hide) return null

                const { checked, id } = option

                return (
                  <div key={id} className={styles.wrapCheckbox}>
                    <Checkbox
                      checked={!!checked}
                      indeterminate={checked === "indeterminate"}
                      onChange={() => {
                        dispatch(actions.switchCheckedState(id))

                        if (!isDirty) onFieldsChange()
                      }}
                    >
                      <LabelTag {...option} />
                    </Checkbox>
                  </div>
                )
              })}
            </div>
          </Form>
        </ModalTemplate>
      </Modal>
      <CreateModal
        open={openCreateModal}
        label={{ name: state.searchValue }}
        onCreate={onCreateLabel}
        onCancel={switchCreateLabelModal}
        placement={placement}
        component={props.component}
      />
    </>
  )
}
