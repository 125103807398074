import { Checkbox, Form, Input, Space, Text } from "@/atoms"
import { InformationPopover } from "@/molecules/InformationPopover"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImageAutoComplete } from "./ImagesAutoComplete"
import { MatchBadge } from "./MatchBadge"
import styles from "./MonorepoConfigurationsForm.module.scss"
import type { MonorepoConfigurationsFormValues } from "./MonorepoConfigurationsForm.types"

const HelperLabel = (props: { label: string; help: string }) => {
  return (
    <Space>
      {props.label}
      <InformationPopover.Popover
        content={
          <InformationPopover.CommonTemplate>
            {props.help}
          </InformationPopover.CommonTemplate>
        }
      />
    </Space>
  )
}
export function MonorepoConfigurationsForm(props: {
  /**
   * Name of the project
   */
  // TODO: we are not allowing user to change it ATM. Once we do should go into values
  projectName: string
  /**
   * Values
   */
  values: MonorepoConfigurationsFormValues
  /**
   * Called when change was made to any field
   */

  isRootSelected: boolean

  onChange: (values: MonorepoConfigurationsFormValues) => void
}) {
  const { t } = useTranslation()
  const { values } = props
  const {
    imageRepositories: selectedImageRepositories = [],
    splitPath = false,
    exclude = false
  } = values
  const correlations = useMemo(() => {
    return selectedImageRepositories.reduce(
      (acc, value) => {
        acc[value.imageRepository] = value.correlationType
        return acc
      },
      {} as Record<string, "auto" | "manual">
    )
  }, [values])

  const handleChange = <
    K extends keyof MonorepoConfigurationsFormValues,
    V = MonorepoConfigurationsFormValues[K]
  >(
    field: K,
    newValue: V
  ) => {
    const values = {
      ...props.values,
      [field]: newValue
    }
    props.onChange(values)
  }

  return (
    <>
      <Text className={styles.title}>
        {t("inventory.codeRepositoriesTab.drawer.config.projectConfigurations")}
      </Text>

      <Form layout="vertical">
        <div>
          <Form.Item>
            <Checkbox
              disabled={props.isRootSelected}
              checked={splitPath}
              onChange={(v) => {
                handleChange("splitPath", v.target.checked)
              }}
            >
              {t("inventory.codeRepositoriesTab.drawer.config.splitLabel")}
            </Checkbox>
          </Form.Item>
          {splitPath && (
            <Form.Item
              label={
                <HelperLabel
                  label={t(
                    "inventory.codeRepositoriesTab.drawer.config.projectNameLabel"
                  )}
                  help={t(
                    "inventory.codeRepositoriesTab.drawer.config.projectNameHelp"
                  )}
                />
              }
            >
              <Input disabled value={props.projectName} />
            </Form.Item>
          )}
          <Form.Item
            label={
              <HelperLabel
                label={t(
                  "inventory.codeRepositoriesTab.drawer.config.imageCorrelationsLabel"
                )}
                help={t(
                  "inventory.codeRepositoriesTab.drawer.config.imageCorrelationsHelp"
                )}
              />
            }
            help={
              <Text type="secondary">
                <Space>
                  <span>
                    {t(
                      "inventory.codeRepositoriesTab.drawer.config.correlationDoneByLabel"
                    )}
                  </span>
                  <span>
                    <MatchBadge matchType="auto" />{" "}
                    {t(
                      "inventory.codeRepositoriesTab.drawer.config.correlationAuto"
                    )}
                  </span>
                  <span>
                    <MatchBadge matchType="manual" />{" "}
                    {t(
                      "inventory.codeRepositoriesTab.drawer.config.correlationManual"
                    )}
                  </span>
                </Space>
              </Text>
            }
          >
            <ImageAutoComplete
              selectedImageRepositories={selectedImageRepositories}
              value={selectedImageRepositories.map((v) => ({
                value: v.imageRepository,
                label: v.imageRepository,
                ...v
              }))}
              onChange={(values) => {
                if (!values) {
                  handleChange("imageRepositories", [])
                  return
                }
                handleChange(
                  "imageRepositories",
                  values.map((value) => ({
                    imageRepository: value,
                    correlationType: correlations[value] || "manual"
                  }))
                )
              }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              disabled={!splitPath && !props.isRootSelected}
              checked={exclude}
              onChange={(v) => {
                handleChange("exclude", v.target.checked)
              }}
            >
              {props.isRootSelected
                ? t(
                    "inventory.codeRepositoriesTab.drawer.config.excludeRepoLabel"
                  )
                : t("inventory.codeRepositoriesTab.drawer.config.excludeLabel")}
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
