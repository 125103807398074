import { useNavigate } from "react-router-dom"

import { Button, Divider, Empty } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./EmptyMenu.module.scss"

import { Protected } from "@/molecules/Protected"
import type { EmptyMenuProps } from "."
import { FooterMenu } from "../FooterMenu"

export const EmptyMenu = (props: EmptyMenuProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.scopes" })
  const navigate = useNavigate()
  const onClick = () => {
    props.actions.setOpenKeys([])
    navigate("/settings/scopes/new")
  }

  return (
    <Protected
      permission={{ resource: "settings.scopes", action: "write" }}
      fallback={
        <Empty
          className={styles.container}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <p className={styles.text}>{t("noScopesUser")}</p>
              <p className={styles.text}>{t("noScopesUserMsg")}</p>
              <Divider className={styles.divider} />
              <div className={styles.footer}>
                <FooterMenu
                  applyDisabled={true}
                  clearDisabled={true}
                  manageScopesDisabled={true}
                  actions={props.actions}
                />
              </div>
            </>
          }
        />
      }
    >
      <Empty
        className={styles.container}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <>
            <p className={styles.text}>{t("noScopes")}</p>
            <p className={styles.text}>{t("descriptions")}</p>
            <Button type="link" className={styles.button} onClick={onClick}>
              {t("createFirstNewScope")}
            </Button>
          </>
        }
      />
    </Protected>
  )
}
