import { Button, Link } from "@/atoms"
import type { LinkButtonProps } from "."

export const LinkButton = (props: LinkButtonProps) => {
  const { children, to, href, target, ...buttonProps } = props
  return (
    <Link to={to} href={href} target={target}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}
