import { useIssueFindings } from "@/api/useTriage"
import { Col } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FixesLocationCard } from "@/molecules/FixesLocationCard"
import { useCopyable } from "@/organisms/Drawers/components/TriageDrawer/hooks/useCopyable"

import type {
  ExposedSecretFinding,
  VulnerableCodeFinding
} from "@/domain/issue"

export const VulnerableCodeCards = ({
  record
}: {
  record?: { id: string }
}) => {
  const id = record?.id
  const context = useIssueFindings({ needle: id || "" }, { enabled: !!id })
  const recordFinding = context.response?.data[0] as
    | VulnerableCodeFinding
    | ExposedSecretFinding
    | undefined
  const { t } = useTranslation()
  const elementId = "VulnerableCodeCardsComponent"
  const onCopy = useCopyable(elementId, recordFinding?.issueType, {
    onFindNode: (node) => {
      node
        .querySelectorAll(
          '[data-component-id="title-card-fixes-description"], button'
        )
        .forEach((el) => el.remove())
    }
  })

  return (
    <Col
      id={elementId}
      span={24}
      data-element-issue-expandable-cell-view="fix-container"
    >
      <FixesLocationCard t={t} record={recordFinding} onCopy={onCopy} />
    </Col>
  )
}
