import cn from "classnames"
import { useNavigate } from "react-router-dom"

import { Button, Link, Tooltip } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import { useLogger } from "@/hooks/useLogger"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { IssueTypeIcon } from "@/icons"
import { SeverityTag } from "@/molecules/SeverityTag"

import styles from "./SeverityIssueBar.module.scss"

import type { Severity } from "@/domain/vulnerability"
import type { SeverityIssueBarComponent } from "."

export const SeverityIssueBar: SeverityIssueBarComponent = (props) => {
  const { issueType, values, runtime, connectSCM, allIsZero } = props
  const { t } = useTranslation()
  const label = t(`issueTypes.${issueType}`)
  const { abbreviateNumber, intlNumber } = useNumberConvertor()
  const valuesLength = values?.length
  const count = values?.[valuesLength - 1]
  const noData = !valuesLength || !count
  const numbersOfIssues = noData ? 0 : abbreviateNumber(count)
  const showNoData = noData && !allIsZero && !connectSCM
  const showConnectSCM = noData && connectSCM
  const showBar = !showNoData && !showConnectSCM
  const severities: Severity[] = ["critical", "high", "medium"]
  const navigate = useNavigate()
  const { logger, EVENTS } = useLogger()

  return (
    <div className={cn("issue-card-widget-dashboard", styles.container)}>
      <div className={styles.heading}>
        <span className={styles.title}>
          <IssueTypeIcon type={issueType} />
          <span
            className={styles.label}
          >{`${label}${runtime ? " In Runtime" : ""}`}</span>
        </span>
        <span className={styles.count}>
          {allIsZero ? "-" : numbersOfIssues}
        </span>
      </div>
      {showNoData && !allIsZero && (
        <span className={styles.noData}>All is well here!</span>
      )}
      {showConnectSCM && (
        <Link
          type="link"
          className={cn(styles.noData, styles.link)}
          to="/integrations"
        >
          Connect with SCM
        </Link>
      )}
      {showBar && (
        <div className={styles.bar}>
          {severities.map((severity, i) => {
            if (!values[i] && !allIsZero) return null

            const countIndex = values.length - 1
            const percentage = (values[i] / values[countIndex]) * 100
            const colorSuffix = allIsZero ? "all" : severity
            const open = allIsZero ? false : undefined

            return (
              <Tooltip
                open={open}
                key={severity}
                placement="top"
                title={
                  <span className={styles.contentTooltip}>
                    <SeverityTag t={t} severity={severity} />{" "}
                    <span>{intlNumber(values[i])}</span>
                  </span>
                }
              >
                <Button
                  id={`${severity}_${i}`}
                  key={severity}
                  style={
                    {
                      height: "10px",
                      flexBasis: `${allIsZero ? 100 : percentage}%`,
                      minWidth: "3%",
                      "--active-color-bar": `var(--active-color-${colorSuffix}-bar)`,
                      "--bg-color-bar": `var(--base-color-${colorSuffix}-bar)`,
                      "--hover-color-bar": `var(--hover-color-${colorSuffix}-bar)`
                    } as React.CSSProperties
                  }
                  size="xs"
                  className={styles.button}
                  disabled={allIsZero}
                  onClick={() => {
                    setTimeout(() => {
                      const { ROOT, ISSUES } = NAMES_ROUTES.TRIAGE
                      let url = `/${ROOT}/${ISSUES}?pageNumber=0&issueType=${issueType}&severity=${capitalizeFirstLetter(severity)}&issueStatus=open`

                      if (runtime && issueType === "Vulnerable_Package")
                        url += "&runtime=YES"

                      navigate(url)
                    }, 150)

                    logger.info(
                      EVENTS.ANALYTIC_EVENTS.DASHBOARD.ISSUE_TYPES_BY_SEVERITY
                        .ISSUE_TYPE_CLICKED,
                      { severity, issueType }
                    )
                  }}
                />
              </Tooltip>
            )
          })}
        </div>
      )}
    </div>
  )
}
