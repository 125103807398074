import { useGetInventorySnapshot } from "@/api/useInventory"
import { useGetStatistics } from "@/api/useStatistics"
import { useFeatureFlags } from "@/hooks/useUser"
import { useFunnelTriage } from "@/pages/TriagePage/components/organisms/FunnelTriagePage"
import { snapshotInventoryPropsSelector } from "../selectors/inventory.selector"
import { statisticsSelector } from "../selectors/statistics.selector"

import type { DashboardProps } from "./useDashboard.types"

const useDashboard = (): DashboardProps => {
  const postureResponse = useGetStatistics(
    { needle: "/posture_score" },
    { select: statisticsSelector }
  )

  const kortexInstalledFF = useFeatureFlags("kortexInstalled")
  const {
    isLoading: isLoadingExploitableIssues,
    ...exploitableIssuesResponse
  } = useGetStatistics(
    {
      needle: kortexInstalledFF
        ? "/runtime_exploitable_issues"
        : "/exploitable_issues"
    },
    { select: statisticsSelector, enabled: kortexInstalledFF !== undefined }
  )

  const inventorySnapshotResponse = useGetInventorySnapshot(undefined, {
    select: snapshotInventoryPropsSelector
  })

  const funnelContext = useFunnelTriage()
  const runtime = kortexInstalledFF
  const fixableIssuesResponse = funnelContext[3] // 3 - fixable issues
  const isLoadingFunnel = fixableIssuesResponse?.[1]?.isLoading
  const fixableIssues = fixableIssuesResponse?.[0]
  const exploitableAndFixableFunnel =
    fixableIssues && fixableIssues[fixableIssues.length - 1]?.data?.data

  return {
    runtime,
    postureProps: postureResponse,
    inventorySnapshotProps: inventorySnapshotResponse,
    funnelProps: {
      context: funnelContext,
      isLoading: funnelContext[4].isLoading
    },
    exploitableProps: {
      exploitableAndFixableFunnel,
      isLoading: isLoadingExploitableIssues || isLoadingFunnel,
      ...exploitableIssuesResponse
    }
  }
}

export default useDashboard
