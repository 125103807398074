import { useIssue } from "@/organisms/Drawers/applications/triage/useTriage"
import { RuntimeEvidenceTab } from "@/organisms/Drawers/components/RuntimeEvidenceTab"
import { TAB_NAMES } from "@/organisms/Drawers/const"

import type { IssueRuntimeEvidenceTabProps } from "./IssueRuntimeEvidenceTab.types"

import { useEffect } from "react"

export const IssueRuntimeEvidenceTab = (
  props: IssueRuntimeEvidenceTabProps
) => {
  const issueId = props.record?.id
  const { response, isLoading } = useIssue(issueId)
  const record = response?.data?.[0]

  useEffect(() => {
    if (isLoading) return

    if ((record?.runtimeData?.runtimeEvidence || []).length > 0)
      props.actions.onChangeTabAvailability(TAB_NAMES.runtimeEvidence, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, record])

  if (isLoading || !record || !record.runtimeData) return null

  return <RuntimeEvidenceTab record={record} />
}
