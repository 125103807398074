import cn from "classnames"

import { Card } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CopyButton } from "@/molecules/CopyButton"
import { FixabilityCell } from "@/molecules/FixabilityCell"
import { Heading } from "@/molecules/Heading"
import { IssueExpandableCell } from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell"
import { useCopyable } from "@/organisms/Drawers/components/TriageDrawer/hooks/useCopyable"

import styles from "./FixesCard.module.scss"

export const FixesCard = ({
  record,
  type
}: {
  record: any
  type?: "resources"
}) => {
  const { t } = useTranslation()
  const onCopy = useCopyable(
    "copyable-issue-expandable-cell",
    record?.issueType
  )

  if (!record) return null

  const { id, issueType, fixability } = record

  return (
    <Card
      className={cn(
        styles.container,
        type === "resources" ? styles.resources : styles.fixes
      )}
      title={
        type === "resources" ? undefined : (
          <div className={styles.titleRow}>
            <div className={styles.containerTitleRow}>
              <Heading level={5} title={t(`general.fixes`)} />
              {FixabilityCell(t)(fixability)}
            </div>
            <CopyButton size="small" type="secondary" onCopy={onCopy}>
              {t("general.copy")}
            </CopyButton>
          </div>
        )
      }
    >
      <span data-type-fixes-card={type}>
        <IssueExpandableCell
          drawer
          noLimits={type === "resources"}
          id={id}
          issueType={issueType}
          fixability={fixability}
          riskInsights={record.riskInsights}
        />
      </span>

      <span
        id="copyable-issue-expandable-cell"
        className={styles.copyableContainer}
      >
        <IssueExpandableCell
          drawer
          copyable
          noLimits={type === "resources"}
          id={id}
          issueType={issueType}
          fixability={fixability}
          riskInsights={record.riskInsights}
        />
      </span>
    </Card>
  )
}
// also in issue modal, for admin link for user text
