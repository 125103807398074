import { Popover, Text } from "@/atoms"
import {
  useLinkDrawerTooltip,
  type RowClick
} from "@/hooks/useLinkDrawerTooltip"
import { IconTextCell } from "@/molecules/IconTextCell"
import { IconResource } from "@/molecules/Resources"
import { CODE_REPOSITORY_DRAWER_ID } from "@/organisms/Drawers/const"
import { Resource } from "./Resource"

import styles from "./ResourceCell.module.scss"

import { useMemo } from "react"
import type { ResourceCellProps } from "."
import { ImageIcon } from "../../../../../icons/Images"

type KeyOf<T> = keyof T

function uniqueByField<T>(array: T[], field: KeyOf<T>): T[] {
  const seen = new Map<T[KeyOf<T>], boolean>()
  return array.reduce((acc, item) => {
    if (!seen.has(item[field])) {
      seen.set(item[field], true)
      acc.push(item)
    }
    return acc
  }, [] as T[])
}

export const ResourceWithPopover = (
  props: ResourceCellProps & { valueCell: string; onRowClick: RowClick }
) => {
  const {
    id: projectId,
    valueCell,
    resources,
    sourceControl,
    relatedImagesCount
  } = props
  const isRepoResource = !!sourceControl
  // we might have multiple repo resource, with different paths.
  // we want to deduplicate and set the name to the resource, and display the repo name (stored in resourceName)
  // another possible case is that we have main project and edited paths that aren't split. If only one path is contains
  // issues, we still want to show the main project name.
  const isMultiplePaths =
    resources.filter((r) => r.type === "CodeRepository").length > 1
  const isMainProject =
    isRepoResource && resources.length > 0 && projectId === resources[0].id
  const shouldShowResourceName = isMultiplePaths || isMainProject
  const displayName = shouldShowResourceName
    ? resources[0].resourceName
    : valueCell
  const showRelatedImages = isRepoResource && !!relatedImagesCount
  const { open, openDrawer } = useLinkDrawerTooltip(props)
  const { type: typeFirstRResource, imageSource } = resources[0] || {}
  const type = isRepoResource ? "CodeRepository" : typeFirstRResource
  const uniqueResources = useMemo(
    () => uniqueByField(resources, "id"),
    [resources]
  )
  return (
    <div className={styles.container}>
      <Popover
        open={open}
        className={styles.labelWrapper}
        rootClassName={styles.root}
        content={
          <div className={styles.tooltipWrapper}>
            {uniqueResources.map((resource, index) => {
              const isCodeRepo = resource.type === "CodeRepository"
              if (!isCodeRepo) {
                return (
                  <Text key={index}>
                    <Resource key={index} {...resource} />
                  </Text>
                )
              }
              return (
                <Text
                  underline
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    openDrawer?.(CODE_REPOSITORY_DRAWER_ID, resource.id)
                  }}
                >
                  <Resource key={index} {...resource} name={displayName} />
                </Text>
              )
            })}
          </div>
        }
      >
        <IconTextCell
          noTooltip
          icon={
            <IconResource
              type={type}
              sourceControl={sourceControl}
              imageSource={imageSource}
            />
          }
          text={displayName}
        />
        {showRelatedImages && resources[1].imageSource && (
          <IconTextCell
            noTooltip
            icon={<ImageIcon imageSource={resources[1].imageSource} />}
            text={`+${relatedImagesCount} related images`}
            classes={{ container: styles.relatedResourceContainer }}
          />
        )}
      </Popover>
    </div>
  )
}
