import { useEffect, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Button } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { SaveOutlined } from "@/icons"
import { useWorkflow } from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import { getInitialValues } from "@/pages/GovernancePage/helpers/workflows"
import { AsideBreadcrumbPage } from "@/pages/SettingsPage/components/molecules/AsideBreadcrumbPage"
import { HeaderBreadcrumbPage } from "@/pages/SettingsPage/components/molecules/HeaderBreadcrumbPage"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { AutomatedActionForm } from "../../organisms/AutomatedActionForm"

import type { AutomatedActionPageComponent } from "."

export const AutomatedActionPage: AutomatedActionPageComponent = (props) => {
  const { t } = useTranslation()
  const tPrefix = "settings.automatedActions.form."
  const breadcrumbKeyPrefix = `${tPrefix}sections.`
  const breadcrumbItems = [
    { key: "details", title: t(`${breadcrumbKeyPrefix}details`) },
    { key: "resources", title: t(`${breadcrumbKeyPrefix}resources`) },
    { key: "triggers", title: t(`${breadcrumbKeyPrefix}triggers`) },
    { key: "conditions", title: t(`${breadcrumbKeyPrefix}conditions`) },
    { key: "actions", title: t(`${breadcrumbKeyPrefix}actions`) }
  ]
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE
  const formProps = useCustomFunctionsForm()
  const { isDirty } = formProps
  const { id = "new" } = useParams()
  const isNew = id === "new"
  const [{ response, isLoading }, attr] = useWorkflow(
    { needle: id },
    { enabled: !!id && !isNew }
  )
  const initialValuesInData = response?.data[0]

  const { state } = useLocation()
  const { duplicateData: initialValuesInState } = state || {}

  const initialValues = initialValuesInState || initialValuesInData

  const navigate = useNavigate()
  const workflowsUrl = `/${ROOT}/${WORKFLOWS}`

  const isDuplicate = useRef(!!initialValuesInState).current
  const { logger, EVENTS } = useLogger()

  const onFinish = async (
    values: ReturnType<typeof getInitialValues> & { "details.name": string }
  ) => {
    const prefix = "forms.messages"
    const isNew = id === "new"

    try {
      const name = values["details.name"].trim()
      const payload = {
        ...initialValues,
        name,
        description: values["details.description"]?.trim(),
        trigger: {
          category: values["trigger.category"],
          type: values["trigger.type"]
        },
        resourceConditions: values.conditions,
        filterConditions: values.currentConditions
          .filter((item: any) => !!item?.length)
          .map((item: any) => {
            return item.reduce((acc: any, { key, selectedValue }: any) => {
              acc[key] = selectedValue

              return acc
            }, {})
          }),
        actions: [
          {
            type: values["actions.action"],
            values: { webhook_id: values["actions.endpoint"] }
          }
        ]
      }

      if (!payload.resourceConditions) payload.resourceConditions = []

      const { actions } = attr

      if (isNew) await actions.creator.mutateAsync({ workflow: payload })
      else
        await actions.updater.mutateAsync({
          needle: `/${id}`,
          data: { workflow: payload }
        })

      const action = isNew ? "added" : "updated"

      props.messageApi.success(t(`${prefix}.${action}`, { name }))

      formProps.setIsDirty(false)

      const { CREATE, EDIT } = EVENTS.ANALYTIC_EVENTS.WORKFLOW
      const event = isNew ? CREATE : EDIT
      const commonTags = { action: payload.actions[0].type, ...payload.trigger }
      const tags = isNew ? { isDuplicate, ...commonTags } : commonTags

      logger.info(event, tags)
    } catch (err: any) {
      const action = t(`general.${isNew ? "create" : "update"}`)
      const message = err?.message || t(`${prefix}.failed`, { action })

      props.messageApi.error(message)
    }
  }

  useEffect(() => {
    if (initialValuesInState) {
      formProps.setIsDirty(true)
      navigate("", { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWithBreadcrumbTemplate
      loading={isLoading}
      header={
        <HeaderBreadcrumbPage
          isLoading={false}
          items={[
            {
              title: t("navigation.governance"),
              to: `/${ROOT}`
            },
            {
              title: "Workflows",
              to: workflowsUrl
            },
            {
              title: isNew
                ? t(`settings.automatedActions.table.actions.create`)
                : `${initialValues?.name || "..."}`
            }
          ]}
        />
      }
      aside={<AsideBreadcrumbPage items={breadcrumbItems} />}
      footer={
        <div className="container-buttons-form-footer-page-with-breadcrumb-template-settings">
          <Button onClick={() => navigate(workflowsUrl)}>
            {t("general.cancel")}
          </Button>
          <Button
            form="automation-action-form"
            htmlType="submit"
            type="primary"
            icon={<SaveOutlined />}
            loading={attr.saving}
            disabled={!isDirty}
          >
            {t(isNew ? `${tPrefix}buttons.submit` : "general.saveUpdate")}
          </Button>
        </div>
      }
    >
      <AutomatedActionForm
        initialValues={getInitialValues(initialValues)}
        onFinish={onFinish}
        {...formProps}
        {...attr}
      />
    </PageWithBreadcrumbTemplate>
  )
}
