import { getExploitabilityByKeys } from "@/const/insights-constants"
import { isVulnerablePackageType } from "@/domain/issue"
import { SeverityTag } from "@/molecules/SeverityTag"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { InsightsTriageCell } from "@/pages/TriagePage/components/molecules/InsightsTriageCell"

import type { ScoreExplanationProps } from "@/api/useTriage.types"
import type { TFunction } from "@/hooks/useTranslation"

export const getItems = (
  record: ScoreExplanationProps,
  { t, internetFacingFF }: { t: TFunction; internetFacingFF?: boolean }
) => {
  const insights = {
    tag: true,
    runtime: false,
    internetFacing: false,
    ingress: false,
    inTheWildExploitMaturity: false,
    pocExploitMaturity: false
  }

  const { riskInsights, epss, cvss, severity, issueType } = record
  const exposure =
    riskInsights.internetFacing === "YES" || !!riskInsights.ingress
  const emptyValue = "-"

  const items = [
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.severity")}
        </OverviewDescriptions.Label>
      ),
      children: (
        <span>
          <SeverityTag t={t} severity={severity} />
          <span>{!!cvss && ` (CVSS ${cvss})`}</span>
        </span>
      )
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.usage")}
        </OverviewDescriptions.Label>
      ),
      className: "tag-score-explanation",
      children: riskInsights.runtime
        ? InsightsTriageCell(
            t,
            !!internetFacingFF
          )({
            ...insights,
            runtime: riskInsights.runtime
          })
        : emptyValue
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.exposure")}
        </OverviewDescriptions.Label>
      ),
      children: exposure
        ? InsightsTriageCell(
            t,
            !!internetFacingFF
          )({
            ...insights,
            internetFacing: riskInsights.internetFacing === "YES",
            ingress: riskInsights.ingress
          })
        : emptyValue,
      className: "tag-score-explanation"
    }
  ]

  if (isVulnerablePackageType(issueType)) {
    const epssValue = !!epss ? `${(Number(epss) * 100).toFixed(2)}%` : epss

    items.splice(
      2,
      0,
      {
        label: <OverviewDescriptions.Label>EPSS</OverviewDescriptions.Label>,
        children: (epssValue ?? emptyValue) as any
      },
      {
        label: (
          <OverviewDescriptions.Label>
            Exploit Maturity
          </OverviewDescriptions.Label>
        ),
        children:
          riskInsights.exploitMaturity !== "UNDETERMINED"
            ? InsightsTriageCell(
                t,
                !!internetFacingFF
              )({
                ...insights,
                ...getExploitabilityByKeys(riskInsights.exploitMaturity)
              })
            : emptyValue,
        className: "tag-score-explanation"
      }
    )
  }

  return items
}
