import type { User } from "@/domain/user"
import type { Services } from "@/interfaces/services"

export type InitialParamsIntercomAdapter = {
  appId: string
  isDev: boolean
  logger: Services.ILoggerAdapter
}

export class IntercomAdapter {
  private appId: string
  private isDev: boolean
  private logger: Services.ILoggerAdapter

  constructor({ appId, logger, isDev }: InitialParamsIntercomAdapter) {
    this.appId = appId
    this.isDev = isDev
    this.logger = logger
    this.shutdown()
  }

  logError(err: unknown, target: string) {
    try {
      const message = err instanceof Error ? err : `Error on ${target}`

      this.logger.error(message, { target })
    } catch (error) {
      this.logger?.error?.(error as Error, {
        target: "IntercomAdapter.logError"
      })
    }
  }

  api(method: string, ...args: unknown[]) {
    try {
      if (this.isDev) return

      if ((window as any).Intercom) {
        return (window as any).Intercom.apply(null, [method, ...args])
      }

      this.logError(new Error("Intercom not found"), "IntercomAdapter.api")
    } catch (err) {
      this.logError(err, "IntercomAdapter.api")
    }
  }

  boot() {
    try {
      this.api("boot", {
        app_id: this.appId,
        z_index: 1050
      })
    } catch (err) {
      this.logError(err, "IntercomAdapter.boot")
    }
  }

  update(user: User) {
    try {
      this.api("update", {
        app_id: this.appId,
        email: user.email,
        created_at: Date.now(),
        name: user.initials,
        user_id: user.email,
        company: user.company
      })
    } catch (err) {
      this.logError(err, "IntercomAdapter.update")
    }
  }

  shutdown() {
    try {
      this.api("shutdown")
      this.boot()
    } catch (err) {
      this.logError(err, "IntercomAdapter.shutdown")
    }
  }
}
