import { BaseImage, SourceControlIcon, VM } from "@/icons"

import type { IconResourceComponent } from "."
import { ImageIcon } from "../../icons/Images"

export const IconResource: IconResourceComponent = (props) => {
  const { type, sourceControl, imageSource } = props
  if (!type) return null
  if (type === "ImageRepository" && imageSource)
    return <ImageIcon imageSource={imageSource} />
  if (type === "Image") return <BaseImage />
  if (type === "Vm") return <VM />
  if (!sourceControl) return null
  return <SourceControlIcon sourceControl={sourceControl} />
}
