import { Drawer as DrawerAntd } from "antd"
import cn from "classnames"

import { DRAWER_ID_SUFFIX } from "."

import styles from "./Drawer.module.scss"

import type { DrawerComponentType } from "."

export const DrawerComponent: DrawerComponentType = (props) => {
  const {
    className,
    children,
    defaultView,
    resizableView,
    id,
    ...drawerProps
  } = props

  return (
    <DrawerAntd
      id={`${id}${DRAWER_ID_SUFFIX}`}
      rootClassName={cn(resizableView && styles.resizable)}
      className={cn(className, !defaultView && styles.container)}
      {...drawerProps}
    >
      {children}
    </DrawerAntd>
  )
}
