import { Flex, Link, Text } from "@/atoms"
import { Image } from "@/icons"
import { IMAGE_DRAWER_ID } from "@/organisms/Drawers/const"

import type { MetaImageComponent } from "."

const MetaImage: MetaImageComponent = ({
  record: { id, name },
  onRowClick,
  classes = {},
  ...props
}) => (
  <Flex gap={8} align="center">
    <Image className={classes?.icon} />
    <Link
      type="link"
      className={classes?.link}
      onClick={() => onRowClick({ key: id }, IMAGE_DRAWER_ID)}
    >
      <Text className={classes?.text} copyable {...props}>
        {name}
      </Text>
    </Link>
  </Flex>
)

export default MetaImage
