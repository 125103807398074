import { LineDeployCommandProps } from "."

export const DEFAULT_END_LINE = " \\\n"

export const getTextForValue = (value: string, defaultValue: string) =>
  value === defaultValue ? `[${value}]` : value

export const getAlign = (align: LineDeployCommandProps["align"]) => {
  if (align === "space-between") return " "

  return ""
}

export const getLineDeployCommand = (props: LineDeployCommandProps) => {
  const { value, defaultValue, align, suffix = "" } = props
  const currentValue =
    value !== undefined && defaultValue !== undefined
      ? `${getTextForValue(value, defaultValue)}`
      : ""

  return `${props.text}${getAlign(align)}${currentValue}${suffix}`
}

export const getDeployCommandString = (
  props: {
    lines: Record<
      string,
      {
        text: string
        name?: string
        align?: LineDeployCommandProps["align"]
        suffix?: string
      }
    >
    values: Record<string, string>
    defaultValues: Record<string, string>
  },
  keys?: number | string[]
) => {
  try {
    const data = Array.isArray(keys)
      ? keys
      : new Array(Object.keys(props.lines).length).fill(undefined)

    return data
      .map((value, index) => {
        try {
          const key = !value ? (index + 1).toString() : value
          const { text, name, align, suffix } = props.lines[key]

          return getLineDeployCommand({
            text,
            align,
            value: name && props.values[name],
            defaultValue: name && props.defaultValues[name],
            firstLine: index === 0,
            suffix
          })
        } catch (error) {
          console.error(error)

          return ""
        }
      })
      .filter((v) => !!v)
      .join(DEFAULT_END_LINE)
  } catch (error) {
    console.error(error)

    return ""
  }
}
