import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate, useDelete, useHttp, useUpdate } from "@/hooks/useHttp"

import type {
  Config,
  GetUseMutationOptions,
  Params,
  Updater
} from "@/hooks/useHttp.types"
import type {
  ActionsOptionsWorkflowResponse,
  ActionsOverTimeResponse,
  ActionsSummaryResponse,
  ActionWorkflowResponse,
  TriggerOptionsWorkflowResponse,
  WorkflowResponse
} from "./useWorkflow.types"

export const useGetWorkflow = <T = WorkflowResponse, S = T>(
  params: Params<{ needle?: string }> = {},
  config?: Config<T, S>
) => {
  const { needle, ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST}${needle ? `/${needle}` : ""}`,
    restParams,
    config
  )

  return context
}

export const useCreateWorkflow = <T, S = T>(
  params?: Params,
  updater?: Updater<T, S>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useCreate(
    RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST,
    params,
    updater,
    options
  )

  return context
}

export const useUpdateWorkflow = <T, S = T>(
  params?: Params,
  updater?: Updater<T, S>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate(
    RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST,
    params,
    updater,
    options
  )

  return context
}

export const useDeleteWorkflow = () => {
  const context = useDelete(RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST)

  return context
}

export const useTriggerOptionsWorkflow = <
  T = TriggerOptionsWorkflowResponse,
  S = T
>(
  params?: Params,
  config?: Config<T, S>
) =>
  useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.TRIGGER}/options`,
    params,
    config
  )

export const useActionsWorkflow = <T = ActionWorkflowResponse, S = T>(
  params?: Params,
  config?: Config<T, S>
) => useHttp<T, S>(RESOURCES_NAMES.SETTINGS.WORKFLOWS.ACTIONS, params, config)

export const useActionsOptionsWorkflow = <
  T = ActionsOptionsWorkflowResponse,
  S = T
>(
  params?: Params,
  config?: Config<T, S>
) =>
  useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.ACTIONS}/options`,
    params,
    config
  )

export const useActionsOverTimeStatisticsWorkflow = () =>
  useHttp<ActionsOverTimeResponse>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.STATISTICS}/actions-over-time`
  )

export const useActionsSummaryStatisticsWorkflow = () =>
  useHttp<ActionsSummaryResponse>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.STATISTICS}/actions-summary`
  )
