import { isCodeIssueType } from "@/domain/issue"

import type {
  AttackChainsResponse,
  FindingsTriageResponse
} from "@/api/useTriage.types"
import type {
  ApplicativeImageFinding,
  BaseImageFinding,
  CodeRepoFinding,
  Finding,
  VmFinding
} from "@/domain/issue"
export const getFindingsInfoForExpandRow = (
  response: FindingsTriageResponse
) => {
  const { data } = response || {}
  const finding: Finding = data?.[0]
  const emptyData = {
    codeRepoFindings: [],
    baseImageFindings: [],
    imageFindings: [],
    fixVersions: [],
    vmFindings: []
  }

  if (!finding) {
    return {
      metadata: {
        epss: 0,
        cvss: 0,
        packageName: "",
        packageVersions: [""],
        cwe: { id: "", description: "" },
        language: "unknown" as const,
        issueType: "Vulnerable_Package" as const
      },
      ...emptyData
    }
  }

  const vmFindings = data.filter(
    (item) => (item.resource.type as any) === "Vm"
  ) as VmFinding[]

  if (isCodeIssueType(finding.issueType)) {
    return { metadata: finding, ...emptyData, vmFindings }
  }

  const {
    cvss,
    epss,
    packageVersions,
    packageName,
    fixVersions,
    cwe,
    language,
    issueType
  } = finding
  const codeRepoFindings = data.filter(
    (item) => item.resource.type === "CodeRepository"
  ) as CodeRepoFinding[]
  const baseImageFindings = data.filter(
    (item) =>
      item.resource.type === "Image" &&
      item.introducedThroughInsights.fromBaseImage
  ) as BaseImageFinding[]
  const imageFindings = data.filter(
    (item) =>
      item.resource.type === "Image" &&
      !item.introducedThroughInsights.fromBaseImage
  ) as ApplicativeImageFinding[]

  return {
    metadata: {
      cvss,
      epss,
      packageVersions,
      packageName,
      cwe,
      language,
      issueType
    },
    fixVersions,
    codeRepoFindings,
    baseImageFindings,
    imageFindings,
    vmFindings
  }
}

export const getAttackChains = (response: AttackChainsResponse) => {
  const { data, ...rest } = response

  return {
    data: data.map((attack) => ({
      application: { name: "application_name", id: "123" },
      environment: "production",
      namespace: "cloud",
      ...attack
    })),
    ...rest
  }
}
