import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { VIRTUAL_MACHINE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { Filter } from "@/organisms/FilterNew/Filter"
import { SmartSearch } from "@/organisms/SmartSearch"
import { useVirtualMachineInventory } from "@/pages/DiscoveryPage/application/virtual-machine-inventory/useVirtualMachine"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"
import { getFilters } from "./getItemsFilter"

export const VirtualMachinesTab = ({ useActiveKeyTabsInventory }: any) => {
  const convertors = useNumberConvertor()
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const columns = getColumnsTable({
    sorter: orderByProps,
    convertors,
    previewMode,
    t
  })
  const filterItems = getFilters(t)
  const { response, isLoading } = useVirtualMachineInventory(filterItems, {
    enabled: activeKey === TAB_KEYS[5]
  })
  const { data, metadata } = response || {}
  const { idsInDrawers, onRowClick } = useDrawer(VIRTUAL_MACHINE_DRAWER_ID)

  useTooltip(data)

  return (
    <TabTemplate
      resizable
      dataSource={data}
      metadata={metadata}
      columns={columns}
      loading={isLoading}
      locale={{
        teasers: {
          dataReadiness: true,
          kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
        }
      }}
      disableDrawer={previewMode}
      idsInDrawers={idsInDrawers}
      onRowClick={onRowClick}
      SearchComponent={
        !previewMode && (
          <Filter.Provider keys={Object.keys(filterItems)}>
            <Filter.Template
              search={
                <SmartSearch
                  id="smart-search-application-tab-inventory"
                  filters={filterItems}
                />
              }
            >
              <Filter.Checkbox
                order="none"
                keyFilter="severity"
                items={SEVERITIES_FILTER_OPTIONS}
                placeHolderDefaultValue="Severity"
              />
            </Filter.Template>
          </Filter.Provider>
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
    />
  )
}
