import { useState } from "react"

import { Col, Form, Row, Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { RocketOutlined } from "@/icons"
import { DescriptiveSelect } from "@/molecules/DescriptiveSelect"
import { useTriggerOptionsWorkflow } from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import { getResetFieldsValuesByTriggerKey } from "@/pages/GovernancePage/helpers/workflows"
import { SectionFormTemplate } from "@/pages/SettingsPage/components/templates/SectionFormTemplate"

import type {
  FieldsValuesState,
  TriggerSectionFormProps
} from "./TriggerSectionForm.types"

export const TriggerSectionForm = ({ form }: TriggerSectionFormProps) => {
  const { response, isLoading } = useTriggerOptionsWorkflow()
  const { categories = [], types = {} } = response || {}

  const [state, setState] = useState<FieldsValuesState>({})
  const triggerCategoryFieldName = "trigger.category"
  const categoryTrigger = Form.useWatch(triggerCategoryFieldName, form)

  const { t } = useTranslation()
  const rules = [{ required: true, message: t("error.requiredField") }]
  const title = t("settings.automatedActions.form.sections.triggers")
  const values = types[categoryTrigger] || []

  return (
    <SectionFormTemplate id="trigger" title={title} Icon={RocketOutlined}>
      <Row gutter={18}>
        <Col span={10}>
          <Form.Item name={triggerCategoryFieldName} rules={rules}>
            <Select
              disabled={isLoading}
              options={categories}
              placeholder={t("forms.fields.category.placeholder")}
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item name="trigger.type" rules={rules}>
            <DescriptiveSelect
              disabled={isLoading || !categoryTrigger}
              values={values}
              placeholder={t("forms.fields.type.placeholder")}
              translationKey="settings.automatedActions.form.fields.triggers"
              onChange={(nextTypeTriggerValue) => {
                const key = `${categoryTrigger}_${nextTypeTriggerValue}`
                const nextState = getResetFieldsValuesByTriggerKey(key, state)

                setState({ ...state, [key]: form.getFieldsValue() })
                form.setFieldsValue(nextState)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </SectionFormTemplate>
  )
}
