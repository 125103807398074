import cn from "classnames"
import { lazy, Suspense, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { Divider, Link, LogoImage, Menu, type ItemType } from "@/atoms"
import { usePrevious } from "@/hooks/usePrevious"
import useServices from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { ApiOutlined, CloudUploadOutlined } from "@/icons"
import {
  filterItemsByItemKey,
  filterMenuItems,
  getItem,
  getMenuItems
} from "./components/ItemMenu"
import { ScopeSideNavigation } from "./components/ScopeSideNavigation"
import { useKeyByParams } from "./hooks/useKeyByParams"

import styles from "./SideNavigation.module.scss"

import { Protected } from "@/molecules/Protected"
import type { SideNavigationComponent } from "."

const AdminCheckListNavigation = lazy(
  () => import("./components/AdminCheckListNavigation")
)

export const SideNavigation: SideNavigationComponent = ({
  collapsed,
  user
}) => {
  const { t } = useTranslation()
  const props = { collapsed, t }
  const { auth } = useServices()
  const { pathname } = useLocation()
  const vmInstancesEnabledFF = useFeatureFlags("vmInstancesEnabled")
  const attr = {
    t,
    pathname,
    vmInstancesEnabledFF,
    collapsed,
    isDemo: auth.isDemo()
  }
  const items: ItemType[] = filterMenuItems(getMenuItems(attr), attr)

  const itemKey = useKeyByParams()
  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    const result = filterItemsByItemKey(items, itemKey)

    return !result?.length ? ["discovery"] : result
  })
  const prevCollapsed = usePrevious(collapsed)

  useEffect(() => {
    if (prevCollapsed && !collapsed)
      setTimeout(() => setOpenKeys(filterItemsByItemKey(items, itemKey)), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevCollapsed, collapsed])

  const selectedKeys = [itemKey]
  const menuProps = {
    className: styles.menuNavigationTemplate,
    rootClassName: cn(collapsed && styles.collapsedRootClassName),
    mode: "inline" as const,
    theme: "dark" as const,
    collapsed: `${collapsed}`,
    openKeys,
    selectedKeys
  }

  return (
    <div
      className={cn(
        styles.container,
        collapsed ? styles.collapsedContainer : styles.noCollapsedContainer
      )}
    >
      <div className={cn(styles.logo, collapsed && styles.collapsed)}>
        <Link to="/">
          <LogoImage dark height={32} />
        </Link>
        {!collapsed && <div className={styles.slugLabel}>{user.company}</div>}
      </div>
      <div className={styles.section}>
        <ScopeSideNavigation {...props} />
      </div>
      <Divider type="horizontal" className={styles.divider} />
      <div className={styles.scrollableSection}>
        <div>
          <Menu
            items={items}
            onOpenChange={(openKeys) => {
              setOpenKeys?.(openKeys)
            }}
            {...menuProps}
          />
        </div>
      </div>
      <Divider type="horizontal" className={styles.divider} />
      <div className={styles.section}>
        <Menu
          items={filterMenuItems([
            getItem("Deployments", "/deployments", <CloudUploadOutlined />),
            getItem("Integrations", "/integrations", <ApiOutlined />)
          ])}
          {...menuProps}
        />
      </div>
      <Suspense>
        <Protected permission={{ resource: "system", action: "write" }}>
          <AdminCheckListNavigation {...props} />
        </Protected>
      </Suspense>
    </div>
  )
}
