import { Text } from "@/atoms"
import { InlineInput } from "@/pages/DeploymentsPage/components/molecules/InlineInput"
import { NullCharacterCommandLine } from "@/pages/DeploymentsPage/components/molecules/NullCharacterCommandLine"

import type { InlineFieldProps } from "./InlineField.types"

export const InlineField = (props: InlineFieldProps) => {
  const { line, values, noNullCharacter, ...rest } = props

  return (
    <Text>
      {line.text}
      {line.name && values && (
        <InlineInput
          key={`${values.key}_${line.name}`}
          value={values[line.name]}
          name={line.name}
          {...rest}
        />
      )}
      {!noNullCharacter && <NullCharacterCommandLine />}
      {line.suffix}
    </Text>
  )
}
