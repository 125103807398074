import cn from "classnames"

import { Tag } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./FeatureStatusTag.module.scss"

import type { FeatureStatusTagComponent } from "."

export const FeatureStatusTag: FeatureStatusTagComponent = (props) => {
  const { t } = useTranslation()
  const { type } = props
  const className = cn(
    styles.container,
    {
      [styles.new]: type === "new",
      [styles.beta]: type === "beta",
      [styles.soon]: type === "soon"
    },
    props.className
  )

  return <Tag className={className}>{t(`featureStatus.${type}`)}</Tag>
}
