import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { TFunction } from "@/hooks/useTranslation"
import { CreatorCell } from "@/molecules/CreatorCell"
import { HeaderCell } from "@/molecules/HeaderCell"
import { CopyableKeyIdCell } from "../../molecules/CopyableKeyIdCell/CopyableKeyIdCell"
import { KeyStatusTag } from "../../molecules/KeyStatusTag"
import { KeyActionsCell } from "./KeyActionsCell"

import type { AuthKey } from "@/api/useAuthKeys.types"
import type { ColumnType } from "@/atoms"

export const getColumnsTable = ({
  tAuthKeys,
  deleteColumnKey: deleteKey
}: {
  tAuthKeys: TFunction
  deleteColumnKey: any
}): ColumnType<AuthKey>[] => {
  const columns = [
    {
      title: "Key Name",
      render: CellTable,
      width: 200,
      ...getDefaultColumnParams("name")
    },
    {
      title: "Description",
      render: CellTable,
      width: 250,
      ...getDefaultColumnParams("description")
    },
    {
      title: "Status",
      width: 110,
      render: KeyStatusTag(tAuthKeys),
      ...getDefaultColumnParams("status")
    },
    {
      title: "Creator",
      render: CreatorCell,
      width: 70,
      ...getDefaultColumnParams("creator")
    },
    {
      title: HeaderCell({
        title: "ID",
        Content: () => <span>{tAuthKeys("idTooltip")}</span>
      }),
      width: 100,
      render: CopyableKeyIdCell,
      ...getDefaultColumnParams("id")
    },
    {
      title: CellTable("Last Used"),
      render: CellTable,
      ...getDefaultColumnParams("lastUsedAt")
    },
    {
      title: CellTable("Creation Date"),
      render: CellTable,
      ...getDefaultColumnParams("createdAt")
    },
    {
      title: CellTable("Expiration Date"),
      render: CellTable,
      ...getDefaultColumnParams("expiry")
    },
    {
      width: 35,
      render: KeyActionsCell({
        tAuthKeys,
        deleteKey
      })
    }
  ]

  return columns.filter(Boolean) as ColumnType<AuthKey>[]
}
