import cn from "classnames"
import { Fragment } from "react"

import { Resource } from "./Resource"
import { ResourcesPopover } from "./ResourcesPopover"

import styles from "./Resources.module.scss"

import type { ResourcesComponent } from "."
import { ImageIcon } from "../../icons/Images"

export const Resources: ResourcesComponent = (props) => {
  const { resources, t, inline, popover, classes = {} } = props
  const countRelatedResources = (resources?.length || 0) - 1
  const classesResource = {
    container: cn(
      styles.containerResources,
      inline && styles.containerResourcesInline,
      classes.container
    ),
    name: cn(
      !inline && styles.nameResources,
      inline && styles.nameResourcesInline,
      classes.name
    )
  }
  const Wrapper = popover ? ResourcesPopover : Fragment
  const propsWrapper = popover ? props : ({} as any)

  return (
    <Wrapper {...propsWrapper}>
      <div
        className={cn(
          styles.container,
          styles.column,
          inline && styles.section
        )}
      >
        <Resource classes={classesResource} {...resources[0]} />
        {countRelatedResources > 0 && (
          <span className={cn(styles.section)}>
            {!inline && resources[1].imageSource && (
              <ImageIcon imageSource={resources[1].imageSource} />
            )}
            <span
              className={cn(
                styles.nameRelatedResources,
                inline && styles.inline
              )}
            >{`+${countRelatedResources} ${t("general.image", { count: countRelatedResources })}`}</span>
          </span>
        )}
      </div>
    </Wrapper>
  )
}
