import cn from "classnames"
import { Fragment, useEffect } from "react"

import { Button, Card, Form, InputNumber, Select } from "@/atoms"
import { CloseOutlined } from "@/icons"
import { InformationPopover } from "@/molecules/InformationPopover"
import { TagInput } from "../TagInput"

import styles from "./InputConditionSection.module.scss"

import type { InputConditionSectionComponent } from "."

export const InputConditionSection: InputConditionSectionComponent = (
  props
) => {
  const {
    type,
    name,
    label,
    options,
    onRemove,
    rules,
    description,
    focusId,
    onReady,
    ...rest
  } = props
  const isConditionSelect = !type
  const Wrap = isConditionSelect ? Fragment : Card
  const isSelect = type === "SELECT"
  const wrapProps = isConditionSelect
    ? {}
    : {
        className: cn(
          !isSelect && "tag-ant-select-selection-item-ant-select-selector",
          styles.card
        )
      }

  const isMultiSelect = type === "SELECT_ARRAY"
  const isSelectComponent = isConditionSelect || isMultiSelect || isSelect
  const isIntegerInput = type === "INT"

  useEffect(() => {
    onReady?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrap {...wrapProps}>
      <Form.Item
        id={focusId}
        required={false}
        name={name}
        label={
          label && (
            <span className={styles.label}>
              {label}
              {description && (
                <InformationPopover.Popover
                  content={
                    <InformationPopover.CommonTemplate
                      classes={{ content: styles.contentPopover }}
                    >
                      {description}
                    </InformationPopover.CommonTemplate>
                  }
                />
              )}
            </span>
          )
        }
        rules={rules}
      >
        {isIntegerInput && (
          <InputNumber
            precision={0}
            min={0}
            max={
              props["data-input-condition-key"] === "kodemScore" ? 1000 : 100
            }
            step={1}
            placeholder={
              typeof rest.placeholder === "string" ? rest.placeholder : ""
            }
          />
        )}
        {type === "STRING_ARRAY_INPUT" && <TagInput {...rest} />}
        {isSelectComponent && (
          <Select
            mode={isMultiSelect ? "multiple" : undefined}
            options={options}
            placeholder={isConditionSelect ? "Add condition..." : "Select"}
            {...rest}
          />
        )}
      </Form.Item>
      {!isConditionSelect && (
        <div>
          <Button
            size="small"
            type="text"
            className={styles.removeButton}
            icon={<CloseOutlined />}
            onClick={onRemove}
          />
        </div>
      )}
    </Wrap>
  )
}
