import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { InputDescription } from "@/pages/DeploymentsPage/components/molecules/InputDescription"
// import { TitleStep } from "@/pages/DeploymentsPage/components/molecules/TitleStep"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"
import { EksDeployCommand } from "@/pages/DeploymentsPage/components/organisms/EksDeployCommand"
import { getCommonFiles } from "@/pages/DeploymentsPage/helpers"
import { getDefaultValues } from "../EksDeployCommand/helpers"

export const EksTab = (props: CommonDeployTabProps & { fargate?: boolean }) => {
  const { noError, version } = props
  const files = getCommonFiles(version)
  const { t } = useTranslation()
  const defaultValues = getDefaultValues()

  return (
    <DeployTab
      steps={[
        {
          title: (
            <DownloadStep
              noError={noError}
              files={files}
              title={t("deployments.kubernetes.deploymentStep")}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab
              prefix="Create a new EFS (Elastic File System) instance"
              subTitle={
                <span>
                  Follow{" "}
                  <a
                    href="https://aws.amazon.com/blogs/containers/running-stateful-workloads-with-amazon-eks-on-aws-fargate-using-amazon-efs/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    instructions
                  </a>{" "}
                  to create Elastic File System instance with a minimum 1GB
                  storage.
                </span>
              }
            />
          ),
          description: (
            <InputDescription
              addonBefore="EFS Volume Handle"
              placeholder="e.g. FileSystemId::AccessPointId"
              name="efsVolumeHandle"
              defaultValues={defaultValues}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab
              subTitle="Create an IAM role with the 'AmazonEC2ContainerRegistryReadOnly' permission and OIDC based trust policy."
              prefix="Create an IAM (Identity and Access Management) Role"
            />
          ),
          description: (
            <InputDescription
              addonBefore="IAM Role ARN"
              placeholder="e.g. arn:aws:iam::AccountId:role/RoleName"
              name="awsRoleArn"
              defaultValues={defaultValues}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab prefix={t("general.install")}>
              {`- Modify and run the command below in AWS CLI with environment and deployment installation attributes`}
            </TitleStepDeployTab>
          ),
          description: <EksDeployCommand {...props} />,
          status: "process"
        }
      ]}
      message={
        <span>
          {t("deployments.kubernetes.alert.text")}
          <br />
          <b>Note:</b>
          <ul>
            <li>
              <b>It may take a couple of hours to ingest issues.</b>
            </li>
            <li>
              <b>
                Kodem uses cert-manager for certificate management. To handle
                certificates manually, reach out to your Kodem Technical Account
                Manager.
              </b>
            </li>
          </ul>
        </span>
      }
    />
  )
}
