import { Button, Empty, Link } from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useTooltip } from "@/hooks/useTooltip"
import { useUser } from "@/hooks/useUser"
import { KeyIcon } from "@/icons"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"
import { Heading } from "@/molecules/Heading"
import { useActiveKeyTabsUsers } from "@/pages/SettingsPage/hooks/useActiveKeyTabsUsers"
import { TabTemplate } from "@/templates/TabTemplate"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import styles from "./AuthKeysTab.module.scss"
import { KeysTabProps } from "./AuthKeysTab.types"
import { CreateKeyModal } from "./CreateKeyModal"
import { getColumnsTable } from "./getColumnsTable"

export const AuthKeysTab = (props: KeysTabProps) => {
  const { t: tAuthKeys } = useTranslation("translation", {
    keyPrefix: "settings.authkeys"
  })
  const { activeKey } = useActiveKeyTabsUsers()
  const [{ response, isLoading }, { deleteKey }] = props.useAuthKeys(
    props.keyType,
    { enabled: activeKey === props.tabName }
  )
  const { data, metadata }: any = response || ({} as any)
  const [showModal, setShowModal] = useState<boolean | number>(false)
  const { user } = useUser()
  const { logger } = useLogger()

  const isKeyCreationEnabled =
    props.keyType === "API_KEYS" ||
    (isUserAdmin(user) && props.keyType !== "SERVICE_KEYS")

  const { COMMAND_GENERATE_KEY } = props.analyticsEvents
  const { confirm } = useConfirmModal()

  const showDeleteConfirmDialog = (record: { key: string; name: string }) => {
    confirm((attr) =>
      DeleteConfirmModal({
        name: record.name,
        entityName: props.keyType,
        onOk: async () => await deleteKey(record.key),
        ...attr
      })
    )
  }

  const columns = useRef(
    getColumnsTable({
      tAuthKeys,
      deleteColumnKey: showDeleteConfirmDialog
    })
  ).current

  useTooltip(data)

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={props.title}
          popover={{
            className: styles.popover,
            content: (
              <div className={styles.popoverContent}>
                <span>{props.description}</span>
              </div>
            )
          }}
        />
      }
      button={
        isKeyCreationEnabled && (
          <Button
            icon={<KeyIcon />}
            type="primary"
            className={styles.button}
            onClick={() => setShowModal(Date.now())}
          >
            {tAuthKeys("actions.create")}
          </Button>
        )
      }
    >
      <TabTemplate
        dataSource={data}
        metadata={metadata}
        columns={columns}
        loading={isLoading}
        tabPosition="left"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div className={styles.empty}>
                  <span>{tAuthKeys("noKeys")}</span>
                  {isKeyCreationEnabled && (
                    <Link>
                      <Button
                        type="link"
                        onClick={() => {
                          setShowModal(true)
                        }}
                      >
                        {tAuthKeys("createFirstNewKey")}
                      </Button>
                    </Link>
                  )}
                </div>
              }
            />
          )
        }}
      />
      <CreateKeyModal
        key={`${showModal}`}
        open={!!showModal}
        useCreateKey={props.useCreateKey}
        logCreateKey={(keyName) => {
          logger.info(COMMAND_GENERATE_KEY, { keyName })
        }}
        keyType={props.keyType}
        onClose={() => {
          setShowModal(false)
        }}
      />
    </VerticalTabTemplate>
  )
}
