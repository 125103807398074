import { useDeleteIntegration } from "@/api/useIntegrations"
import { Button, Collapse, Flex, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { DisconnectOutlined, FolderOpenOutlined } from "@/icons"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"

import styles from "./ConfigureTabGitLabIntegration.module.scss"

import type { ConfigureTabGitLabIntegrationComponent } from "."

export const ConfigureTabGitLabIntegration: ConfigureTabGitLabIntegrationComponent =
  ({ configuration, refetchIntegration }) => {
    const { installations = [] } = configuration || {}
    const noGroups = !installations.length
    const vendor = "gitlab"
    const remover = useDeleteIntegration({ vendor })
    const { confirm } = useConfirmModal()
    const { t } = useTranslation()
    const { t: tGitLab } = useTranslation("translation", {
      keyPrefix: "integrations.connect.gitlab"
    })

    return (
      <Flex vertical gap={16} className={styles.container}>
        <Text strong>GitLabs Groups</Text>
        {noGroups && <Text>{tGitLab("noGroup")}</Text>}
        {!noGroups && (
          <Collapse
            items={installations.map(({ groupName, projectsCount }: any) => ({
              label: (
                <Flex vertical>
                  <Text strong>{groupName}</Text>
                  <Text type="secondary">
                    <Flex gap={4} className={styles.description}>
                      <FolderOpenOutlined />
                      {`${projectsCount} ${tGitLab("project_connected", { count: projectsCount })}`}
                    </Flex>
                  </Text>
                </Flex>
              ),
              children: (
                <Button
                  danger
                  size="small"
                  icon={<DisconnectOutlined />}
                  onClick={() =>
                    confirm((attr) =>
                      DeleteConfirmModal({
                        entityName: vendor,
                        content: tGitLab("deleteGuard"),
                        okText: t("general.disconnect"),
                        onOk: async () => {
                          const result = (await remover.mutateAsync(
                            groupName
                          )) as { data: boolean; error: string }

                          if (!result.data) throw new Error(result.error)

                          return refetchIntegration()
                        },
                        ...attr
                      })
                    )
                  }
                >
                  {t("general.disconnect")}
                </Button>
              )
            }))}
          />
        )}
      </Flex>
    )
  }
