import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { AutomatedActionsTab } from "@/pages/GovernancePage/components/organisms/AutomatedActionsTab"
import { PageTemplate } from "@/templates/PageTemplate"

import type { AutomatedActionsPageComponent } from "."

export const AutomatedActionsPage: AutomatedActionsPageComponent = (props) => {
  const { t } = useTranslation()

  return (
    <PageTemplate
      full
      header={
        <HeaderPage
          titleTranslationKey={t("settings.automatedActions.title")}
        />
      }
    >
      <AutomatedActionsTab {...props} />
    </PageTemplate>
  )
}
