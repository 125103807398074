import { Popover } from "@/atoms"
import { SingleImageResourcePopover } from "@/molecules/Resources"

import { IconTextCell } from "@/molecules/IconTextCell"
import type { ImageNameCellComponent } from "."
import { ImageIcon } from "../../../../../icons/Images"

export const ImageNameCell: ImageNameCellComponent = (value, record) => {
  const {
    key: id,
    displayName: name,
    digest = "",
    registry,
    ...rest
  } = record || {}
  const prefix = registry ? `${registry}/` : ""
  const props = {
    id,
    name: prefix + name,
    digest,
    imageSource: record.imageSource,
    ...rest
  }
  // Image must contain a source to be displayed
  if (!props.imageSource) {
    return null
  }
  return (
    <Popover
      trigger="hover"
      placement="topLeft"
      content={<SingleImageResourcePopover {...props} />}
    >
      <div>
        <IconTextCell
          icon={<ImageIcon imageSource={props.imageSource} />}
          text={value}
          noTooltip
        />
      </div>
    </Popover>
  )
}
