import cn from "classnames"

import { Link } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { IconWithFrame } from "@/icons"

import styles from "./IconLabelWidget.module.scss"

import type { IconLabelWidgetComponent } from "."

export const IconLabelWidget: IconLabelWidgetComponent = (props) => {
  const { text, to, Icon, classes = {} } = props
  const { color, container } = classes
  const { logger } = useLogger()

  return (
    <Link className={cn(styles.link, container)} type="link" to={to}>
      <span
        className={cn(styles.wrapper, color)}
        onClick={() => {
          if (props.MixpanelEvent) {
            logger.info(props.MixpanelEvent)
          }
        }}
      >
        {!!Icon &&
          IconWithFrame(Icon, {
            background: false,
            size: "xl",
            className: styles.icon
          })}
        <span className={styles.text}>{text}</span>
      </span>
    </Link>
  )
}
