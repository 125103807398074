import { getColorNumberForUserAvatar, getInitials } from "@/domain/user"
import { UserItem } from "@/molecules/UserItem"

import type { CreatedBy } from "./CreatorCell.types"

export const CreatorCell = (createdBy: CreatedBy) => {
  const { name, email } = createdBy || {}
  const displayName = name || email || ""

  return (
    <UserItem
      avatarSize="small"
      tooltip={displayName}
      email={email}
      initials={getInitials(displayName)}
      colorNumber={getColorNumberForUserAvatar(displayName)}
      displayOnlyAvatar
    />
  )
}
