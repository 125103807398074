import { Webhook } from "@/icons"
import { InformationPopover } from "@/molecules/InformationPopover"

import styles from "./AutomatedActionsCell.module.scss"

import type { TFunction } from "@/hooks/useTranslation"

export const AutomatedActionsCell =
  ({ t }: { t: TFunction }) =>
  (value: { type: string }[]) => {
    if (!value?.length) return null

    return (
      <div className={styles.cell}>
        <InformationPopover.Popover
          placement="top"
          content={
            <InformationPopover.CommonTemplate title="Included Actions">
              {value.map(({ type }, key) => {
                if (type === "webhook") {
                  return (
                    <span key={`${type}${key}`} className={styles.itemPopover}>
                      <Webhook />
                      <span>
                        {t(
                          `settings.automatedActions.table.actionsByType.${type}`
                        )}
                      </span>
                    </span>
                  )
                }

                return null
              })}
            </InformationPopover.CommonTemplate>
          }
        >
          {value.map(({ type }, key) => {
            if (type === "webhook") {
              return <Webhook key={`${type}${key}`} />
            }

            return null
          })}
        </InformationPopover.Popover>
      </div>
    )
  }
