import React, { useMemo, useState } from "react"
import { useDebounce } from "use-debounce"

import { useGetInventoryImage } from "@/api/useInventory"
import { AutoComplete, Select, Spin, Tag } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { MatchBadge } from "./MatchBadge"
import styles from "./MonorepoConfigurationsForm.module.scss"
import type { RepositoryImageCorrelation } from "./MonorepoConfigurationsForm.types"

type ImageAutoCompleteComponent = typeof AutoComplete<
  RepositoryImageCorrelation[]
>

export const ImageAutoComplete = ({
  onChange,
  selectedImageRepositories,
  ...rest
}: Omit<React.ComponentProps<ImageAutoCompleteComponent>, "onChange"> & {
  selectedImageRepositories: RepositoryImageCorrelation[]
  onChange: (value: string[]) => void
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [debouncedSearchValue] = useDebounce(searchValue, 500)
  const { t } = useTranslation()

  const onClear = () => {
    setSearchValue("")
    onChange?.([])
  }

  const onRemoveTag = (value: string) => {
    const newSelectedImageRepositories = selectedImageRepositories.filter(
      (v) => v.imageRepository !== value
    )
    onChange?.(newSelectedImageRepositories.map((v) => v.imageRepository))
  }
  const { response, isLoading } = useGetInventoryImage(
    {
      pageNumber: "0",
      imageName: debouncedSearchValue !== "" ? debouncedSearchValue : undefined
    },
    {
      select: (data) => {
        return data.data.map((item) => {
          const imageName = item.data.registry + "/" + item.data.repository
          return {
            value: imageName,
            label: imageName,
            imageRepository: imageName,
            correlationType: "auto"
          }
        })
      }
    }
  )

  const options = response || []
  const uniqueOptions = useMemo(() => {
    return options.filter((opt, idx) => {
      return options.findIndex((o) => o.value === opt.value) === idx
    })
  }, [options])
  return (
    <Select
      className={styles.select}
      popupClassName={styles.popupClassName}
      maxTagCount={3}
      notFoundContent={
        isLoading ? (
          <Spin size="small" className={styles.optionsLoader} />
        ) : (
          t("inventory.codeRepositoriesTab.drawer.config.noResults")
        )
      }
      onSearch={setSearchValue}
      allowClear
      onClear={onClear}
      filterOption={false}
      mode="tags"
      options={uniqueOptions}
      placeholder={t(
        "inventory.codeRepositoriesTab.drawer.config.selectImages"
      )}
      tagRender={(props) => {
        const matchType =
          selectedImageRepositories.find(
            (v) => v.imageRepository === props.value
          )?.correlationType || "auto"

        return (
          <Tag
            className={styles.tag}
            closable={!!props.value}
            icon={props.value ? <MatchBadge matchType={matchType} /> : null}
            onClose={() => onRemoveTag(props.value)}
          >
            {props.label}
          </Tag>
        )
      }}
      optionRender={(option) => {
        if (isLoading)
          return <Spin size="small" className={styles.optionsLoader} />
        const selected = selectedImageRepositories.find(
          (value) => value.imageRepository === option.value
        )
        if (!selected) return option.label
        return (
          <div className={styles.tag}>
            <MatchBadge matchType={selected.correlationType} />
            {option.label}
          </div>
        )
      }}
      {...rest}
      onChange={(v) => {
        onChange(v as unknown as string[])
      }}
    />
  )
}
