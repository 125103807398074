import { Input } from "@/atoms"
import { useDeployCommandValues } from "@/pages/DeploymentsPage/hooks/useDeployCommandValues"

import type { InputDescriptionProps } from "."

export const InputDescription = (props: InputDescriptionProps) => {
  const { name, addonBefore, placeholder, defaultValues = {} } = props
  const [values, setValues] = useDeployCommandValues()
  const isDefaultValue = defaultValues[name] === values[name]
  const value = isDefaultValue ? undefined : values[name]

  return (
    <div className="command-container-deploy-page">
      <Input
        addonBefore={addonBefore}
        placeholder={placeholder}
        value={value}
        onInput={(e) => {
          setValues({
            ...values,
            [name]: (e.target as HTMLInputElement).value
          })
        }}
      />
    </div>
  )
}
