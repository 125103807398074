import { useTransition } from "react"

import { Drawer, Tabs } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import {
  decodeParams,
  encodeParams
} from "@/organisms/Drawers/applications/common/common"
import { useStateDrawer } from "@/organisms/Drawers/hooks/useStateDrawer"
import { TabsTemplate } from "@/templates/TabsTemplate"
import { getIdsDrawer, useDrawer } from "../../hooks/useDrawer"
import { useTabs } from "../../hooks/useTabs"
import { ItemsTabs } from "../ItemsTabs"

import type { TabsDrawerTemplateComponent } from "."

export const TabsDrawerTemplate: TabsDrawerTemplateComponent = (props) => {
  const {
    tabs,
    open,
    titleProps,
    classes = {},
    decode = decodeParams,
    encode = encodeParams,
    ...drawerProps
  } = props
  const drawer = useDrawer(drawerProps.id)
  const attr = { decode, encode, drawer }
  const { logger, EVENTS } = useLogger()
  const tabsState = useTabs(tabs, attr, props)
  const drawerState = useStateDrawer(attr, props)
  const [{ activeTab }, actions] = tabsState
  const items = ItemsTabs(tabsState, drawerState, props)
  const { Title, minWidth, width = 900 } = props
  const { close } = drawer
  const [, startTransition] = useTransition()
  const displayTabs = !!tabs && !!drawerState.drawerId

  return (
    <Drawer
      destroyOnClose
      resizable
      open={drawerState.open}
      width={width}
      minWidth={minWidth}
      className={classes.drawer}
      onClose={() => {
        if (drawerState.drawerId) close()
      }}
      title={Title && <Title {...drawerState} {...titleProps} />}
      afterOpenChange={(open) => {
        if (open)
          logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.OPENED, {
            name: props.name,
            id: drawerState.record?.id
          })

        if (open) {
          const idsDrawers = getIdsDrawer(drawer.searchParams)

          if (idsDrawers.length > 3) {
            startTransition(() => {
              props.requestCloseDrawer?.(idsDrawers[0])
            })
          }

          actions.forceRender()
        }

        if (!open) close()
      }}
      {...drawerProps}
    >
      {displayTabs && (
        <TabsTemplate placement="drawer">
          <Tabs
            items={items}
            onChange={(nextActiveTab) => {
              const { name } = props

              logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.CHANGE_TAB, {
                name,
                id: drawerState.record?.id,
                tab: nextActiveTab
              })

              if (props.controlledActiveKey) {
                actions.onChangeActiveKey(nextActiveTab)
              }
            }}
            {...(props.controlledActiveKey ? { activeKey: activeTab } : {})}
          />
        </TabsTemplate>
      )}
      {!tabs && props.children}
    </Drawer>
  )
}
