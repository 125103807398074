import { useEffect } from "react"

import { Card, Flex, Form, Radio } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useHttp } from "@/hooks/useHttp"
import { usePrevious } from "@/hooks/usePrevious"
import { usePrompt } from "@/hooks/usePrompt"
import { useTranslation } from "@/hooks/useTranslation"
import { FileProtectOutlined, FilterOutlined } from "@/icons"
import { getConditionsOptions } from "@/pages/GovernancePage/applications/policies/usePolicies.selector"
import { ButtonListField } from "@/pages/GovernancePage/components/molecules/ButtonListField"
import { DetailsSectionForm } from "@/pages/GovernancePage/components/molecules/DetailsSectionForm"
import { ConditionSection } from "@/pages/GovernancePage/components/organisms/ScmForm/components/ConditionSection"
import {
  ALL_SCOPE,
  SPECIFIC_SCOPE
} from "@/pages/GovernancePage/helpers/workflows"
import { useFiltersScopes } from "@/pages/SettingsPage/application/scopes/useScopes"
import { CardFormList } from "@/pages/SettingsPage/components/organisms/CardFormList"
import { SectionFormTemplate } from "@/pages/SettingsPage/components/templates/SectionFormTemplate"
import { ActionsSectionForm } from "../ActionsSectionForm"
import { TriggerSectionForm } from "../TriggerSectionForm"

import styles from "./AutomatedActionForm.module.scss"

import type { AutomatedActionFormProps } from "."

export const AutomatedActionForm = (props: AutomatedActionFormProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { response } = useFiltersScopes()
  const { data: options = { resources: [], fields: {} } } = response || {}

  const triggerCategory = Form.useWatch("trigger.category", form)
  const triggerType = Form.useWatch("trigger.type", form)

  const disabled = !triggerCategory || !triggerType

  const { response: conditionsOptions } = useHttp<any>(
    `${RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST}/filter_conditions/options`,
    {
      trigger_type: triggerType,
      trigger_category: triggerCategory
    },
    { enabled: !disabled, select: getConditionsOptions }
  )

  const resourcesInitialValues = { filters: [{}], includeExclude: "Include" }
  const formKey = "settings.automatedActions.form."
  const sectionKey = `${formKey}sections.`
  const fieldKey = `${formKey}fields.`

  usePrompt(t("prompt.form.message"), !props.saving && props.isDirty)

  const prevIsDirty = usePrevious(props.isDirty)

  useEffect(() => {
    if (!!prevIsDirty && !props.isDirty) {
      const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE

      props.actions.navigate(`/${ROOT}/${WORKFLOWS}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDirty])

  const currentScopeFieldName = "currentScope"

  const isSpecificScope =
    Form.useWatch(currentScopeFieldName, form) === SPECIFIC_SCOPE

  return (
    <Form
      id="automation-action-form"
      scrollToFirstError={{ behavior: "smooth", block: "end" }}
      className="form-page-with-breadcrumb-template-ks"
      form={form}
      initialValues={props.initialValues}
      layout="vertical"
      onFinish={props.onFinish}
      onFieldsChange={props.onFieldsChange}
    >
      <DetailsSectionForm
        label={t(`${fieldKey}details.label`)}
        title={t(`${sectionKey}details`)}
        {...props}
      />
      <SectionFormTemplate
        id="resources"
        title={t(`${sectionKey}resources`)}
        Icon={FilterOutlined}
      >
        <Form.Item required={false} name={currentScopeFieldName}>
          <Radio.Group className={styles.radioGroup}>
            <Card className={styles.card}>
              <Radio value={ALL_SCOPE}>
                {t(`${formKey}fields.resources.${ALL_SCOPE}`)}
              </Radio>
            </Card>
            <Card className={styles.card}>
              <Radio value={SPECIFIC_SCOPE}>
                {t(`${formKey}fields.resources.${SPECIFIC_SCOPE}`)}
              </Radio>
              {isSpecificScope && (
                <div className={styles.conditionsList}>
                  <Form.List name="conditions">
                    {(fields, { add, remove }) => (
                      <Flex vertical gap={8}>
                        {fields.map(({ key, name }, index) => (
                          <CardFormList
                            key={key}
                            name={name}
                            form={form}
                            remove={() => remove(index)}
                            options={options}
                            cardsLength={fields.length}
                            isAllResources={false}
                          />
                        ))}
                        <ButtonListField
                          type="and"
                          onClick={() => add(resourcesInitialValues)}
                        />
                      </Flex>
                    )}
                  </Form.List>
                </div>
              )}
            </Card>
          </Radio.Group>
        </Form.Item>
      </SectionFormTemplate>

      <TriggerSectionForm form={form} />

      <SectionFormTemplate
        id="conditions"
        title={t(`${sectionKey}conditions`)}
        Icon={FileProtectOutlined}
      >
        <ConditionSection
          disabled={disabled}
          form={form}
          rules={[]}
          conditionsOptions={conditionsOptions || []}
          setFormIsDirty={() => {}}
        />
      </SectionFormTemplate>
      <ActionsSectionForm
        disabled={disabled}
        form={form}
        triggerCategory={triggerCategory}
        triggerType={triggerType}
      />
    </Form>
  )
}
