import cn from "classnames"

import { Badge, Button, Col, Flex, Row, Space, Text } from "@/atoms"

import { FolderOutlined, SaveOutlined, SourceControlIcon } from "@/icons"
import { useConfirmModal } from "@/molecules/ConfirmModal"
import { InformationPopover } from "@/molecules/InformationPopover"
import { ModulesTree } from "@/molecules/ModulesTree/ModulesTree"
import { ROOT_KEY } from "@/molecules/ModulesTree/ModulesTree.types"
import { Loader } from "@/pages/SettingsPage/components/molecules/Loader"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MonorepoConfigurationsForm } from "./ModuleConfigurationsForm/MonorepoConfigurationsForm"
import { MonorepoConfigurationsProps } from "./MonorepoConfigurations.types"

import styles from "./MonorepoConfigurations.module.scss"

import { preventEventBubbling } from "@/helpers/utils"
import { ActionsDropdown } from "@/molecules/ActionsDropdown"
import type { Configs } from "./MonorepoConfigurations.types"

type Node = {
  key: string
  children?: Node[]
}

function getAllKeys(node: Node): string[] {
  // This will store all the keys
  let keys: string[] = []

  // A recursive function to traverse the tree
  function traverse(currentNode: Node): void {
    // Add the current node's key to the keys array
    keys.push(currentNode.key)
    if (!currentNode.children) return
    // Recurse over each of the children nodes
    for (let child of currentNode.children) {
      traverse(child)
    }
  }

  // Start the traversal from the input node
  traverse(node)

  // Return the collected keys
  return keys
}

function getAllAvailablePaths(paths: string[]): string[] {
  const result: Set<string> = new Set()

  paths.forEach((path) => {
    const components = path.split("/")
    let currentPath = ""

    components.forEach((component, index) => {
      currentPath += component
      result.add(currentPath)

      // Add separator for next component if it's not the last one
      if (index < components.length - 1) {
        currentPath += "/"
      }
    })
  })

  return Array.from(result)
}

export function MonorepoConfigurations(props: MonorepoConfigurationsProps) {
  const {
    record,
    configLoading = false,
    modules = [],
    repoName,
    scm = "OnPrem",
    configs,
    onSave,
    onReset,
    loading = false
  } = props
  const normalizedModules = useMemo(() => {
    return modules.map((module) => ({
      ...module,
      path: module.path.replace(/^\//, "")
    }))
  }, [modules])
  const [selected, setSelected] = useState<string>(ROOT_KEY)
  const [highlights, setHighlights] = useState<string[]>([selected])
  const [currentConfigs, setCurrentConfigs] = useState<Configs>(configs || {})
  const { confirm } = useConfirmModal()
  const { t } = useTranslation()
  const edited = useMemo(() => {
    return Object.entries(currentConfigs)
      .filter(
        ([_, conf]) => conf.splitPath || conf.imageRepositories.length > 0
      )
      .map(([path, _]) => path)
  }, [currentConfigs])

  useEffect(() => {
    if (!loading) setCurrentConfigs(configs || {})
  }, [configs, loading])

  const selectedConfigs = currentConfigs[
    selected === ROOT_KEY ? "" : selected!
  ] || {
    imageRepositories: [],
    exclude: false,
    splitPath: false
  }

  const handleSave = () => {
    const onlyNoneEmpty = Object.fromEntries(
      Object.entries(currentConfigs).filter(
        ([_, value]) =>
          value.splitPath || value.exclude || value.imageRepositories.length > 0
      )
    )
    onSave(onlyNoneEmpty)
  }

  const formatProjectName = (selected: string) => {
    if (selected === ROOT_KEY) return repoName
    if (selected.startsWith("/")) return `${repoName}${selected}`
    return `${repoName}/${selected}`
  }

  const formatSelectedTitle = (selected: string) => {
    if (selected === ROOT_KEY) return repoName
    if (selected.startsWith("/")) return `${repoName}${selected}`
    return `${repoName}/${selected}`
  }

  const isFile = (path: string) => {
    return !!normalizedModules.find((module) => module.path === path)
  }

  const dirname = (path: string) => {
    return path.split("/").slice(0, -1).join("/").replace(/^\/+/, "")
  }

  const allAdjacentModules = (path: string) => {
    const dir = dirname(path)
    const inSameDir = normalizedModules
      .filter((module) => module.path.startsWith(dir))
      .map((module) => module.path)
    return getAllAvailablePaths(inSameDir).filter((p) => p.startsWith(dir))
  }

  /**
   * Reset the configurations of the selected module by removing it from the currentConfigs
   * and calling save
   */
  const handleResetSelected = () => {
    if (!selected) return
    if (selected === ROOT_KEY) {
      onReset()
      return
    }
    const { [selected]: _, ...rest } = currentConfigs
    onSave(rest)
  }

  return (
    <Row data-resizable-tab-template="true" className={styles.rowContainer}>
      <Col span={12} className={styles.treeContainer}>
        <Row className={styles.headerComponent}>
          <Col span={24}>
            <Flex justify="space-between" align="center">
              <Flex vertical>
                <Flex align="center" gap={8}>
                  <Text type="secondary" className={styles.secondaryTextHeader}>
                    {t(
                      "inventory.codeRepositoriesTab.drawer.config.currentRepository"
                    )}
                    :{" "}
                  </Text>
                  <InformationPopover.Popover
                    content={
                      <InformationPopover.CommonTemplate>
                        {t(
                          "inventory.codeRepositoriesTab.drawer.config.currentRepositoryDescription"
                        )}
                      </InformationPopover.CommonTemplate>
                    }
                  />
                </Flex>
                <Text className={styles.textHeader} ellipsis>
                  {repoName}
                </Text>
                <Text type="secondary" className={styles.secondaryTextHeader}>
                  {`${t("inventory.codeRepositoriesTab.drawer.overview.lastSeen")}: ${record.lastSeen}`}
                </Text>
              </Flex>
              <Space>
                <Flex align="center" gap={8}>
                  <Badge color="#40A9FF" size="small" />{" "}
                  {t("inventory.codeRepositoriesTab.drawer.config.edited")}
                </Flex>
                <div
                  onClick={preventEventBubbling}
                  data-testid="monorepo-configurations-actions-cell"
                >
                  <ActionsDropdown
                    menu={{
                      items: [
                        {
                          key: "resetAll",
                          label: t(
                            "inventory.codeRepositoriesTab.drawer.config.resetAll"
                          ),
                          onClick: () => {
                            confirm(() => ({
                              title: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalTitle"
                              ),
                              content: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalContent"
                              ),
                              okText: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalConfirmLabel"
                              ),
                              okButtonProps: {
                                style: { textTransform: "none" }
                              },
                              onOk: () => {
                                onReset()
                              }
                            }))
                          }
                        },
                        {
                          key: "resetSelected",
                          label: t(
                            "inventory.codeRepositoriesTab.drawer.config.resetSelected"
                          ),
                          onClick: () => {
                            confirm(() => ({
                              title: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalTitle"
                              ),
                              content: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalContent"
                              ),
                              okText: t(
                                "inventory.codeRepositoriesTab.drawer.config.resetModalConfirmLabel"
                              ),
                              okButtonProps: {
                                style: { textTransform: "none" }
                              },
                              onOk: handleResetSelected
                            }))
                          }
                        }
                      ]
                    }}
                  />
                </div>
              </Space>
            </Flex>
          </Col>
        </Row>
        <div className={styles.wrapper}>
          <ModulesTree
            repo={{ name: repoName, scm }}
            selectedKeys={highlights}
            onSelect={(_, e) => {
              const nodeKey = e.node.key.toString()
              if (nodeKey.length === 0) return
              if (isFile(nodeKey)) {
                const dir = dirname(nodeKey)
                if (dir === "") {
                  setSelected(ROOT_KEY)
                  setHighlights([ROOT_KEY])
                  return
                }
                setSelected(dir === "" ? ROOT_KEY : dir)
                setHighlights([
                  dir === "" ? ROOT_KEY : dir,
                  ...allAdjacentModules(nodeKey)
                ])
                return
              }
              setSelected(nodeKey)
              setHighlights(
                nodeKey === ROOT_KEY ? [ROOT_KEY] : getAllKeys(e.node)
              )
            }}
            // directoriesOnly
            modules={normalizedModules}
            defaultExpandAll
            edited={edited}
          />
        </div>
      </Col>
      <Col span={12}>
        {configLoading ? (
          <Loader />
        ) : (
          <>
            <Row className={styles.headerComponent}>
              {selected && (
                <Col span={24}>
                  <Flex align="center" gap={6}>
                    {selected === ROOT_KEY ? (
                      <SourceControlIcon sourceControl={scm} />
                    ) : (
                      <FolderOutlined />
                    )}
                    <Text
                      className={cn(styles.textHeader, styles.lineHeight)}
                      ellipsis
                    >
                      {formatSelectedTitle(selected)}
                    </Text>
                  </Flex>
                </Col>
              )}
            </Row>
            {!!selected ? (
              <div className={cn(styles.contentContainer, styles.form)}>
                <div>
                  <MonorepoConfigurationsForm
                    isRootSelected={selected === ROOT_KEY}
                    projectName={formatProjectName(selected)}
                    onChange={(v) => {
                      if (!selected) return
                      setCurrentConfigs((c) => ({
                        ...c,
                        [selected === ROOT_KEY ? "" : selected]: v
                      }))
                    }}
                    values={selectedConfigs}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.emptyContainer}>
                <Text className={styles.emptyContainerTitle}>
                  {t(
                    "inventory.codeRepositoriesTab.drawer.config.noSelectionTitle"
                  )}
                </Text>
                <Text className={styles.emptyContainerContent}>
                  {t(
                    "inventory.codeRepositoriesTab.drawer.config.noSelectionContent"
                  )}
                </Text>
              </div>
            )}
          </>
        )}
        <div className={styles.footer}>
          <Button
            loading={loading}
            onClick={() => {
              confirm(() => ({
                title: t(
                  "inventory.codeRepositoriesTab.drawer.config.saveModalTitle"
                ),
                content: t(
                  "inventory.codeRepositoriesTab.drawer.config.saveModalContent"
                ),
                okText: t(
                  "inventory.codeRepositoriesTab.drawer.config.saveModalConfirmLabel"
                ),
                okButtonProps: { style: { textTransform: "none" } },
                onOk: () => {
                  handleSave()
                }
              }))
            }}
            type="primary"
            icon={<SaveOutlined />}
          >
            <span style={{ textTransform: "none" }}>
              {t(
                "inventory.codeRepositoriesTab.drawer.config.saveModalConfirmLabel"
              )}
            </span>
          </Button>
        </div>
      </Col>
    </Row>
  )
}
