import { preventEventBubbling } from "@/helpers/utils"
import { ActionsDropdown } from "../ActionsDropdown"

import type { ActionsCellComponent } from "."

export const ActionsCell: ActionsCellComponent = ({
  noPermissions,
  ...menu
}) => (
  <div
    onClick={preventEventBubbling}
    data-testid="actions-cell"
    data-tooltip-content={
      noPermissions ? "general.noPermissionsTooltip" : undefined
    }
    data-tooltip-translation={noPermissions ? "true" : undefined}
  >
    <ActionsDropdown noPermissions={noPermissions} menu={menu} />
  </div>
)
