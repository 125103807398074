import { useMemo } from "react"

import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags, useUser } from "@/hooks/useUser"

import type { PropsUseTeasers } from "./useTeasers.types"

export const useTeasers = (props: PropsUseTeasers | undefined) => {
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const kortexInstalledFF = useFeatureFlags("kortexInstalled")
  const scmInstalledFF = useFeatureFlags("scmInstalled")
  const ciIntegratedFF = useFeatureFlags("ciIntegrated")

  const { teasers = {} } = props || {}
  const { t } = useTranslation()
  const { user } = useUser()

  const dataReadinessProps =
    dataReadinessFF &&
    !!teasers["dataReadiness"] &&
    (teasers["dataReadiness"] || { title: t("collectingData.digesting") })
  const usesCiIntegration = !!teasers["ciIntegrated"]

  let kortexInstalledProps = !kortexInstalledFF && teasers["kortexInstalled"]

  if (!!kortexInstalledProps && usesCiIntegration && ciIntegratedFF) {
    kortexInstalledProps = false
  }

  const scmInstalledProps = !scmInstalledFF && teasers["scmInstalled"]

  const useConditionAndToShowSetupRequiredTeaser =
    teasers["kortexInstalled"] && teasers["scmInstalled"]
  const shouldShowSetupRequiredTeaser = useConditionAndToShowSetupRequiredTeaser
    ? kortexInstalledProps && scmInstalledProps
    : kortexInstalledProps || scmInstalledProps
  const shouldShowTeasersWithProps =
    shouldShowSetupRequiredTeaser || dataReadinessProps

  const result = useMemo(
    () => [
      !!shouldShowTeasersWithProps,
      {
        type: shouldShowSetupRequiredTeaser ? "setup_required" : "digesting",
        ...(shouldShowTeasersWithProps || {})
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowSetupRequiredTeaser]
  )

  const noReceivedFF =
    kortexInstalledFF === undefined && scmInstalledFF === undefined

  if (user.role === undefined || noReceivedFF) return [false, {}]

  return result
}
