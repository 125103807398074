import { SourceControlIcon } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { ExternalLinkIcon } from "@/molecules/ExternalLinkIcon"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import type { EntityRecord } from "@/organisms/Drawers/applications/common"
import { useBranchEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"
import styles from "./TitleCodeRepositoryDrawer.module.scss"
import { getRepositoryUrl } from "./helpers"

export const TitleCodeRepositoryDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const [context] = useBranchEntity(props?.record?.id)
  const { response, isLoading } = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)

  if (isLoading || !data?.length) return <SkeletonTitle />

  const { sourceControl, repositoryName, branchName } = data[0]
  const repoUrl = getRepositoryUrl(sourceControl, repositoryName)

  return (
    <TitleDrawer
      icon={<SourceControlIcon sourceControl={sourceControl} />}
      title={
        <div className={styles.linkedTitle}>
          {repositoryName}
          {repoUrl && <ExternalLinkIcon href={repoUrl} />}
        </div>
      }
      subTitle={`Branch: ${branchName || ""}`}
      aside={<CopyLinkButton link={link} />}
    />
  )
}
