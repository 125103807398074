import { RolePolicies } from "@/domain/user"

export const rolePolicies: RolePolicies = {
  OPERATOR: {
    issues: ["write"],
    tickets: ["write"],
    labels: ["write"]
  },
  TICKET_CREATOR: {
    tickets: ["write"]
  },
  VIEWER: {}
}
