import { Button, Form, Modal } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import {
  useAuthMethods,
  useUpdateAuthMethods
} from "@/pages/SettingsPage/application/auth/useAuthMethods"
import { Loader } from "../../molecules/Loader"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import { AuthenticationMethodCollapse } from "../AuthenticationMethodCollapse"

import { Protected } from "@/molecules/Protected"
import styles from "./AuthenticationMethodTab.module.scss"

export const AuthenticationMethodTab: React.FC = () => {
  const { t } = useTranslation("translation")
  const { response, isLoading } = useAuthMethods()
  const { updateAuthMethod, isLoading: isCreating } = useUpdateAuthMethods()
  const [form] = Form.useForm()
  const dirty = Form.useWatch("dirty", form)
  const disabledBtn = !dirty || isCreating
  const [modal, modalContextHolder] = Modal.useModal()

  return (
    <VerticalTabTemplate
      title={<Heading level={4} title={t("settings.auth.title")} />}
      footer={
        <>
          <Button
            onClick={() => {
              modal.confirm({
                width: 400,
                className: styles.modal,
                content: "Are you sure you want to cancel the changes?",
                okText: t("general.approve"),
                cancelText: t("general.backToEdit"),
                onOk: () => {
                  form.resetFields()
                }
              })
            }}
            disabled={disabledBtn}
          >
            {t("general.cancel")}
          </Button>
          <Protected
            permission={{ resource: "settings.auth", action: "write" }}
            shouldDisable
          >
            <Button
              icon={<CheckCircleOutlined />}
              loading={isCreating}
              onClick={form.submit}
              disabled={disabledBtn}
              type="primary"
            >
              {t("general.apply")}
            </Button>
          </Protected>
        </>
      }
    >
      {isLoading && <Loader />}
      {!isLoading && !!response && (
        <AuthenticationMethodCollapse
          form={form}
          data={response.data}
          updateAuthMethod={updateAuthMethod}
        />
      )}
      {modalContextHolder}
    </VerticalTabTemplate>
  )
}
