import { Badge, Link, Tooltip } from "@/atoms"
import { getQueryStringByKey } from "@/hooks/useQueryStringStorage"
import { Fragment } from "react"
import { FeatureStatusTag } from "../FeatureStatusTag"
import { InformationPopover } from "../InformationPopover"

import styles from "./LabelTabItem.module.scss"

import type { LabelTabItemProps, WrapProps } from "."

export const LabelTabItem = ({
  title,
  to,
  content,
  keyStorageRestoreQueryString,
  icon,
  testId,
  tooltip,
  onClick,
  featureStatus,
  ...props
}: LabelTabItemProps) => {
  const queryStringInStorage = getQueryStringByKey(keyStorageRestoreQueryString)
  const useLink = to && !onClick
  let Wrapper = useLink ? Link : Fragment
  let wrapperProps: WrapProps = useLink
    ? { to: `${to}${queryStringInStorage}` }
    : {}

  if (tooltip) {
    Wrapper = Tooltip
    wrapperProps = { title: tooltip, to: undefined }
  }

  return (
    <Wrapper {...wrapperProps}>
      <div className={styles.container} onClick={onClick}>
        <span className={styles.title} data-testid={`${testId}-title`}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {title}
          {featureStatus && (
            <FeatureStatusTag className={styles.tag} type={featureStatus} />
          )}
        </span>
        {content && (
          <InformationPopover.Popover
            content={
              <InformationPopover.CommonTemplate>
                {content}
              </InformationPopover.CommonTemplate>
            }
          />
        )}
        <Badge
          className={styles.badge}
          data-testid={`${testId}-badge`}
          {...props}
        />
      </div>
    </Wrapper>
  )
}
