import cn from "classnames"
import { Fragment } from "react"

import { Menu, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FolderViewOutlined } from "@/icons"
import { EmptyMenu } from "./components/EmptyMenu"
import { FooterMenu } from "./components/FooterMenu"
import { ListMenu } from "./components/ListMenu"
import { useScope } from "./useScope"

import styles from "./ScopeMenu.module.scss"

import type { ScopeMenuProps } from "."

export const ScopeMenu: React.FC<ScopeMenuProps> = ({ collapsed }) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.scopes" })
  const {
    data,
    search,
    isLoading,
    isFetchedAfterMount,
    selectedId,
    currentScopeId,
    currentScopeName,
    openKeys,
    scopesMenuKey,
    actions
  } = useScope()
  const applyDisabled =
    (!selectedId && !currentScopeId) || `${selectedId}` === `${currentScopeId}`
  const tooltipProps = collapsed
    ? {
        destroyTooltipOnHide: true,
        title: t("title"),
        placement: "right" as const,
        rootClassName: "root-class-tooltip-dark-antd-ks"
      }
    : {}
  const TooltipComponent = collapsed ? Tooltip : Fragment

  return (
    <TooltipComponent {...tooltipProps}>
      <Menu
        triggerSubMenuAction="click"
        disabled={!isFetchedAfterMount}
        className={cn(
          styles.menu,
          !collapsed && styles.notCollapsed,
          currentScopeName && styles.scopeIsActive
        )}
        theme="dark"
        openKeys={openKeys}
        onOpenChange={(openKeys) => {
          if (currentScopeId === null) return
          if (!!openKeys.length) {
            actions.resetToCurrentScope()
          }
          actions.setOpenKeys(openKeys)
        }}
        items={[
          {
            key: scopesMenuKey,
            label: currentScopeName || t("title"),
            icon: <FolderViewOutlined />,
            popupClassName: styles.popupClassName,
            children: [
              {
                label: (
                  <ListMenu
                    title={t("title")}
                    description={t("menu.description")}
                    selectedId={selectedId}
                    setSelectedItem={actions.setSelectedScope}
                    search={search}
                    isLoading={isLoading}
                    items={data || []}
                    footer={
                      <FooterMenu
                        applyDisabled={applyDisabled}
                        clearDisabled={!selectedId}
                        manageScopesDisabled={currentScopeId === null}
                        actions={actions}
                      />
                    }
                    empty={<EmptyMenu actions={actions} />}
                    actions={actions}
                  />
                ),
                key: "scopesMenu",
                className: cn(!data?.length && styles.emptyMenu)
              }
            ]
          }
        ]}
      />
    </TooltipComponent>
  )
}
