export type Role = "ADMIN" | "OPERATOR" | "TICKET_CREATOR" | "VIEWER"
export const defaultRole: Role = "VIEWER"

export type Resource =
  | "issues"
  | "tickets"
  | "labels"
  | "integrations"
  | "settings.scopes"
  | "settings.users"
  | "settings.auth"
  | "settings.labels"
  | "settings.code-scan"
  | "settings.service-keys"
  | "settings.deployment-keys"
  | "scm.policies"
  | "ci.policies"
  | "governance.workflows"
  | "system"
  | "*"

export type Action = "read" | "write" | "*"

export type RolePolicies = Partial<
  Record<Role, Partial<Record<Resource, Action[]>>>
>

export interface Permission {
  resource: Resource
  action: Action
}
