import cn from "classnames"
import { useRef, useState } from "react"

import { Divider, Search, Spin, Text, Tooltip } from "@/atoms"
import { CheckOutlined, NoFound } from "@/icons"
import { CreateActionEmptyTable } from "@/molecules/EmptyTable"
import { Heading } from "@/molecules/Heading"

import styles from "./ListMenu.module.scss"

import type { ListMenuProps } from "."

export const ListMenu: React.FC<ListMenuProps> = (props) => {
  const {
    title,
    items,
    setSelectedItem,
    selectedId,
    description,
    footer,
    empty,
    search,
    isLoading
  } = props
  const stopPropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
  }

  const [searchKey, setSearchKey] = useState(Date.now())
  const isEmpty = !items?.length && !search
  const showList = !isEmpty
  const showLoading = isLoading && search
  const emptySearch = !items?.length && !showLoading

  const EmptyState = useRef(
    CreateActionEmptyTable({
      tKey: "",
      message: "No results were found",
      action: "Clear search",
      image: <NoFound />,
      onClick: () => {
        setSearchKey(Date.now())
        props.actions.setSearch("")
      }
    })
  ).current

  return (
    <div
      className={styles.container}
      onKeyDown={stopPropagation}
      onClick={stopPropagation}
    >
      <div className={styles.header}>
        <Heading level={4} title={title} />
        {showList && (
          <>
            {description && <Text className={styles.text}>{description}</Text>}
            <Search
              allowClear
              key={searchKey}
              onSearch={props.actions.setSearch}
            />
          </>
        )}
      </div>
      <Divider className={styles.divider} />
      {showList && (
        <div className={styles.list}>
          {items.map((item) => {
            const { id, name } = item
            const isActive = id === selectedId

            return (
              <Tooltip
                key={id}
                rootClassName="tooltip-scope-menu-ks"
                title={name}
              >
                <div
                  className={cn(styles.item, isActive && styles.active)}
                  onClick={() => setSelectedItem(item)}
                >
                  <Text ellipsis key={id}>
                    {name}
                  </Text>

                  {isActive && <CheckOutlined />}
                </div>
              </Tooltip>
            )
          })}
          {showLoading && (
            <div className={styles.loading}>
              <Spin />
            </div>
          )}
          {emptySearch && EmptyState}
        </div>
      )}
      <Divider className={styles.divider} />
      {!!footer && showList && <div className={styles.footer}>{footer}</div>}
      {isEmpty && !!empty && <div className={styles.empty}>{empty}</div>}
    </div>
  )
}
