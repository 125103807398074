import { useEffect, useRef, useState } from "react"

import type { Key, Props, State, TabsKeys } from "./useTabs.types"

export type Actions = ReturnType<typeof useTabs>[1]

export const getInitialCounts = (tabs: TabsKeys) =>
  tabs.reduce(
    (acc, key) => {
      acc[key] = 0

      return acc
    },
    {} as Record<Key, number>
  )

export const getInitialState = (
  tabs: TabsKeys,
  forceRender = false,
  props?: Props
) => ({
  forceRender,
  counts: getInitialCounts(tabs),
  tabsProps: { ...props?.initialState }
})

export const useTabs = (tabs: TabsKeys, attr: any, props?: Props) => {
  const { drawer, ...rest } = attr
  const { activeTabFieldName, searchParams, setSearchParams } = drawer
  const [state, setState] = useState<State>(getInitialState(tabs, false, props))
  const isValueExist = searchParams.has(activeTabFieldName)
  let activeTab = ""

  useEffect(() => {
    if (isValueExist) {
      const value = searchParams.get(activeTabFieldName)

      if (!value) return

      if (!tabs.includes(value) || state.tabsProps.disabled?.[value]) {
        searchParams.delete(activeTabFieldName)
        return setSearchParams(searchParams)
      }

      activeTab = value
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueExist])

  const lastActiveTab = useRef(activeTab) // lastActiveTab is used to keep track of the last active tab to prevent switching to the first tab during the closing animation

  const onChangeActiveKey = (activeTab: Key) => {
    if (!!activeTab && !state.tabsProps.disabled?.[activeTab]) {
      const { setSearchParams } = drawer

      lastActiveTab.current = activeTab
      searchParams.set(activeTabFieldName, activeTab)
      setSearchParams(searchParams, { replace: true })
    }
  }

  const onChangeCountByKey = (name: Key, count: number) => {
    setState((prev) => ({ ...prev, counts: { ...prev.counts, [name]: count } }))
  }

  const onChangeTabAvailability = (name: Key, enabled: boolean) => {
    setState((prev) => ({
      ...prev,
      tabsProps: {
        ...prev.tabsProps,
        disabled: { ...prev.tabsProps.disabled, [name]: !enabled }
      }
    }))
  }

  const forceRender = () => setState((prev) => ({ ...prev, forceRender: true }))

  const activeTabValue = activeTab || lastActiveTab.current || tabs[0]
  const actions = {
    forceRender,
    onChangeActiveKey,
    onChangeCountByKey,
    onChangeTabAvailability,
    ...rest
  }

  return [{ activeTab: activeTabValue, ...state }, actions] as const
}
