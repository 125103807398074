import { SourceControlIcon, VM } from "@/icons"
import { IconTextCell } from "@/molecules/IconTextCell"

import type { ResourceComponent } from "."
import { ImageIcon } from "../../../../../icons/Images"

export const Resource: ResourceComponent = ({
  type,
  name,
  sourceControl,
  imageSource
}) => {
  if (type === "ImageRepository") {
    if (!imageSource) return null
    return (
      <IconTextCell
        noTooltip
        icon={<ImageIcon imageSource={imageSource} />}
        text={name}
      />
    )
  }

  if (type === "Vm") {
    return <IconTextCell noTooltip icon={<VM />} text={name} />
  }

  return (
    <IconTextCell
      noTooltip
      icon={<SourceControlIcon sourceControl={sourceControl} />}
      text={name}
    />
  )
}
