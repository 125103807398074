export const ANALYTIC_EVENTS = {
  LOGIN: "LOGIN",
  NAVIGATION: "NAVIGATION",
  PAGE_OPENED: "PAGE_OPENED",
  pageView: "page.view",
  OPEN_API_DOCUMENTATION: "open_api_documentation",
  DASHBOARD: {
    POSTURE_SCORE_OVERTIME: {
      HOVER: "dashboard.posture_score_overtime.hover"
    },
    CLICK_INVENTORY_SNAPSHOT: {
      APPLICATIONS: "dashboard.inventory_snapshot.applications",
      CODE_REPOSITORIES: "dashboard.inventory_snapshot.code_repositories",
      IMAGES: "dashboard.inventory_snapshot.images",
      BASE_IMAGES: "dashboard.inventory_snapshot.base_images",
      PACKAGES: "dashboard.inventory_snapshot.packages"
    },
    FUNNEL: {
      NAVIGATION: "dashboard.funnel.navigation"
    },
    VULNERABLE_FUNCTION: {
      ISSUE_WITH_VULNERABLE_FUNCTION_CLICKED:
        "dashboard.vulnerable_function.issue_with_vulnerable_function.clicked",
      ISSUE_WITH_NO_VULNERABLE_FUNCTION_CLICKED:
        "dashboard.vulnerable_function.issue_with_no_vulnerable_function.clicked"
    },
    EXPLOITABLE_ISSUE: {
      NAVIGATION_TO_ISSUES_PAGE: "dashboard.exploitable_issue.navigation"
    },
    ISSUE_TYPES_BY_SEVERITY: {
      ISSUE_TYPE_CLICKED: "dashboard.issue_types_by_severity.issue_type.clicked"
    },
    TOP_ISSUES: {
      ISSUE_CLICKED: "dashboard.top_issues.issue.clicked"
    }
  },
  INTEGRATIONS: {
    CLICKED: "integrations.clicked",
    OPENED_INSTRUCTION: "integrations.instructions",
    OPENED_CONNECT: "integrations.connect",
    METHOD_SELECTED: "integrations.method_selected",
    COMMAND_CONNECT: "integrations.command_connect",
    COMMAND_CANCEL: "integrations.command_cancel",
    COMMAND_REMOVE: "integrations.command_remove",
    KODEMCI: {
      KODEM_CI: {
        OPENED_GENERATE_KEY: "integrations.kodemci.kodem_ci.generate_key",
        COMMAND_GENERATE_KEY:
          "integrations.kodemci.kodem_ci.command_generate_key"
      },
      JENKINS: {
        OPENED_GENERATE_KEY: "integrations.kodemci.jenkins.generate_key",
        COMMAND_GENERATE_KEY:
          "integrations.kodemci.jenkins.command_generate_key"
      },
      GITHUB_ACTIONS: {
        OPENED_GENERATE_KEY: "integrations.kodemci.github_actions.generate_key",
        COMMAND_GENERATE_KEY:
          "integrations.kodemci.github_actions.command_generate_key"
      },
      CIRCLE_CI: {
        OPENED_GENERATE_KEY: "integrations.kodemci.circle_ci.generate_key",
        COMMAND_GENERATE_KEY:
          "integrations.kodemci.circle_ci.command_generate_key"
      }
    }
  },
  SBOM: {
    EXPORT: "sbom.export",
    SORT: "sbom.sort",
    COPYABLE: {
      CODE_REPOSITORY: "image_details_card.code_repo_copy",
      COMMAND: "image_details_card.command_copy",
      IMAGE_NAME: "image_details_card.image_name_copy"
    },
    DRAWER: {
      OPENED: "sbom.record",
      CLOSED: "sbom.drawer.closed",
      VULNERABILITIES: {
        CLICKED_CVE_LINK: "sbom.cve.cve_link"
      }
    }
  },
  REMEDIES: {
    SORT: "remedies.sort",
    DRAWER: {
      OPENED: "remedies.opened",
      CLOSED: "remedies.closed"
    },
    CREATE_ISSUE_JIRA: "remedies.jira.created"
  },
  TAB: {
    FILTER: {
      ADVANCE_SEARCH: {
        CLICK: {
          KEY: "key.click.advance_search.filter.tab",
          VALUE: "value.click.advance_search.filter.tab"
        }
      }
    }
  },
  INVENTORY: {
    DRAWERS: {
      ISSUES_EXPORT: "inventory.drawer.issues.export",
      SBOM_EXPORT: "inventory.drawer.sbom.export"
    },
    BASE_IMAGES: {
      EXPORT: "base_images.export",
      DRAWER: {
        OPENED: "inventory.base_images.drawer.opened"
      }
    },
    CODE_REPOSITORIES_TAB: {
      EXPORT: "code_repositories.export",
      DRAWER: {
        OPENED: "inventory.code_repositories.drawer.opened",
        CHANGE_TAB: "inventory.code_repositories.drawer.change_tab",
        TABS: {
          PACKAGES: {
            SORT: "inventory.code_repositories.drawer.tabs.packages.sort",
            EXPAND_ROW:
              "inventory.code_repositories.drawer.tabs.packages.expand_row"
          },
          ISSUES: {
            SORT: "inventory.code_repositories.drawer.tabs.issues.sort",
            EXPAND_ROW:
              "inventory.code_repositories.drawer.tabs.issues.expand_row"
          }
        }
      }
    },
    IMAGES: {
      EXPORT: "images.export",
      DRAWER: {
        OPENED: "inventory.images.drawer.opened",
        CHANGE_TAB: "inventory.images.drawer.change_tab",
        TABS: {
          PACKAGES: {
            SORT: "inventory.images.drawer.tabs.packages.sort",
            EXPAND_ROW: "inventory.images.drawer.tabs.packages.expand_row"
          },
          ISSUES: {
            SORT: "inventory.images.drawer.tabs.issues.sort",
            EXPAND_ROW: "inventory.images.drawer.tabs.issues.expand_row"
          }
        }
      }
    },
    APPLICATIONS: {
      EXPORT: "applications.export"
    }
  },
  TRIAGE: {
    EXPAND_ROW: "triage.expand_row",
    CREATE_ISSUE_JIRA: "triage.jira.created",
    DISMISS_ISSUE: "triage.dismiss_issue",
    REOPEN_ISSUE: "triage.reopen_issue",
    EXPORT: "triage.export",
    DRAWER: {
      IN_RUNTIME_COLLAPSE_OPEN: "triage.drawer.in_runtime_collapse_open"
    }
  },
  DRAWER: {
    OPENED: "drawer.opened",
    CLOSED: "drawer.closed",
    CHANGE_TAB: "drawer.change_tab",
    EXPAND_ROW: "drawer.expand_row",
    SORT: "drawer.sort",
    PAGINATION: "drawer.pagination"
  },
  SETTINGS: {
    USERS: {
      USER_INVITED: "user_invited",
      USER_UPDATED: "user_updated"
    },
    POLICIES: {
      CI: {
        CREATE: "ci_policy.create",
        EDIT: "ci_policy.edit",
        REORDER: "ci_policy.reorder",
        STATUS: "ci_policy.status",
        DELETE: "ci_policy.delete",
        CANCEL: "ci_policy.cancel"
      },
      SCM: {
        CREATE: "scm_policy.create",
        EDIT: "scm_policy.edit",
        REORDER: "scm_policy.reorder",
        STATUS: "scm_policy.status",
        DELETE: "scm_policy.delete",
        CANCEL: "scm_policy.cancel"
      }
    },
    AUTH_KEYS: {
      API_KEYS: {
        COMMAND_GENERATE_KEY: "settings.api_key.command_generate_key"
      },
      DEPLOYMENT_KEYS: {
        COMMAND_GENERATE_KEY: "settings.deployment_key.command_generate_key"
      },
      SERVICE_KEYS: {
        COMMAND_GENERATE_KEY: "settings.service_key.command_generate_key"
      }
    }
  },
  ONBOARDING: {
    DISMISSED: "onboarding.dismissed",
    SPLASH_SCREEN: {
      GET_STARTED: "onboarding.get_started",
      DISPLAYED: "onboarding.displayed",
      SKIPPED: "onboarding.skipped"
    }
  },
  LABELS: {
    PATCH: "labels.patch",
    CREATE: "labels.create",
    UPDATE: "labels.update",
    DELETE: "labels.delete"
  },
  HELPFUL_FEEDBACK: {
    YES: "helpful_feedback.yes",
    NO: "helpful_feedback.no"
  },
  WORKFLOW: {
    CREATE: "workflows.create",
    EDIT: "workflows.edit",
    DELETE: "workflows.delete"
  },
  EVIDENCE: {
    DOWNLOAD: "evidence.download"
  }
}

export const ERROR_EVENTS = {
  APPLICATION: {
    CHECK_VERSION: "ERROR_CHECK_VERSION"
  },
  NETWORK: {
    ERROR: "ERROR_NETWORK",
    ERROR_RECEIVING_DATA_FROM_NETWORK: "ERROR_RECEIVING_DATA_FROM_NETWORK"
  },
  SIGN_IN: "ERROR_SIGN_IN_FUNCTION",
  SIGN_OUT: "ERROR_SIGN_OUT_FUNCTION",
  INTEGRATIONS: {
    INVALID_PARAMS: "INVALID_PARAMS",
    ERROR: "ERROR_INTEGRATIONS",
    REQUESTED: "REQUESTED_INTEGRATIONS",
    SUCCESS: "SUCCESS_INTEGRATIONS"
  },
  SCOPES: {
    ERROR_CREATE_SCOPE: "ERROR_CREATE_SCOPE",
    ERROR_UPDATE_SCOPE: "ERROR_UPDATE_SCOPE",
    ERROR_DELETE_SCOPE: "ERROR_DELETE_SCOPE"
  },
  AUTH: {
    MISSING_PARAM_IN_LINK: "ERROR_MISSING_PARAM",
    ACCEPT_INVITE: "ERROR_ACCEPT_INVITE",
    VERIFY_CODE_OOB: "ERROR_VERIFY_CODE_OOB"
  },
  LAYOUT: "ERROR_LAYOUT",
  ACTIONS: {
    DELETE_ENTITY_CONFIRM_MODAL: "DELETE_ENTITY_CONFIRM_MODAL"
  }
}

export const INTERNAL_EVENTS = {
  CLEAR_FILTERS_TABLE_TAB_TEMPLATE: "__clear_filters_table_tab_template__"
}

export const EVENTS = {
  ANALYTIC_EVENTS,
  ERROR_EVENTS
}
