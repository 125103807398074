export const NAMESPACE_DEFAULT_VALUE = "N/A"

export const MONTH_IN_SECONDS = 30 * 24 * 60 * 60
export const YEAR_IN_SECONDS = 365 * 24 * 60 * 60

export const RUNTIME_ID = "runtime"

export const FIXABILITY_VALUE_QUERY_STRING = "full,partial,has_fix"

export const FIXABLE_DATA_ID_ANALYTIC_FUNNEL_CARD = "fixable-funnel-card"
