import cn from "classnames"

import { Button, Flex, Text } from "@/atoms"

import styles from "./StatisticWidget.module.scss"

import type { StatisticWidgetProps } from "."

export const StatisticWidget = (props: StatisticWidgetProps) => {
  const { title, value, icon, onClick } = props
  const isClickable = !!onClick
  const Wrapper = isClickable ? Button : "div"

  return (
    <Wrapper className={cn(!isClickable && styles.container)} onClick={onClick}>
      <Flex align="center" justify="space-between">
        <Flex vertical justify="space-between">
          <Text className={styles.title} type="secondary">
            {title}
          </Text>
          <Text className={styles.value}>{value}</Text>
        </Flex>
        <Flex align="center" justify="center" className={styles.iconWrapper}>
          {icon}
        </Flex>
      </Flex>
    </Wrapper>
  )
}
