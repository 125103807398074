export const getRepositoryUrl = (
  sourceControl: SourceControl.Vendor,
  repositoryName: string
): string | undefined => {
  switch (sourceControl) {
    case "GitHub":
      return `https://github.com/${repositoryName}`
    case "GitLab":
      return `https://gitlab.com/${repositoryName}`
    case "Bitbucket":
      return `https://bitbucket.org/${repositoryName}`
    case "OnPrem":
      return undefined // Local repositories don't have external URLs
    default:
      return undefined
  }
}
