import { Tooltip } from "@/atoms"

import type { WrapperLabelPrimaryProps } from "."

export const WrapperLabelPrimary = (props: WrapperLabelPrimaryProps) => {
  const { children, title } = props

  if (!title) return children

  return (
    // 9999 is z-index for placeholder in antd table
    <Tooltip zIndex={10000} placement="right" title={title}>
      <span>{children}</span>
    </Tooltip>
  )
}
