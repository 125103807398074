import { useTranslation } from "@/hooks/useTranslation"
import { LabelTag } from "@/molecules/LabelTag"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"

import styles from "./MetaIssue.module.scss"

import type { Issue } from "@/domain/issue"

export const MetaIssue = ({ record }: { record: Issue }) => {
  const { t } = useTranslation()

  if (!record || !record.labels?.length) return null

  return (
    <div className={styles.container}>
      <OverviewDescriptions.Label>{`${t(
        "settings.labels.title"
      )}:`}</OverviewDescriptions.Label>
      <div className={styles.labels}>
        <LabelTag labels={record.labels} type="line" issueId={record.id} />
      </div>
    </div>
  )
}
