import { useEffect, useState } from "react"

import { isCodeIssueType } from "@/domain/issue"
import { copyHtmlToClipboard } from "@/helpers/clipboard.helper"
import { useLogger } from "@/hooks/useLogger"

import type { Type } from "@/domain/issue"

export const useCopyable = (
  containerId: string,
  issueType: Type | undefined,
  props: { onFindNode?: (node: HTMLElement) => void } = {}
) => {
  const [copied, setCopied] = useState(false)
  const { logger } = useLogger()

  useEffect(() => {
    try {
      if (!copied) return

      const node = document
        .getElementById(containerId)
        ?.cloneNode(true) as HTMLElement

      if (!node) return

      props.onFindNode?.(node)

      const el = node.querySelectorAll(
        '[data-element-issue-expandable-cell-view="fix-container"] > div:first-child'
      )

      let html = el[0]?.innerHTML || ""

      if (!!issueType && isCodeIssueType(issueType))
        html += el[1]?.innerHTML || ""

      copyHtmlToClipboard(html, logger)

      setCopied(false)
    } catch (err: any) {
      logger.error(err, {
        tags: { custom_error: "USE_COPYABLE_HOOK" }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied])

  const onCopy = () => setCopied(true)

  return onCopy
}
