import { useNavigate } from "react-router-dom"

import * as workflowApi from "@/api/useWorkflow"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useTranslation } from "@/hooks/useTranslation"
import * as selectors from "./useWorkflow.selector"

import type {
  ActionsOptionsWorkflowResponse,
  Workflow
} from "@/api/useWorkflow.types"
import type { Config, Params, Query, QueryClient } from "@/hooks/useHttp.types"
import type {
  VariablesUpdater,
  WorkflowEntitySelector
} from "./useWorkflow.types"

const onSettled =
  (queryClient: QueryClient) =>
  (_: unknown, __: unknown, variables?: unknown) =>
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        const key = queryKey[0]
        const resource = RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST
        const needle = (variables as { needle?: string })?.needle

        return key === resource || (!!needle && key === `${resource}${needle}`)
      }
    })

export const useCreateWorkflow = () =>
  workflowApi.useCreateWorkflow(undefined, undefined, (queryClient) => ({
    onSettled: onSettled(queryClient)
  }))

export const useUpdateWorkflow = () =>
  workflowApi.useUpdateWorkflow<VariablesUpdater>(
    undefined,
    undefined,
    (queryClient) => ({
      onMutate: async (variables) => {
        const predicate = ({ queryKey }: Query) => {
          if (
            typeof queryKey[0] === "string" &&
            queryKey[0].startsWith(RESOURCES_NAMES.SETTINGS.WORKFLOWS.LIST)
          )
            return true

          return false
        }

        await queryClient.cancelQueries({ predicate })

        const prevData = queryClient.getQueriesData({ predicate })

        const isSameId = (id: string) => variables.needle.includes(id)

        const processingData = (
          data: Workflow | Workflow[],
          variables: VariablesUpdater
        ) => {
          if (Array.isArray(data)) {
            return data.map((item) => {
              if (isSameId(item.id)) {
                return variables.data.workflow
              }

              return item
            })
          }

          if (isSameId(data?.id)) {
            return variables.data.workflow
          }

          return data
        }

        prevData
          .filter(
            (result) =>
              !!(result[1] as KosmosApi.Response<Workflow | Workflow[]>)?.data
          )
          .map((result) => {
            const data = (
              result[1] as KosmosApi.Response<Workflow | Workflow[]>
            ).data

            return [
              result[0],
              {
                ...(result[1] || {}),
                data: processingData(data, variables)
              }
            ] as const
          })
          .forEach((result) => {
            queryClient.setQueryData(result[0], result[1])
          })

        return { prevData }
      },
      onError: (_err, _variables, context: any) => {
        context?.prevData?.forEach((result: [string[], unknown[]]) => {
          queryClient.setQueryData(result[0], result[1])
        })
      },
      onSettled: onSettled(queryClient)
    })
  )

export const useWorkflow = (
  params?: Params,
  config?: Config<ReturnType<typeof selectors.getWorkflow>>
) => {
  const context = workflowApi.useGetWorkflow(params, {
    select: selectors.getWorkflow,
    ...config
  })

  const updater = useUpdateWorkflow()
  const creator = useCreateWorkflow()

  const deletion = workflowApi.useDeleteWorkflow()

  const saving = creator.isPending || updater.isPending
  const loading = context.isLoading || saving || deletion.isPending

  const navigate = useNavigate()

  const statusChange = (enabled: boolean, record: WorkflowEntitySelector) => {
    updater.mutateAsync({
      needle: `/${record.id}`,
      data: { workflow: { ...record, enabled } }
    })
  }

  const { t } = useTranslation()
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE

  const createDuplicate = (record: WorkflowEntitySelector) => {
    const name = `${record.name} (Copy)`

    navigate(`/${ROOT}/${WORKFLOWS}/new`, {
      state: { duplicateData: { ...record, name } }
    })
  }

  const onEdit = (record: WorkflowEntitySelector) =>
    navigate(`/${ROOT}/${WORKFLOWS}/${record.id}`)

  const actions = {
    t,
    statusChange,
    createDuplicate,
    onEdit,
    creator,
    updater,
    deletion,
    navigate
  }

  return [context, { loading, saving, actions }] as const
}

export const useTriggerOptionsWorkflow = () => {
  const context = workflowApi.useTriggerOptionsWorkflow(undefined, {
    select: selectors.getTriggerOptionsWorkflow
  })

  return context
}

export const useActionsWorkflow = (params: Params) =>
  workflowApi.useActionsWorkflow(params, {
    select: selectors.getActionsWorkflow
  })

export const useActionsOptionsWorkflow = <
  T = ActionsOptionsWorkflowResponse,
  S = T
>(
  params: Params,
  config?: Config<T, S>
) => workflowApi.useActionsOptionsWorkflow(params, config)
