import dayjs from "dayjs"
import { memo, useMemo } from "react"

import { Column } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { Widget } from "@/molecules/Widget"

import type { useActionsOverTimeStatisticsWorkflow } from "@/api/useWorkflow"
import type { ActionsStatistics } from "@/api/useWorkflow.types"

export const ActionsOverTimeWidget: React.FC<{
  context: ReturnType<typeof useActionsOverTimeStatisticsWorkflow>
}> = memo(
  ({ context }) => {
    const { t } = useTranslation("translation", {
      keyPrefix: "settings.automatedActions.table.widgets.overtime"
    })
    const { intlNumber } = useNumberConvertor()

    const DATE_FORMAT = "MMM D"

    const config = useMemo(() => {
      let max = 0

      return {
        data:
          context.response?.data?.flatMap((item) =>
            ["webhook"].map((type) => {
              const value = item.actions[type as keyof ActionsStatistics] || 0

              if (value > max) max = value

              return {
                type,
                value,
                valueTooltip: intlNumber(value),
                year: `${dayjs(item.weekStart).format(DATE_FORMAT)} - ${dayjs(item.weekEnd).format(DATE_FORMAT)}`
              }
            })
          ) || [],
        height: 152,
        maxColumnWidth: 12,
        legend: false,
        isStack: true,
        xField: "year",
        yField: "value",
        yAxis: { max: max === 0 ? undefined : max + Math.round(max * 0.1) },
        offsetY: 100,
        seriesField: "type",
        interactions: [
          {
            type: "active-region",
            enable: false
          }
        ],
        responsive: true
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.response]) as any

    return (
      <Widget
        loading={context.isLoading}
        title={t("title")}
        popover={{ content: t("tooltip") }}
      >
        <Column
          tooltip={{
            customContent: (_, data) => {
              const { valueTooltip } = data[0]?.data || {}

              return (
                <span
                  style={{
                    textTransform: "capitalize",
                    padding: "10px",
                    lineHeight: "20px"
                  }}
                >{`${t("tooltipGraph")} - ${valueTooltip}`}</span>
              )
            }
          }}
          {...config}
        />
      </Widget>
    )
  },
  (prev, curr) => prev.context.response === curr.context.response
)
