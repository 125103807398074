import { useEffect } from "react"

import { Flex, Form, Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ReadOutlined, ThunderboltOutlined } from "@/icons"
import { LinkButton } from "@/molecules/LinkButton"
import {
  useActionsOptionsWorkflow,
  useActionsWorkflow
} from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import { SectionFormTemplate } from "@/pages/SettingsPage/components/templates/SectionFormTemplate"
import { WebhookModalAutomationActionForm } from "./WebhookModalAutomationActionForm"

import styles from "./ActionsSectionForm.module.scss"

import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import type { ActionsSectionFormProps } from "."

export const ActionsSectionForm = (props: ActionsSectionFormProps) => {
  const { disabled, triggerCategory, triggerType, form } = props
  const { response: actions } = useActionsWorkflow({ type: "webhook" })
  const { response: optionsResponse } = useActionsOptionsWorkflow(
    { trigger_type: triggerType, trigger_category: triggerCategory },
    { enabled: !disabled }
  )
  const options = optionsResponse?.data || []
  const { t } = useTranslation()
  const keyPrefix = "settings.automatedActions.form"
  const rules = [{ required: true, message: t("error.requiredField") }]
  const selectWebhookEndpointLabel = t(
    `${keyPrefix}.fields.selectWebhookEndpoint.label`
  )

  const actionTypeFieldName = "actions.action"
  const actionType = Form.useWatch(actionTypeFieldName, form)
  const webhookValue = "webhook"

  const idLinkWebhook = "link.action.form.webhooks"

  useEffect(() => {
    const isActionTypeTouched = form.isFieldTouched(actionTypeFieldName)

    if (isActionTypeTouched && actionType === webhookValue) {
      document
        .getElementById(idLinkWebhook)
        ?.scrollIntoView({ behavior: "smooth" })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType])

  return (
    <SectionFormTemplate
      id="actions"
      title={t(`${keyPrefix}.sections.actions`)}
      Icon={ThunderboltOutlined}
    >
      <Form.Item
        required={false}
        name={actionTypeFieldName}
        label={t(`${keyPrefix}.fields.action.label`)}
        rules={rules}
      >
        <Select
          placeholder={t(`${keyPrefix}.fields.action.placeholder`)}
          disabled={disabled}
          options={options.map(({ key, displayName, disabled }: any) => ({
            disabled,
            label: displayName,
            value: key
          }))}
        />
      </Form.Item>
      {actionType === webhookValue && (
        <div className={styles.webhookFields}>
          <Form.Item
            required={false}
            name="actions.endpoint"
            className={styles.endpointField}
            label={
              <Flex align="center" justify="space-between">
                <span>{selectWebhookEndpointLabel}</span>
                <WebhookModalAutomationActionForm />
              </Flex>
            }
            rules={rules}
          >
            <Select
              placeholder={selectWebhookEndpointLabel}
              disabled={disabled}
              options={actions}
            />
          </Form.Item>
          <LinkButton
            id={idLinkWebhook}
            size="small"
            type="link"
            icon={<ReadOutlined />}
            href={`${EXTERNAL_ROUTES.API_DOCS}/governance/webhook-payload-examples`}
            target="_blank"
          >
            {t(`${keyPrefix}.fields.action.link`)}
          </LinkButton>
        </div>
      )}
    </SectionFormTemplate>
  )
}
