import type { Workflow } from "@/api/useWorkflow.types"
import { getStepsValues } from "@/helpers/form.helper"

export const ALL_SCOPE = "all"
export const SPECIFIC_SCOPE = "specific"

export const getInitialValues = (data: Workflow | undefined) => {
  const {
    name,
    description,
    trigger,
    actions,
    resourceConditions = [],
    filterConditions = []
  } = data || {}
  const { category, type: triggerType } = trigger || {}
  const action = actions?.[0]
  const { values, type: actionType } = action || {}
  const isResourceConditions = !!resourceConditions.length

  return {
    "details.name": name,
    "details.description": description,
    "trigger.category": category,
    "trigger.type": triggerType,
    "actions.action": actionType,
    "actions.endpoint": values?.webhookId,
    conditions: isResourceConditions
      ? resourceConditions
      : [{ filters: [{}], includeExclude: "Include" }],
    currentConditions: getStepsValues(filterConditions),
    currentScope: isResourceConditions ? SPECIFIC_SCOPE : ALL_SCOPE
  }
}

export const getResetFieldsValuesByTriggerKey = (
  key: string,
  state: Record<string, Record<string, unknown>>
) => {
  const defaultState = {
    currentConditions: getStepsValues([]),
    "actions.action": undefined,
    "actions.endpoint": undefined
  }
  const prevState = state[key]
  const nextState = prevState || defaultState

  return {
    currentConditions: nextState.currentConditions,
    "actions.action": nextState["actions.action"],
    "actions.endpoint": nextState["actions.endpoint"]
  }
}
