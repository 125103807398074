import { useNavigate } from "react-router-dom"

import { Button, Empty, Link } from "@/atoms"
import { NAMES_ROUTES, VALUES_PARAMS } from "@/const/routes.constants"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useQueryString } from "@/hooks/useQueryString"
import { useTranslation } from "@/hooks/useTranslation"
import { FolderViewOutlined } from "@/icons"
import { CreateActionEmptyTable } from "@/molecules/EmptyTable"
import { Heading } from "@/molecules/Heading"
import {
  useDeleteScope,
  useScopes
} from "@/pages/SettingsPage/application/scopes/useScopes"
import { TabTemplate } from "@/templates/TabTemplate"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import { Filter } from "../Filter"
import { getColumnsTable } from "./getColumnsTable"

import { Protected } from "@/molecules/Protected"
import styles from "./ScopesTab.module.scss"

export const ScopesTab = () => {
  const { t } = useTranslation()
  const { t: tScopes } = useTranslation("translation", {
    keyPrefix: "settings.scopes"
  })
  const navigate = useNavigate()
  const params = useQueryString(["orderBy", "ascending"])
  const { response, isLoading } = useScopes(params)
  const { deleteScope } = useDeleteScope(params)
  const { data, metadata } = response || {}
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { SETTINGS } = NAMES_ROUTES
  const { ROOT, SCOPES } = SETTINGS
  const rootUrl = `/${ROOT}/${SCOPES}`
  const linkToForm = `${rootUrl}/${VALUES_PARAMS.NEW_ENTITY}`

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={tScopes("title")}
          popover={{
            className: styles.popover,
            content: (
              <div className={styles.popoverContent}>
                <span>{tScopes("descriptions")}</span>
              </div>
            )
          }}
        />
      }
      button={
        <Link to={linkToForm}>
          <Protected
            permission={{ resource: "settings.scopes", action: "write" }}
            shouldDisable
          >
            <Button
              className={styles.button}
              icon={<FolderViewOutlined />}
              type="primary"
            >
              {tScopes("createScope")}
            </Button>
          </Protected>
        </Link>
      }
    >
      <TabTemplate
        tabPosition="left"
        loading={isLoading}
        columns={getColumnsTable(orderByProps, {
          t,
          deleteScope: deleteScope,
          duplicateScope: (duplicateScopeId: string) => {
            navigate(`${linkToForm}/${duplicateScopeId}`)
          }
        })}
        dataSource={data}
        metadata={metadata}
        locale={{
          customEmptyComponent: (
            <>
              <Protected
                permission={{ resource: "settings.scopes", action: "write" }}
                fallback={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className={styles.empty}>
                        <span>{tScopes("noScopesUser")}</span>
                        <span>{tScopes("noScopesUserMsg")}</span>
                      </div>
                    }
                  />
                }
              >
                <CreateActionEmptyTable tKey="scope" to={linkToForm} />
              </Protected>
            </>
          )
        }}
        SearchComponent={<Filter />}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${rootUrl}/${record.id}`)
          }
        })}
        onChange={(_pagination, _filters, sorter: any) => {
          onSorterChange(sorter)
        }}
      />
    </VerticalTabTemplate>
  )
}
