import { useGetIntegrationsList } from "@/api/useIntegrations"

import type { JiraMetadata } from "@/api/useIntegrations.types"

export const useJiraIntegration = () => {
  const context = useGetIntegrationsList()
  const { response: integrationResponse } = context
  const { metadata = {} } = integrationResponse || {}
  const hasIntegration = !!(metadata as JiraMetadata)?.integrationsStatus?.jira

  return { hasIntegration }
}
