import { Button, Empty, Flex, Link, Text } from "@/atoms"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { CloudUploadOutlined } from "@/icons"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { TabTemplate } from "@/templates/TabTemplate"
import { useDeployments } from "../../../application/useDeployments"
import {
  checkNoDeployments,
  useFiltersDeployments
} from "../../../application/useFiltersDeployments"
import { DeployButton } from "../../molecules/DeployButton"
import { getColumnsTable } from "./getColumnsTable"
import { getFilters } from "./getFilters"

import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { LinkButton } from "@/molecules/LinkButton"
import styles from "./DeploymentsTab.module.scss"

export const DeploymentsTab = () => {
  const { t } = useTranslation()
  const filters = getFilters(t)
  const { response, isLoading } = useDeployments(filters)
  const { response: options, isLoading: isLoadingFilters } =
    useFiltersDeployments()
  const { data = [], metadata } = response || {}
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF || isLoadingFilters
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const twoLoaders = isLoading || isLoadingFilters
  const dataset = twoLoaders ? undefined : data
  const showCustomEmptyText = checkNoDeployments(options) && !twoLoaders
  const dataSource = showCustomEmptyText ? [] : dataset

  useTooltip(data)

  return (
    <>
      <TabTemplate
        headerComponent={
          <Flex>
            <Flex vertical flex={1}>
              <Text type="secondary">
                Deploy and manage Kodem sensors to gain visibility into your
                deployed environments,
              </Text>
              <Text type="secondary">
                enhancing issue prioritization and runtime insights.
                <LinkButton
                  size="small"
                  type="link"
                  href={`${EXTERNAL_ROUTES.API_DOCS}/change-log/runtime-sensor-releases`}
                  target="_blank"
                >
                  {t(`deployments.viewChangeLog`)}
                </LinkButton>
              </Text>
            </Flex>
            <DeployButton name="Deploy" Icon={<CloudUploadOutlined />} />
          </Flex>
        }
        columns={getColumnsTable(orderByProps, {
          t
        })}
        loading={twoLoaders}
        metadata={metadata}
        dataSource={dataSource}
        locale={{
          emptyText: showCustomEmptyText && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div className={styles.emptyWrapper}>
                  <span>{t("deployments.noDeployments.line1")}</span>
                  <Link to="/deployments/new-deployment">
                    <Button type="link">
                      {t("deployments.noDeployments.line2")}
                    </Button>
                  </Link>
                </div>
              }
            />
          )
        }}
        onChange={(_pagination, _filters, sorter: any) => {
          onSorterChange(sorter)
        }}
        SearchComponent={
          <Filter.Provider isLoading={filterDisabled}>
            <Filter.Template
              isLoading={filterDisabled}
              aside={
                <Filter.DatePicker
                  placeholder="Last Upgrade"
                  isLoading={filterDisabled}
                />
              }
              search={
                filterDisabled ? (
                  <InputSmartSearchLoading />
                ) : (
                  <SmartSearch data={options} filters={filters} />
                )
              }
            >
              <Filter.Checkbox
                keyFilter="environments"
                order="none"
                items={options?.environments}
                placeHolderDefaultValue="Environments"
                isLoading={filterDisabled}
              />
              <Filter.Checkbox
                keyFilter="statuses"
                order="none"
                items={options?.["statuses"]}
                placeHolderDefaultValue="Status"
                isLoading={filterDisabled}
              />
            </Filter.Template>
          </Filter.Provider>
        }
      />
    </>
  )
}
