import { useSearchParams } from "react-router-dom"

import { FIXABILITY_VALUE_QUERY_STRING } from "@/const/default-values"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { getCards } from "./helpers"

import type { GetCardProps } from "."

export const useFunnelTriage = (props?: GetCardProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const kortexInstalled = useFeatureFlags("kortexInstalled")

  const cards = getCards(t, searchParams, {
    runtime: kortexInstalled,
    ...props
  })

  const context = useParallelQueries<{ data: number }>(
    cards.map(({ params, enabled }) => ({
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params,
      enabled
    }))
  )

  const contextFixable = useParallelQueries<{ data: number }>(
    cards.map(({ params, enabled }) => ({
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params: { fixability: FIXABILITY_VALUE_QUERY_STRING, ...params },
      enabled
    }))
  )

  const { isLoading: isLoadingIssues, isPending: isPendingIssues } =
    context?.[1] || {}
  const {
    isLoading: isLoadingFixableIssues,
    isPending: isPendingFixableIssues
  } = contextFixable?.[1] || {}

  const isLoading =
    isLoadingIssues ||
    isPendingIssues ||
    isLoadingFixableIssues ||
    isPendingFixableIssues

  return [
    context,
    cards,
    kortexInstalled,
    contextFixable,
    { isLoading }
  ] as const
}
