import { useGetDeploymentKey } from "@/api/useDeployments"
import { Tooltip } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { PlusCircleOutlined } from "@/icons"
import { InlineInputButton } from "../InlineInputButton"

import { Protected } from "@/molecules/Protected"
import type { DeploymentKeyButtonProps } from "."

export const DeploymentKeyButton = ({
  name,
  inputRef,
  onChange,
  onError
}: DeploymentKeyButtonProps) => {
  const { t } = useTranslation()
  const { logger } = useServices()
  const { mutateAsync, isPending: isLoading } = useGetDeploymentKey()
  const tooltipKey = "generateNewKey"

  return (
    <div data-id="deployment-key-button">
      <Tooltip title={t(`deployments.${tooltipKey}`)}>
        <div>
          <Protected
            permission={{
              resource: "settings.deployment-keys",
              action: "write"
            }}
            shouldDisable
          >
            <InlineInputButton
              loading={isLoading}
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                try {
                  if (isLoading) return

                  const { data } = await mutateAsync({} as any, {})
                  const { plain } = data

                  if (plain && inputRef?.current) {
                    inputRef.current.innerText = plain
                  }

                  if (name) {
                    onChange?.({ name, value: plain })
                  }
                } catch (error) {
                  logger.error(error as Error, {
                    tag: { custom_event: "DeploymentKeyButtonError" }
                  })
                  onError?.()
                }
              }}
            />
          </Protected>
        </div>
      </Tooltip>
    </div>
  )
}
