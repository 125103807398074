import { Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Ai } from "@/icons"

export const PoweredByAi = () => {
  const { t } = useTranslation()

  return (
    <Flex gap={4} align="center">
      {t("general.poweredBy")} <Ai style={{ fontSize: "18px" }} />
    </Flex>
  )
}
