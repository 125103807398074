import cn from "classnames"

import { Card, Empty } from "@/atoms"
import { Heading } from "@/molecules/Heading"
import { LoaderWidget } from "./components/LoaderWidget"

import styles from "./Widget.module.scss"

import type { WidgetComponent } from "."

export const Widget: WidgetComponent = (props) => {
  const { children, title, className, popover, loading } = props

  return (
    <Card
      className={cn(styles.container, loading && styles.loading, className)}
    >
      <Heading
        level={6}
        title={title}
        popover={popover}
        classes={{ container: styles.titleContainer, title: styles.title }}
      />
      {!loading && children && <div className={styles.body}>{children}</div>}
      {!loading && !children && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {loading && <LoaderWidget />}
    </Card>
  )
}
