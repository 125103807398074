import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { CopyableKeyIdCell } from "../../molecules/CopyableKeyIdCell/CopyableKeyIdCell"
import { ScopeActionsCell } from "../../molecules/ScopeActionsCell"

import type { Scope } from "@/api/useScopes.types"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">,
  {
    t,
    deleteScope,
    duplicateScope
  }: {
    t: TFunction
    deleteScope: (scope: Scope) => void
    duplicateScope: (duplicateScopeId: string) => void
  }
) => [
  {
    title: "Scope Name",
    width: "30%",
    render: CellTable,
    ...getDefaultColumnParams("name", sorter)
  },
  {
    title: "Description",
    width: "100%",
    render: CellTable,
    ...getDefaultColumnParams("description", sorter)
  },
  {
    title: "ID",
    width: "15%",
    render: CopyableKeyIdCell,
    ...getDefaultColumnParams("id", sorter)
  },
  {
    title: "Creation Date",
    width: 130,
    render: CellTable,
    ...getDefaultColumnParams("createdAt", sorter)
  },
  {
    width: 40,
    render: ScopeActionsCell(t, {
      deleteScope,
      duplicateScope
    }),
    ...getDefaultColumnParams("")
  }
]
