import { Col, Row } from "@/atoms"
import { PageTemplate } from "@/templates/PageTemplate"
import { HeaderDashboardPage } from "./components/molecules/HeaderDashboardPage/HeaderDashboardPage"
import { ExploitableIssuesOverTimeWidget } from "./components/organisms/ExploitableIssuesOverTimeWidget"
import { FunnelDashboard } from "./components/organisms/FunnelDashboard"
import { InventorySnapshotWidget } from "./components/organisms/InventorySnapshotWidget"
import { IssuesBySeverityWidget } from "./components/organisms/IssuesBySeverityWidget"
import PostureScoreWidget from "./components/organisms/PostureScoreWidget"
import { TopIssuesWidget } from "./components/organisms/TopIssuesWidget"
import { VulnerableFunctionInRuntimeWidget } from "./components/organisms/VulnerableFunctionInRuntimeWidget"
import useDashboard from "./hooks/useDashboard"

import styles from "./DashboardPage.module.scss"

export const DashboardPage = () => {
  const {
    runtime,
    postureProps,
    inventorySnapshotProps,
    funnelProps,
    exploitableProps
  } = useDashboard()

  return (
    <PageTemplate header={<HeaderDashboardPage />}>
      <Row gutter={16} className={styles.firstSection}>
        <Col lg={13} sm={24}>
          <PostureScoreWidget {...postureProps} />
        </Col>
        <Col lg={11} sm={24}>
          <InventorySnapshotWidget {...inventorySnapshotProps} />
        </Col>
      </Row>
      <Row gutter={16} className={styles.secondSection}>
        <Col flex="auto">
          <FunnelDashboard {...funnelProps} />
        </Col>
        <Col flex="0 0 342px">
          <VulnerableFunctionInRuntimeWidget />
        </Col>
      </Row>
      <Row gutter={16} className={styles.thirdSection}>
        <Col lg={13} sm={24}>
          <ExploitableIssuesOverTimeWidget
            runtime={runtime}
            {...exploitableProps}
          />
        </Col>
        <Col lg={11} sm={24}>
          <IssuesBySeverityWidget runtime={runtime} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <TopIssuesWidget runtime={runtime} />
        </Col>
      </Row>
    </PageTemplate>
  )
}
