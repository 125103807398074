import { useJiraIntegration } from "@/organisms/Drawers/components/TriageDrawer/hooks/useJiraIntegration"
import AttackChains from "../AttackSimulation/AttackChains"

import type { TabComponentProps } from "@/organisms/Drawers/components/TabsDrawerTemplate"

export const AttackSimulationTab = (props: TabComponentProps) => {
  const { hasIntegration } = useJiraIntegration()

  return <AttackChains hasIntegration={hasIntegration} {...props} />
}
