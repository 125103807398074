import { useEffect, useState } from "react"

import { Button, message } from "@/atoms"
import { shallowEqualObjects } from "@/helpers/utils"
import { usePrevious } from "@/hooks/usePrevious"
import { useTranslation } from "@/hooks/useTranslation"
import { IconDismissedTag } from "@/molecules/DismissedTag"
import { LabelModal } from "@/organisms/LabelModal"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import { useDismissIssues } from "@/pages/TriagePage/application/useTriage"
import { useDismissModal } from "@/pages/TriagePage/hooks/useDismissModal"
import { DismissModal } from "../DismissModal"
import {
  getInitialState,
  getIssuesForDismissByProject,
  getIssuesForReopenByProject,
  getStateFromSelectedIssues
} from "./helpers"

import styles from "./IssueActions.module.scss"

import type { Issue } from "@/domain/issue"
import { Permission } from "@/domain/user"
import { Protected } from "@/molecules/Protected"
import type { IssueActionsComponent } from "."

export const IssueActions: IssueActionsComponent = (props) => {
  const { t } = useTranslation()
  const { params, componentName, showSelctedProjects } = props
  const [selectedState, dispatch] = useSelection<Issue>()
  const [messageApi, messageContextHolder] = message.useMessage()
  const [{ dismissIssues, reopenIssues }] = useDismissIssues(
    params,
    { messageApi, showLoadingMessage: props.showLoadingMessage, componentName },
    props.getOptionsUseDismissIssues
  )
  const writePermission: Permission = { resource: "issues", action: "write" }
  const [state, setState] = useState(getInitialState())
  const { open, openModal, onOk, onCancel } = useDismissModal({
    onOk: (attr?: { reason?: string }) =>
      dismissIssues(getIssuesForDismissByProject(selectedState.items), attr)
  })

  useEffect(() => {
    const nextState = getStateFromSelectedIssues(selectedState)

    if (nextState) setState(nextState)
  }, [selectedState])

  const prevParams = usePrevious(params)
  const onFinishLabels = () => dispatch(actions.unselectAll())

  useEffect(() => {
    if (shallowEqualObjects(prevParams, params)) return

    dispatch(actions.setFlags({ unselect: true }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevParams, params])

  const { issuesCount, issuesTotal, reOpen, dismiss, projectsCount } = state

  let issuesSelectedText = `${t("triage.issue_selected", { count: issuesCount, total: issuesTotal })}`
  if (showSelctedProjects) {
    issuesSelectedText += ` ${t("triage.from_projects", { count: projectsCount })}`
  }

  return (
    <div className={styles.container}>
      {!!issuesCount && (
        <>
          <span>{issuesSelectedText}</span>
          <div className={styles.wrapButtons}>
            {dismiss && (
              <Protected permission={writePermission} shouldDisable>
                <Button
                  disabled={selectedState.updating}
                  size="small"
                  type="secondary"
                  onClick={openModal}
                  icon={<IconDismissedTag />}
                >
                  {t("general.dismiss")}
                </Button>
              </Protected>
            )}
            {reOpen && (
              <Protected permission={writePermission} shouldDisable>
                <Button
                  disabled={selectedState.updating}
                  size="small"
                  type="secondary"
                  icon={<IconDismissedTag />}
                  onClick={() => {
                    reopenIssues(
                      getIssuesForReopenByProject(selectedState.items)
                    )
                  }}
                >
                  {t("general.reopen")}
                </Button>
              </Protected>
            )}
          </div>
        </>
      )}
      <DismissModal
        count={issuesCount}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
      />
      {!!issuesCount && (
        <LabelModal
          btnType="secondary"
          items={selectedState.items}
          onFinish={onFinishLabels}
          placement="tab"
          component={componentName || "issue-actions-component"}
        />
      )}
      {messageContextHolder}
    </div>
  )
}
