import { Card, Popover, PoweredByAi, Text } from "@/atoms"
import { getKeyEvidenceList } from "@/domain/runtime-evidence"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { List } from "@/molecules/List"
import cn from "classnames"

import type { RuntimeEvidenceTabProps } from "../../RuntimeEvidenceTab.types"

import styles from "../../RuntimeEvidenceTab.module.scss"

export const KeyEvidenceCard = (props: RuntimeEvidenceTabProps) => {
  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })
  const { record } = props
  const language = record?.language
  const runtimeData = record?.runtimeData
  const keyEvidence = runtimeData?.keyEvidence

  if (!language || !runtimeData || !keyEvidence) return null

  const { translationKey: displayEvidenceKey, keyEvidenceList } =
    getKeyEvidenceList(keyEvidence, language)

  if (!displayEvidenceKey) return null

  return (
    <Card className={cn("embedded-card-ks", styles.content)}>
      <div className={styles.poweredBy}>
        <Heading
          level={5}
          title={tRuntimeEvidence(displayEvidenceKey, {
            count: keyEvidenceList?.length
          })}
        />
        <Popover
          content={tRuntimeEvidence("aiContentPopover")}
          overlayClassName={styles.aiPopover}
        >
          <div>
            <PoweredByAi />
          </div>
        </Popover>
      </div>
      <List
        limit={3}
        items={keyEvidenceList}
        classes={{
          list: styles.functionsOrClassesListEntity,
          button: styles.functionsOrClassesListButton
        }}
        render={(name) => (
          <Text code className={styles.secondaryTextContent}>
            &#8226; {name}
          </Text>
        )}
      />
    </Card>
  )
}
