import cn from "classnames"

import { Badge, Link } from "@/atoms"
import { FeatureStatusTag } from "@/molecules/FeatureStatusTag"
import { WrapperLabelPrimary } from "./WrapperLabelPrimary"

import styles from "./LabelPrimaryNavigation.module.scss"

import type { LabelPrimaryNavigationProps } from "."

export const LabelPrimaryNavigation = ({
  label,
  count,
  subMenu,
  to,
  tag,
  tooltip
}: LabelPrimaryNavigationProps) => {
  const Wrapper = to ? Link : "div"

  return (
    <WrapperLabelPrimary title={tooltip}>
      <Wrapper className={styles.container} to={to}>
        <span className={styles.label}>{label}</span>
        {!!tag && <FeatureStatusTag className={styles.tag} type={tag} />}
        {!!count && (
          <Badge
            className={cn(styles.count, subMenu && styles.subMenuCount)}
            count={count}
          />
        )}
      </Wrapper>
    </WrapperLabelPrimary>
  )
}
