import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { SingleImageResourcePopover } from "@/molecules/Resources"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useImageEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import type { EntityRecord } from "@/organisms/Drawers/applications/common"
import { ImageIcon } from "../../../../../../icons/Images"

export const TitleImageDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const [context] = useImageEntity(props.record?.id)
  const { response, isLoading } = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)
  if (isLoading || !data?.length) return <SkeletonTitle />

  const record = data[0]
  const { displayName, tag, fullDisplayName } = record
  return (
    <TitleDrawer
      icon={<ImageIcon imageSource={record.imageSource} />}
      title={displayName}
      subTitle={`Tag: ${tag}`}
      aside={<CopyLinkButton link={link} />}
      popover={
        <SingleImageResourcePopover name={fullDisplayName} {...record} />
      }
    />
  )
}
