export const getStepsValues = (
  steps: Record<string, unknown>[] | undefined
) => {
  const result = (steps || []).map((values) =>
    Object.keys(values).map((key) => ({
      key,
      value: key,
      selectedValue: values[key]
    }))
  )

  result.push([])

  if (result.length === 1) result.push([])

  return result
}
