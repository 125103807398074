import { lazy, Suspense } from "react"

import { Card, Skeleton } from "@/atoms"

import type { AttackChainsComponent } from "."

const AttackSimulationList = lazy(
  () => import("./components/AttackSimulationList")
)

const AttackChains: AttackChainsComponent = (props) => (
  <Suspense
    fallback={
      <Card>
        <Skeleton active />
      </Card>
    }
  >
    <AttackSimulationList {...props} />
  </Suspense>
)

export default AttackChains
