import { Button, Modal, message } from "@/atoms"
import { useModal } from "@/hooks/useModal"
import { useTranslation } from "@/hooks/useTranslation"
import { Webhook } from "@/icons"
import { FormWebhook } from "@/pages/IntegrationsPage/components/organisms/WebhookConfigurationTab"
import { ConnectIntegrationFooter } from "@/pages/IntegrationsPage/components/pages/ConnectIntegrationPage/ConnectIntegrationFooter"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"

import styles from "./ActionsSectionForm.module.scss"

export const WebhookModalAutomationActionForm = () => {
  const { visible, show, hide } = useModal()
  const [messageApi, messageContextHolder] = message.useMessage()
  const { onFinish, createOrUpdateWebhook, ...formProps } = useWebhookAPI({
    messageApi
  })
  const { t } = useTranslation()
  const text = t("integrations.webhook.form.buttons.add")

  return (
    <>
      <Button
        className={styles.button}
        icon={<Webhook />}
        type="link"
        onClick={show}
      >
        {text}
      </Button>
      <Modal
        title={text}
        open={visible}
        okText={text}
        footer={
          <ConnectIntegrationFooter
            okText="Add Endpoint"
            isLoading={formProps.isLoading}
            okProps={{ form: "webhook-form", htmlType: "submit" }}
            onCancel={hide}
          />
        }
        onCancel={hide}
      >
        <FormWebhook
          onFinish={async (values) => {
            await createOrUpdateWebhook(values)
            hide()
          }}
          {...formProps}
        />
      </Modal>
      {messageContextHolder}
    </>
  )
}
