import cn from "classnames"

import { sanitizeString } from "@/helpers/string.helpers"
import showdown from "showdown"
import { MarkdownProps } from "."

import { useMemo, useRef } from "react"
import styles from "./Markdown.module.scss"

export const Markdown = (props: MarkdownProps) => {
  const { content, className } = props
  // Store converter instance
  const converter = useRef<showdown.Converter>(
    new showdown.Converter({
      requireSpaceBeforeHeadingText: true,
      simpleLineBreaks: true,
      smartIndentationFix: true,
      omitExtraWLInCodeBlocks: true,
      strikethrough: true,
      tables: true,
      tasklists: true,
      ghMentions: true,
      encodeEmails: true,
      openLinksInNewWindow: true
    })
  )

  const htmlContent = useMemo(() => {
    return sanitizeString(converter.current.makeHtml(content), {
      allowCode: true,
      allowTags: [
        "p",
        "code",
        "li",
        "ul",
        "h1",
        "h2",
        "h3",
        "h4",
        "a",
        "ol",
        "table",
        "tr",
        "td",
        "th",
        "img",
        "blockquote",
        "pre",
        "br"
      ]
    })
  }, [content])

  return (
    <span
      className={cn(styles.githubMarkdown, className)}
      dangerouslySetInnerHTML={{
        __html: htmlContent
      }}
    />
  )
}
