import { Collapse, Link, Paragraph, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FlagOutlined } from "@/icons"
import { ContentItemWebhook } from "./components/ContentItemWebhook"
import { LabelItemWebhook } from "./components/LabelItemWebhook"

import styles from "./WebhookConfigurationTab.module.scss"

import { NAMES_ROUTES } from "@/const/routes.constants"
import { InformationAlert } from "@/molecules/InformationAlert"
import type { WebhookConfigurationTabProps } from "."

export const WebhookConfigurationTab = (
  props: WebhookConfigurationTabProps
) => {
  const installations = props.configuration?.installations || []
  const noWebhooks = !installations.length
  const { t: tGeneral } = useTranslation("translation")
  const { t: tWebhook } = useTranslation("translation", {
    keyPrefix: "integrations.webhook.configuration"
  })
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE
  const workflowsUrl = `/${ROOT}/${WORKFLOWS}`

  return (
    <div>
      {!noWebhooks && (
        <InformationAlert
          className={styles.marginItem}
          icon={<FlagOutlined />}
          message={tWebhook("completeTitle")}
          description={
            <span className={styles.description}>
              {tWebhook("configureFirst")}
              <Link to={workflowsUrl}>
                {tGeneral("general.workflow", { count: 1 })}
              </Link>
              {tWebhook("configureSecond")}
            </span>
          }
        />
      )}
      <Paragraph className={styles.title}>{tWebhook("title")}</Paragraph>
      {noWebhooks && <Text>{tWebhook("noData")}</Text>}
      {!noWebhooks &&
        installations.map((values) => (
          <Collapse
            key={values.id}
            className={styles.collapse}
            items={[
              {
                key: `${values.id}`,
                label: <LabelItemWebhook values={values} />,
                children: (
                  <ContentItemWebhook initialValues={values} {...props} />
                )
              }
            ]}
          />
        ))}
    </div>
  )
}
