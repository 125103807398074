import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { StatusSwitcherCell } from "@/molecules/StatusSwitcherCell"
import { ActionsCellScmTab } from "@/pages/SettingsPage/components/molecules/ActionsCellScmTab"
import { ScopeCell } from "@/pages/SettingsPage/components/molecules/ScopeCell"

import type { Policy } from "@/api/usePolicies.types"
import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (
  t: TFunction,
  {
    isUpdating,
    onStatusChange,
    onEdit,
    onDelete
  }: {
    isUpdating: boolean
    onStatusChange: (checked: boolean, policy: Policy) => void
    onEdit: (policy: Policy) => void
    onDelete: (policy: Policy) => void
  }
) => {
  const onActionCell = () => ({
    onClick: (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
    }
  })

  return [
    {
      title: "Policy Name",
      render: CellTable,
      ...getDefaultColumnParams("name")
    },
    {
      title: "Description",
      render: CellTable,
      ...getDefaultColumnParams("description")
    },
    {
      title: "Scope",
      width: "40%",
      render: ScopeCell(t),
      ...getDefaultColumnParams("specificScopes")
    },
    {
      title: "Status",
      width: 65,
      onCell: onActionCell,
      render: StatusSwitcherCell(isUpdating, onStatusChange),
      ...getDefaultColumnParams("enabled")
    },
    {
      title: null,
      width: 50,
      onCell: onActionCell,
      render: ActionsCellScmTab(t, { onEdit, onDelete }),
      ...getDefaultColumnParams("actions")
    }
  ]
}
