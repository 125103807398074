import { NAMES_ROUTES } from "@/const/routes.constants"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import { useLogger } from "@/hooks/useLogger"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"
import {
  useDeletePolicy,
  usePolicies,
  useScopeOptions,
  useUpdatePolicy
} from "@/pages/GovernancePage/applications/policies/usePolicies"
import { DndContext } from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Row } from "./Row"
import { Tab } from "./Tab"

import type { Policy } from "@/api/usePolicies.types"
import type { DragEndEvent } from "@dnd-kit/core"
import type { SubTabProps } from "."

export const SubTab = (props: SubTabProps) => {
  const { messageApi } = props
  const { isLoading: scopeOptionsIsLoading } = useScopeOptions(props.policyType)
  const {
    response,
    refetch,
    isRefetching,
    isLoading: policiesIsLoading
  } = usePolicies({ type: props.policyType, subType: props.subType })
  const isLoading = policiesIsLoading || scopeOptionsIsLoading
  const { data } = response || {}
  const updateContext = useUpdatePolicy(props.policyType)
  const deleteContext = useDeletePolicy(props.policyType)
  const [dataSource, setDataSource] = useState<(Policy & { key: string })[]>(
    data || []
  )
  const navigate = useNavigate()
  const { logger } = useLogger()

  const { t: tPolicies } = useTranslation("translation", {
    keyPrefix: "settings.policies"
  })

  const { confirm } = useConfirmModal()

  const setDataSourceFromResponse = () => {
    if (data) {
      setDataSource(data)
    }
  }

  const onStatusChange = (enabled: boolean, policy: Policy) => {
    const { id, name } = policy

    logger.info(props.analyticsEvents.STATUS, { id, name, enabled })

    setDataSource((previous) =>
      previous.map((item) => (item.key === id ? { ...item, enabled } : item))
    )
  }

  const parentRoute = `/${NAMES_ROUTES.GOVERNANCE.ROOT}/${props.settingsRoute}/${props.subType}`

  const onEdit = (policy: Policy) => {
    navigate(`${parentRoute}/${policy.id}`)
  }

  const onDelete = (policy: Policy) => {
    const { id, name } = policy
    confirm((attr) =>
      DeleteConfirmModal({
        name,
        entityName: `${props.policyType}_${props.subType}`,
        onOk: async () => {
          await deleteContext.mutateAsync(id)
          await refetch()

          logger.info(props.analyticsEvents.DELETE, { id, name })
        },
        ...attr
      })
    )
  }

  useEffect(() => {
    setDataSourceFromResponse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const updateList = async (policies: typeof dataSource) => {
    const listUpdatingKey = "listUpdating"
    const listUpdatedKey = "listUpdated"

    try {
      messageApi.loading({
        key: listUpdatingKey,
        content: tPolicies(listUpdatingKey),
        duration: 0
      })
      await updateContext.mutateAsync({
        policyType: capitalizeFirstLetter(props.subType),
        policies: policies.map(({ id, enabled }) => ({
          id,
          enabled
        }))
      })
      await refetch()
      messageApi.success(tPolicies(listUpdatedKey))
    } catch (err: any) {
      if (typeof err?.data?.detail === "string")
        return messageApi.error(err.data.detail)
      messageApi.error({ content: t("error.serverError") })
      setDataSourceFromResponse()
    } finally {
      messageApi.destroy(listUpdatingKey)
    }
  }
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (data?.length !== dataSource?.length) return
    if (data === dataSource) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      updateList(dataSource)
    }, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id)
        const overIndex = previous.findIndex((i) => i.key === over?.id)

        return arrayMove(previous, activeIndex, overIndex)
      })
    }

    const { name } = dataSource.find((i) => i.key === active.id) || {}
    logger.info(props.analyticsEvents.REORDER, { id: active.id, name })
  }

  const { t } = useTranslation()
  const isUpdating = updateContext.isPending
  const applyingChanges = isUpdating || isRefetching

  const actions = { isUpdating, onStatusChange, onEdit, onDelete }

  useEffect(() => {
    props.setIsInitialDataInState(dataSource === data || !applyingChanges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, data, applyingChanges])

  useTooltip(dataSource, { preventRemoveContent: true })

  const tabProps = {
    isLoading,
    actions,
    parentRoute,
    dataSource: isLoading && !dataSource?.length ? undefined : dataSource
  }

  if (!props.draggable) return <Tab {...tabProps} />

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        disabled={isUpdating}
        items={dataSource.map((item) => item.key)}
        strategy={verticalListSortingStrategy}
      >
        <Tab components={{ body: { row: Row } }} {...tabProps} />
      </SortableContext>
    </DndContext>
  )
}
