import { useState } from "react"

import { Button, Dropdown, Link, message, Tabs, Text } from "@/atoms"
import { NAMES_ROUTES, VALUES_PARAMS } from "@/const/routes.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { FileProtectOutlined } from "@/icons"
import { LabelTabItem } from "@/molecules/LabelTabItem"
import { usePolicies } from "@/pages/GovernancePage/applications/policies/usePolicies"
import {
  PROTECT_SUB_TYPE_POLICY,
  SUPPRESS_SUB_TYPE_POLICY
} from "@/pages/GovernancePage/helpers/policy"
import { useNavigate, useParams } from "react-router-dom"
import { SubTab } from "./SubTab"

import styles from "./ScmTab.module.scss"

import { Permission } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { Protected } from "@/molecules/Protected"
import { TabsTemplate } from "@/templates/TabsTemplate"

import type { PoliciesPageProps } from "./ScmTab.types"

export const ScmTab = (props: PoliciesPageProps) => {
  const { t: tPolicy } = useTranslation("translation", {
    keyPrefix: `settings.${props.policyType.toLowerCase()}`
  })
  const { t: tPolicies } = useTranslation("translation", {
    keyPrefix: "settings.policies"
  })
  const [messageApi, messageContext] = message.useMessage()
  const protectSubType = PROTECT_SUB_TYPE_POLICY
  const suppressSubType = SUPPRESS_SUB_TYPE_POLICY
  const [isInitialDataInState, setIsInitialDataInState] = useState(false)
  const navigation = useNavigate()
  const tabsKeys = [protectSubType, suppressSubType]
  const { needle } = useParams()
  const activeKey = needle || tabsKeys[0]
  const { ROOT } = NAMES_ROUTES.GOVERNANCE
  const subTabProps = {
    messageApi,
    setIsInitialDataInState,
    ...props
  }
  const { hasPermission } = useUser()
  const writePermission: Permission = {
    resource: "scm.policies",
    action: "write"
  }
  const isAllowed = hasPermission(writePermission)
  const { abbreviateNumber } = useNumberConvertor()
  const { policyType } = props
  const protectRes = usePolicies({ type: policyType, subType: protectSubType })
  const suppressRes = usePolicies(
    { type: policyType, subType: suppressSubType },
    undefined
  )

  return (
    <div>
      <div className={styles.titleWrapper}>
        <div>
          <Text type="secondary">{tPolicy("first_line_note")}</Text>
          <br />
          <Text type="secondary">{tPolicy("second_line_note")}</Text>
          <br />
          <Text type="secondary">{tPolicy("third_line_note")}</Text>
        </div>
        <Protected permission={writePermission} shouldDisable>
          <Dropdown
            className={styles.dropdownButton}
            disabled={!isInitialDataInState}
            menu={{
              items: [
                {
                  key: protectSubType,
                  label: (
                    <Link
                      to={`/${ROOT}/${props.settingsRoute}/${protectSubType}/${VALUES_PARAMS.NEW_ENTITY}`}
                    >
                      Protection Policy
                    </Link>
                  )
                },
                {
                  key: suppressSubType,
                  label: (
                    <Link
                      to={`/${ROOT}/${props.settingsRoute}/${suppressSubType}/${VALUES_PARAMS.NEW_ENTITY}`}
                    >
                      Suppression Policy
                    </Link>
                  )
                }
              ]
            }}
            trigger={["hover"]}
          >
            <Button icon={<FileProtectOutlined />} type="primary">
              {tPolicies("createPolicy")}
            </Button>
          </Dropdown>
        </Protected>
      </div>

      <TabsTemplate className={styles.tabs}>
        <Tabs
          activeKey={activeKey}
          items={[
            {
              key: tabsKeys[0],
              label: (
                <LabelTabItem
                  className="label-tab-item"
                  count={abbreviateNumber(
                    protectRes.response?.metadata?.total || 0
                  )}
                  overflowCount={999}
                  title="Protection"
                />
              ),
              children: (
                <SubTab
                  draggable={isAllowed}
                  subType={protectSubType}
                  {...subTabProps}
                />
              )
            },
            {
              key: tabsKeys[1],
              label: (
                <LabelTabItem
                  count={abbreviateNumber(
                    suppressRes.response?.metadata?.total || 0
                  )}
                  overflowCount={999}
                  title="Suppression"
                />
              ),
              children: <SubTab subType={suppressSubType} {...subTabProps} />
            }
          ]}
          onChange={(key) => {
            navigation(`/${ROOT}/${props.settingsRoute}/${key}`)
          }}
        />
      </TabsTemplate>
      {messageContext}
    </div>
  )
}
