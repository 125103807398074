import { Card, Divider, Flex, Link, Text } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { useTranslation } from "@/hooks/useTranslation"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { MetaRow } from "@/organisms/Drawers/components/PackageDrawer/components/MetaRow"
import { APPLICATION_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import cn from "classnames"
import MetaImage from "../MetaImage"
import { RuntimeImagesCard } from "../RuntimeImagesCard"

import type { RuntimeEvidenceTabProps } from "../../RuntimeEvidenceTab.types"

import styles from "../../RuntimeEvidenceTab.module.scss"

export const RuntimeApplicationCard = (props: RuntimeEvidenceTabProps) => {
  const { t } = useTranslation()
  const { record } = props
  const { onRowClick } = useDrawer("")
  const runtimeData = record?.runtimeData
  const applicationData = runtimeData?.lastSeenOnApplication
  if (!runtimeData || !applicationData || !applicationData?.id) return null

  const { environment, namespace, lastSeenInRuntime } = applicationData

  return (
    <Card className={cn("embedded-card-ks", styles.content)}>
      <RuntimeImagesCard {...props} />
      {!!applicationData.id && (
        <>
          <Divider className={styles.divider} />
          <Flex vertical gap={8} className={styles.applicationImageContent}>
            {lastSeenInRuntime > 0 && (
              <OverviewDescriptions.Label>
                {`${t("runtimeEvidence.lastObserved")}: ${getDateInUserLocale(getDateFromSeconds(lastSeenInRuntime), DEFAULT_LONG_FORMAT)}`}
              </OverviewDescriptions.Label>
            )}
            <Flex gap={8} align="center">
              <OverviewDescriptions.Label>Image:</OverviewDescriptions.Label>
              <MetaImage
                record={applicationData.image}
                onRowClick={onRowClick}
                classes={{
                  text: styles.textContent
                }}
              />
            </Flex>
            <MetaRow
              items={[
                {
                  label: t("runtimeEvidence.observedInApp"),
                  children: (
                    <Link
                      type="link"
                      onClick={() =>
                        onRowClick(
                          { key: applicationData.id },
                          APPLICATION_DRAWER_ID
                        )
                      }
                    >
                      <Text copyable className={styles.textContent}>
                        {applicationData.name}
                      </Text>
                    </Link>
                  )
                },
                { label: "Environment:", children: environment },
                { label: "Namespace:", children: namespace }
              ]}
            />
          </Flex>
        </>
      )}
    </Card>
  )
}
