import {
  DEFAULT_VALUES,
  REGCRED_YAML_FILENAME
} from "@/pages/DeploymentsPage/const"
import { getKortexFilename } from "@/pages/DeploymentsPage/helpers"
import { getDeployCommandString } from "@/pages/DeploymentsPage/helpers/deployment-command"

import type { InitialValues } from "./helpers.types"

export const getDefaultValues = (initialValues?: InitialValues) => {
  const {
    environment = DEFAULT_VALUES.ENVIRONMENT,
    pullSecretName = DEFAULT_VALUES.SECRET_NAME,
    namespace = DEFAULT_VALUES.NAMESPACE
  } = initialValues || {}

  return {
    deploymentKey: DEFAULT_VALUES.DEPLOYMENT_KEY,
    key: Date.now().toString(),
    namespace,
    pullSecretName,
    environment
  }
}

export const getLines = ({ version }: { version: string }) => ({
  1: {
    name: "namespace",
    text: "kubectl apply --namespace",
    suffix: ` -f ${REGCRED_YAML_FILENAME} &&`,
    align: "space-between" as const
  },
  2: {
    text: `helm upgrade --install kortex ${getKortexFilename(version)}`
  },
  3: {
    name: "namespace",
    text: "--namespace",
    align: "space-between" as const
  },
  4: { name: "environment", text: "--set kortex.environment=" },
  5: { name: "deploymentKey", text: "--set kortex.deploymentKey=" },
  6: { name: "pullSecretName", text: "--set kortex.image.pullSecretName=" },
  7: { text: "--create-namespace" }
})

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>,
  version: string
) => {
  const lines = getLines({ version })

  return getDeployCommandString({
    lines,
    values,
    defaultValues
  })
}
