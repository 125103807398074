import { useActionsSummaryStatisticsWorkflow } from "@/api/useWorkflow"
import { Col, Row, Tag } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { StopOutlined, Webhook } from "@/icons"
import { StatisticWidget, Widget } from "@/molecules/Widget"

import styles from "./ActionsTakenWidget.module.scss"

export const ActionsTakenWidget = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.automatedActions.table.widgets.taken"
  })
  const { t: tGeneral } = useTranslation()
  const context = useActionsSummaryStatisticsWorkflow()
  const { data } = context.response || {}
  const { webhook } = data || {}

  return (
    <Widget
      loading={context.isLoading}
      title={t("title")}
      popover={{ content: t("tooltip") }}
    >
      <Row gutter={8}>
        <Col span={24}>
          <StatisticWidget
            title={t("sent")}
            value={webhook || 0}
            icon={<Webhook />}
          />
        </Col>
        <Col span={24}>
          <StatisticWidget
            title={t("dismissIssue")}
            value={
              <Tag
                className={styles.tagComingSoon}
              >{`${tGeneral("general.comingSoon")}`}</Tag>
            }
            icon={<StopOutlined className={styles.iconComingSoon} />}
          />
        </Col>
      </Row>
    </Widget>
  )
}
