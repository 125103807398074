import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { SbomTab } from "../SbomTab"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IssueTabApplicationDrawer } from "./components/IssueTabApplicationDrawer"
import { TitleApplicationDrawer } from "./components/TitleApplicationDrawer"

import type { ApplicationDrawerComponent } from "."

export const ApplicationDrawer: ApplicationDrawerComponent = (props) => {
  const { widthAdditive, ...restProps } = props
  const tabs = ["sbom", "scaIssues"]
  const components = [SbomTab, IssueTabApplicationDrawer]

  return (
    <TabsDrawerTemplate
      name="ApplicationDrawer"
      tabs={tabs}
      components={components}
      Title={TitleApplicationDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...restProps}
    />
  )
}
