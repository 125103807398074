import { CellTable, SortOrder } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import { TFunction } from "@/hooks/useTranslation"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { InsightsApplicationCell } from "../../molecules/InsightsApplicationCell"

import type { ColumnType } from "@/atoms"
import { RowClick } from "@/hooks/useLinkDrawerTooltip.ts"
import type { Application } from "@/pages/DiscoveryPage/application/application-inventory/useApplicationInventory.types"
import { MoreTableCellDrawer } from "@/pages/DiscoveryPage/components/molecules/MoreTableCellDrawer"

export const getColumnsTablePreviewMode = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
) => [
  {
    title: "Application Name",
    render: CellTable,
    ...getDefaultColumnParams("displayName", sorter),
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
  },
  {
    title: "Environment",
    width: 120,
    render: CellTable,
    ...getDefaultColumnParams("environment")
  },
  {
    title: "Namespace",
    width: 200,
    render: CellTable,
    ...getDefaultColumnParams("namespace")
  }
]

export const getColumnsTable = ({
  internetFacingFF,
  previewMode,
  convertors,
  sorter,
  t,
  onRowClick
}: {
  internetFacingFF?: boolean
  previewMode?: boolean
  convertors: NumberConvertor
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
  onRowClick: RowClick
}): ColumnType<Application>[] => {
  const columnsPreviewMode = getColumnsTablePreviewMode(sorter)

  if (previewMode) return columnsPreviewMode

  return [
    ...columnsPreviewMode,
    {
      title: "Insights",
      width: internetFacingFF ? 110 : 75,
      render: InsightsApplicationCell({ t, internetFacingFF }),
      ...getDefaultColumnParams("insights")
    },
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Related Images",
      width: "30%",
      render: MoreTableCellDrawer(onRowClick),
      ...getDefaultColumnParams("relatedImages")
    },
    {
      title: "Last Seen",
      width: 155,
      ...getDefaultColumnParams("lastSeen")
    }
  ]
}
