import { Carousel, Empty } from "@/atoms"
import { EVENTS } from "@/const/event.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { useTranslation } from "@/hooks/useTranslation"
import { VulnerableFunctionSlide } from "@/pages/DashboardPage/components/molecules/VulnerableFunctionSlide"
import CardDashboardTemplate from "@/pages/DashboardPage/components/templates/CardDashboardTemplate"
import styles from "./VulnerableFunctionInRuntimeWidget.module.scss"

export const VulnerableFunctionInRuntimeWidget = () => {
  const { t } = useTranslation()
  const { t: tVulnerableFn } = useTranslation("translation", {
    keyPrefix: "dashboard.vulnerableFunction"
  })

  const [context, { isLoading }] = useParallelQueries<{ data: number }>(
    ["YES", "NO"].map((runtime) => ({
      url: `${RESOURCES_NAMES.TRIAGE.ISSUES_COUNT}`,
      params: { hasFunctionEvidence: "YES", issueStatus: "open", runtime }
    }))
  )

  const executed = context[0].data?.data || 0
  const notExecuted = context[1].data?.data || 0
  const all = executed + notExecuted
  const issuesExist = all > 0
  const percentageExecuted = Math.round((executed / all) * 100)
  const percentageNotExecuted = 100 - percentageExecuted
  const contentPopover = tVulnerableFn("tooltip")
  const { VULNERABLE_FUNCTION } = EVENTS.ANALYTIC_EVENTS.DASHBOARD

  return (
    <CardDashboardTemplate
      title={tVulnerableFn("title")}
      contentPopover={contentPopover}
      loading={isLoading}
      className={{ row: styles.row }}
      teasers={{
        dataReadiness: true,
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <div className={styles.wrapper}>
        {issuesExist && (
          <Carousel rootClassName={styles.carousel}>
            <div className={styles.slide}>
              <VulnerableFunctionSlide
                executed
                to="/triage/issues?issueStatus=open&runtime=YES&pageNumber=0&hasFunctionEvidence=YES"
                value={executed}
                suffix={percentageExecuted}
                t={tVulnerableFn}
                MixpanelEvent={
                  VULNERABLE_FUNCTION.ISSUE_WITH_VULNERABLE_FUNCTION_CLICKED
                }
              />
            </div>
            <div className={styles.slide}>
              <VulnerableFunctionSlide
                to="/triage/issues?issueStatus=open&runtime=NO&pageNumber=0&hasFunctionEvidence=YES"
                value={notExecuted}
                suffix={percentageNotExecuted}
                t={tVulnerableFn}
                MixpanelEvent={
                  VULNERABLE_FUNCTION.ISSUE_WITH_NO_VULNERABLE_FUNCTION_CLICKED
                }
              />
            </div>
          </Carousel>
        )}
        {!issuesExist && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={contentPopover}
          />
        )}
      </div>
    </CardDashboardTemplate>
  )
}
