import { Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import React from "react"
import { ProtectedProps } from "."

import { useUser } from "@/hooks/useUser"
import styles from "./Protected.module.scss"

export const Protected = ({
  permission,
  children,
  shouldDisable = false,
  fallback = null
}: ProtectedProps) => {
  const { hasPermission } = useUser()
  const { t } = useTranslation()
  const hasAccess = hasPermission(permission)

  if (hasAccess) return children

  if (!shouldDisable || !React.isValidElement(children)) {
    return fallback
  }

  return (
    <Tooltip title={t("general.noPermissionsTooltip")}>
      <span className={styles.disabledElement}>
        {React.cloneElement(children, { disabled: true } as {
          disabled: boolean
        })}
      </span>
    </Tooltip>
  )
}
