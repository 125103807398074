import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { Permission } from "@/domain/user"

import type { TFunction } from "@/hooks/useTranslation"

export const getItems = (t: TFunction) => {
  const TAB_KEYS = TAB_KEYS_BY_PAGE.SETTINGS

  return [
    { label: t("sections.organization") },
    {
      label: t("auth.title"),
      key: TAB_KEYS[2],
      permission: {
        resource: "settings.auth",
        action: "read"
      } as Permission
    },
    { label: t("users.title"), key: TAB_KEYS[0] },
    { label: t("sections.preferences") },
    { label: t("scopes.title"), key: TAB_KEYS[1] },
    {
      label: t("labels.title"),
      key: TAB_KEYS[9],
      permission: {
        resource: "settings.labels",
        action: "read"
      } as Permission
    },
    {
      label: t("sections.sast"),
      key: TAB_KEYS[8],
      permission: {
        resource: "settings.code-scan",
        action: "read"
      } as Permission
    },
    { label: t("sections.keys") },
    { label: t("apikeys.title"), key: TAB_KEYS[4] },
    {
      label: t("deploymentkeys.title"),
      key: TAB_KEYS[5],
      permission: {
        resource: "settings.deployment-keys",
        action: "read"
      } as Permission
    },
    {
      label: t("servicekeys.title"),
      key: TAB_KEYS[6],
      permission: {
        resource: "settings.service-keys",
        action: "read"
      } as Permission
    }
  ]
}
