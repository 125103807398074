import { Form, Input } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { AlignLeftOutlined } from "@/icons"
import { SectionFormTemplate } from "@/pages/SettingsPage/components/templates/SectionFormTemplate"

import type { DetailsSectionFormComponent } from "."

export const DetailsSectionForm: DetailsSectionFormComponent = (props) => {
  const { t } = useTranslation()
  const rules = [{ required: true, message: t("error.requiredField") }]
  const desc = "forms.fields.description"
  const { label, title, focusRef } = props

  return (
    <SectionFormTemplate id="details" title={title} Icon={AlignLeftOutlined}>
      <Form.Item
        required={false}
        name="details.name"
        label={label}
        rules={rules}
      >
        <Input
          ref={focusRef}
          placeholder={t("forms.fields.name.placeholder")}
        />
      </Form.Item>
      <Form.Item
        required={false}
        name="details.description"
        label={t(`${desc}.label`)}
      >
        <Input placeholder={t(`${desc}.placeholder`)} />
      </Form.Item>
    </SectionFormTemplate>
  )
}
