import cn from "classnames"

import { useGetStatistics } from "@/api/useStatistics"
import { Flex } from "@/atoms"
import { TEASERS } from "@/const/teaser.constants"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { SmileOutlined } from "@/icons"
import { TRIAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { getIssuesDefaultParams } from "@/pages/DashboardPage/hooks/helpers"
import { getIssuesTriageSelectorCommon } from "@/pages/TriagePage/application/useTriage.selector"
import { IssueCard } from "../../molecules/IssueCard"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./TopIssuesWidget.module.scss"

export const TopIssuesWidget = ({ runtime }: { runtime?: boolean }) => {
  const { t } = useTranslation()
  const context = useGetStatistics(
    {
      runtime,
      needle: "/issues/top",
      topResource: 5,
      ...getIssuesDefaultParams()
    },
    {
      enabled: runtime !== undefined,
      select: (response) => {
        const { data = [], ...rest } = response || {}
        const sortedData = data.sort(
          (a, b) => b.issue.kodemScore - a.issue.kodemScore
        )
        const minKodemScore = sortedData[4]?.issue?.kodemScore
        const filteredData =
          minKodemScore === undefined
            ? sortedData
            : sortedData.filter(
                (item) => item.issue.kodemScore >= minKodemScore
              )

        return {
          data: getIssuesTriageSelectorCommon(
            filteredData.map(({ issue, resources }) => ({
              resources,
              ...issue
            }))
          ),
          ...rest
        }
      }
    }
  )
  const { response, isLoading } = context
  const { data = [] } = response || {}
  const { onRowClick } = useDrawer("")
  const loading = isLoading || runtime === undefined
  const dataExist = data?.length > 0
  const minHeightAuto = !loading && dataExist
  const { logger, EVENTS } = useLogger()

  return (
    <CardDashboardTemplate
      title={t(`dashboard.topIssues.title${runtime ? "Runtime" : ""}`)}
      loading={loading}
      className={{ card: cn(styles.card, minHeightAuto && styles.loadedCard) }}
      teasers={{
        dataReadiness: true,
        scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t),
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <div className={styles.container}>
        <Flex vertical flex={1} gap="4px">
          {!dataExist && (
            <Flex
              vertical
              flex={1}
              align="center"
              justify="center"
              className={styles.noData}
            >
              <SmileOutlined className={styles.iconNoData} />
              <div>
                <span>{t("dashboard.topIssues.noData")}</span>
              </div>
            </Flex>
          )}
          {data.map(({ key, ...issue }) => (
            <IssueCard
              key={issue.id}
              resources={[]}
              onClick={(id: string) => {
                logger.info(
                  EVENTS.ANALYTIC_EVENTS.DASHBOARD.TOP_ISSUES.ISSUE_CLICKED,
                  { issueId: issue.id }
                )
                onRowClick({ key: id }, TRIAGE_DRAWER_ID)
              }}
              onRowClick={onRowClick}
              {...issue}
            />
          ))}
        </Flex>
      </div>
    </CardDashboardTemplate>
  )
}
