import { useNavigate } from "react-router-dom"

import {
  useDeleteIntegration,
  useInstallIntegration,
  usePatchIntegration,
  useTestWebhook
} from "@/api/useIntegrations"
import { Form } from "@/atoms"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useTranslation } from "@/hooks/useTranslation"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"

import { WebhookAPIProps } from "./useWebhookAPI.types"

export const useWebhookAPI = (props?: WebhookAPIProps) => {
  const { initialValues, messageApi } = props || {}
  const params = { vendor: INTEGRATION_VENDORS.webhook }
  const webhookId = initialValues?.id
  const formId = webhookId || `${params.vendor}-form`
  const installer = useInstallIntegration(params)
  const updater = usePatchIntegration(params)
  const remover = useDeleteIntegration(params)
  const { focusRef, isDirty, setIsDirty, onFieldsChange } =
    useCustomFunctionsForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { confirm } = useConfirmModal()
  const testWebhook = useTestWebhook()

  const getBackToIntegrationUrl = (configTab?: boolean) =>
    `/integrations/${params.vendor}${configTab ? "?tab=configuration" : ""}`

  const backToIntegration = (configTab?: boolean) =>
    navigate(getBackToIntegrationUrl(configTab))

  const messagePrefix = "integrations.webhook.messages"

  const createOrUpdateWebhook = async (values: Record<string, unknown>) => {
    const prefix = `${messagePrefix}.${webhookId ? "update" : "add"}`

    try {
      const payload = {
        data: { webhook: webhookId ? { id: webhookId, ...values } : values }
      }

      if (webhookId) await updater.mutateAsync(payload)
      else await installer.mutateAsync(payload)

      messageApi?.success(t(`${prefix}.success`, { name: values.name }))

      return true
    } catch (error: any) {
      const displayMessage = error?.data?.displayMessage

      messageApi?.error(displayMessage || t(`${prefix}.failed`))

      return false
    }
  }

  const onFinish = async (values: Record<string, any>) => {
    const result = await createOrUpdateWebhook(values)

    if (!result) return

    if (!webhookId) backToIntegration(true)
    else setIsDirty(false)
  }

  const onRemove = async () => {
    const name = initialValues?.name || "Webhook"

    confirm((attr) =>
      DeleteConfirmModal({
        name,
        entityName: "WEBHOOK",
        onOk: () => remover.mutateAsync(webhookId),
        ...attr
      })
    )
  }

  const onReset = () => {
    form.resetFields()
    setIsDirty(false)
  }

  const onTest = async (url: string) => {
    try {
      messageApi?.loading("Testing webhook")
      const { data } = await testWebhook.mutateAsync({ webhook: { url } })
      const { success, message } = data

      messageApi?.destroy()

      if (!success) {
        if (message) return messageApi?.error(message)

        throw new Error()
      }

      messageApi?.success(t("general.success"))
    } catch {
      messageApi?.error(t("general.failed"))
    }
  }

  const isLoading = installer.isPending || updater.isPending

  return {
    focusRef,
    form,
    initialValues,
    formId,
    isDirty,
    isLoading,
    onFinish,
    onFieldsChange,
    onRemove,
    onReset,
    onTest,
    getBackToIntegrationUrl,
    backToIntegration,
    t,
    createOrUpdateWebhook,
    URL_FIELD_NAME: "url"
  }
}
