import { LabelTab } from "../LabelTab"

export const ItemsTabs = (state: any, drawerState: any, props: any) => {
  const { tabs, components, name: drawerName } = props
  const [{ activeTab, counts, tabsProps, forceRender }, actions] = state
  const { disabled = {}, tooltips = {}, featureStatus = {} } = tabsProps

  return tabs.map((name: string, index: number) => {
    const ChildrenComponent = components[index]
    const isDisabled = disabled[name]
    const tooltip =
      tooltips[name]?.[isDisabled ? "disabledTooltipKey" : "tooltipKey"]
    const count = counts[name]
    const attr = { name, count, tooltip, featureStatus: featureStatus[name] }

    return {
      key: name,
      disabled: isDisabled,
      forceRender,
      label: <LabelTab {...attr} />,
      children: (
        <ChildrenComponent
          isActive={name === activeTab}
          actions={actions}
          forceRender={forceRender}
          drawerName={drawerName}
          {...drawerState}
        />
      )
    }
  })
}
