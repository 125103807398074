import { useState } from "react"

import { Steps, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationAlert } from "@/molecules/InformationAlert"
import { DeployCommandValuesContext } from "@/pages/DeploymentsPage/hooks/useDeployCommandValues"

import styles from "./DeployTab.module.scss"

import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { LinkButton } from "@/molecules/LinkButton"
import { DeployTabComponent } from "."
import { ReadOutlined } from "@/icons"

export const DeployTab: DeployTabComponent = (props) => {
  const { t } = useTranslation()
  const { children, steps, message, title } = props
  const value = useState({})

  return (
    <DeployCommandValuesContext.Provider value={value}>
      <Title className={styles.title} level={5}>
        {`${title || t("deployments.instructions")}:`}
      </Title>
      <Steps
        size="small"
        className={styles.steps}
        direction="vertical"
        items={steps}
      />
      <div className={styles.main}>{children}</div>
      {!!message && (
        <InformationAlert className={styles.infoAlert} message={message} />
      )}
      <LinkButton
        className={styles.readInstructionsLink}
        icon={<ReadOutlined />}
        size="small"
        type="link"
        href={`${EXTERNAL_ROUTES.API_DOCS}/deployments/deployments-overview`}
        target="_blank"
      >
        {t(`deployments.readFullInstructions`)}
      </LinkButton>
    </DeployCommandValuesContext.Provider>
  )
}
