import { Card, Col, Markdown } from "@/atoms"
import { isGhsaRisk, isMalRisk } from "@/domain/vulnerability/vulnerability.ts"
import { Heading } from "@/molecules/Heading"
import { LineClamp } from "@/molecules/LineClamp"
import IssueDetailsSkeleton from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell/IssueDetailsSkeleton"

import type { DescriptionIssueCardComponent } from "."
import styles from "./DescriptionIssueCard.module.scss"

export const DescriptionIssueCard: DescriptionIssueCardComponent = (props) => {
  const { t, record } = props
  if (!record) return <IssueDetailsSkeleton />
  if (record.description === "") return null
  const shouldMarkdown = isGhsaRisk(record.riskId) || isMalRisk(record.riskId)

  return (
    <Col span={24}>
      <Card
        className="embedded-card-ks"
        title={<Heading level={6} title={t("general.description")} />}
      >
        <LineClamp clamp={3} classes={{ container: styles.container }}>
          {shouldMarkdown && <Markdown content={record.description} />}
          {!shouldMarkdown && <span>{record.description}</span>}
        </LineClamp>
      </Card>
    </Col>
  )
}
