import { getExploitabilityByKeys } from "@/const/insights-constants"
import { defaultSortResourcesByType } from "@/domain/resource"
import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { APPLICATION_DRAWER_ID } from "@/organisms/Drawers/const"

import type { PackageResponse } from "@/api/useInventory.types"

export const getDataProject = (data: PackageResponse["data"]) =>
  (data || []).map(({ id, data }: any, index: number) => {
    const { vulnerabilitiesSummary, insights, resources, ...item } = data

    return {
      id,
      key: id,
      applicationsCount: {
        copyable: true,
        title: "Applications",
        items: (item.relatedApplications || []).map(
          ({ name, environment, namespace }: any) => ({
            name,
            description: `${environment || "-"}/${namespace || "-"}`
          })
        ),
        label: item.relatedApplications?.length || ""
      },
      license: {
        id: `${id}_${index}`,
        count: item.licenses.length - 1,
        items: item.licenses,
        label: item.licenses[0] || ""
      },
      issuesSummary: getIssuesCountMapOrderedSeverity(vulnerabilitiesSummary),
      insights: {
        ...getExploitabilityByKeys(insights.exploitMaturity),
        ...insights,
        id: item.key
      },
      lastSeenDate: getDateInUserLocale(
        getDateFromSeconds(item.lastSeen),
        DEFAULT_LONG_FORMAT
      ),
      projects: item.projectIds.length,
      expandable: !!item.projectIds.length,
      resources: defaultSortResourcesByType(resources),
      ...item
    }
  })

export const getPackages = (response: PackageResponse) => {
  const { data = [], ...rest } = response

  return {
    data: data.map(({ id, data }: any, index: number) => {
      const { vulnerabilitiesSummary, insights, resources, ...item } = data

      return {
        id,
        key: id,
        applicationsCount: {
          copyable: true,
          title: "Applications",
          items: (item.relatedApplications || []).map(
            ({ id: applicationId, name, environment, namespace }: any) => ({
              name,
              description: `${environment || "-"}/${namespace || "-"}`,
              drawerId: APPLICATION_DRAWER_ID,
              drawerEntityId: applicationId
            })
          ),
          label: item.relatedApplications?.length || ""
        },
        license: {
          id: `${item.key}_${index}`,
          count: item.licenses.length - 1,
          items: item.licenses,
          label: item.licenses[0] || ""
        },
        issuesSummary: getIssuesCountMapOrderedSeverity(vulnerabilitiesSummary),
        insights: {
          ...getExploitabilityByKeys(insights.exploitMaturity),
          ...insights,
          id
        },
        lastSeenDate: getDateInUserLocale(
          getDateFromSeconds(item.lastSeen),
          DEFAULT_LONG_FORMAT
        ),
        projects: item.projectIds.length,
        expandable: !!item.projectIds.length,
        resources: defaultSortResourcesByType(resources),
        ...item
      }
    }),
    ...rest
  }
}

export const getProjectsByPackage = (response: {
  pages: PackageResponse[]
}) => {
  const { pages, ...rest } = response

  return { pages: getDataProject(pages.flatMap((page) => page.data)), ...rest }
}
