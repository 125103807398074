import { Button, Card, Flex, Link } from "@/atoms"
import { TEASERS } from "@/const/teaser.constants"
import { isUserAdmin } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags, useUser } from "@/hooks/useUser"
import { ConnectEnvData, Runtime } from "@/icons"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { TAB_NAMES } from "@/organisms/Drawers/const"
import cn from "classnames"

import type { RuntimeEvidenceCardProps } from "./RuntimeEvidenceCard.types"

import styles from "./RuntimeEvidenceCard.module.scss"

export const RuntimeEvidenceCard = (props: RuntimeEvidenceCardProps) => {
  const { logger, EVENTS } = useLogger()
  const { user } = useUser()
  const { t } = useTranslation()
  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })
  const kortexInstalledFF = useFeatureFlags("kortexInstalled")
  const kortexInstalledProps =
    TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
  const showLink = isUserAdmin(user)
  const { record, isCodeIssue = false, drawerName, eventFrom } = props
  const runtimeData = record?.runtimeData
  const keyEvidence = runtimeData?.keyEvidence
  const isKortexInstaled = !!kortexInstalledFF

  return (
    <Card className={cn("embedded-card-ks", styles.card)}>
      <Flex gap={isKortexInstaled ? 32 : 24} align="center">
        <div>
          {isKortexInstaled ? (
            <Runtime
              className={cn(
                styles.runtimeIcon,
                isCodeIssue || !keyEvidence?.isRuntime
                  ? styles.notInRuntime
                  : styles.inRuntime
              )}
            />
          ) : (
            <ConnectEnvData className={styles.connectEnvIcon} />
          )}
        </div>
        <Flex vertical>
          <Heading
            level={5}
            title={t("general.runtimeEvidence")}
            popover={{
              className: styles.popover,
              content: (
                <InformationPopover.CommonTemplate
                  classes={{ content: styles.contentPopover }}
                >
                  {tRuntimeEvidence("contentPopover")}
                </InformationPopover.CommonTemplate>
              )
            }}
          />
          {isKortexInstaled ? (
            <>
              {isCodeIssue ? (
                <>
                  <OverviewDescriptions.Label>
                    {tRuntimeEvidence("runtime_code_issue_soon")}
                  </OverviewDescriptions.Label>
                  <OverviewDescriptions.Label>
                    {tRuntimeEvidence("runtime_code_issue_soon_2")}
                  </OverviewDescriptions.Label>
                </>
              ) : (
                !!keyEvidence?.displayText && (
                  <div>{keyEvidence.displayText}</div>
                )
              )}
              {!!runtimeData?.runtimeEvidence?.length && (
                <div className={styles.buttonWrapper}>
                  <Button
                    type="link"
                    ghost
                    onClick={() => {
                      if (!!drawerName) {
                        logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.CHANGE_TAB, {
                          name: drawerName,
                          id: record?.id,
                          tab: TAB_NAMES.runtimeEvidence,
                          from: eventFrom || "cardLink"
                        })
                      }

                      props.actions.onChangeActiveKey(TAB_NAMES.runtimeEvidence)
                    }}
                  >
                    {tRuntimeEvidence("viewEvidence")}
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <div>{kortexInstalledProps.title}</div>
              {showLink && (
                <Link to={kortexInstalledProps.link.url}>
                  {kortexInstalledProps.link.text}
                </Link>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}
